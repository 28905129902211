import React, { useState, useContext, useEffect } from 'react';
import GlobalStateContext from '../../../../components/GlobalStateContext'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../../../../css/datePicker.css'
import { stdValue } from '../../../../Resources/setNumber'
import Modal from '../../../../components/BarraSuperior/Modal';
import PsHooks from '../../../../Hooks/psHooks';
import BoletinsHooks from '../../../../Hooks/boletinsHooks';
import { dados } from './stdBoletim';
import AnalisesContext from '../../../../ANALISES/AnalisesContext';

const DayAnalyzer = () => {
    const {openPS} = PsHooks()

    const {buscarBoletim} = BoletinsHooks()
    const [secoes, setSecoes] = useState(null);
    // const [selectedDate, setSelectedDate] = useState(null);
    const {data, setData, user, site} = useContext(GlobalStateContext)
    const {setIsDataComplete, selectedDate, setSelectedDate, bltData, setBltData, psAnData, setPsAnData, anDate, setAnDate} = useContext(AnalisesContext)
    const [isLoading, setIsLoading] = useState(false)
    const [progress, setProgress] = useState(0)
    const [mes, setMes] = useState(null)
    const [modalText, setModalText] = useState('Exportando p/ BME...')

    // useEffect(() => {
    //   if (bltData === null && global.bltData !== null){
    //     setBltData(global.bltData)
    //     setSelectedDate(global.selectedDate)
    //   }else{
    //     global.bltData = bltData
    //     global.selectedDate = selectedDate
    //   }
    // }, [bltData]);

    useEffect(() => {
      if (bltData && psAnData){
        setIsDataComplete(true)
      }else{
        setIsDataComplete(false)
      }
      
    }, [bltData, psAnData]);

    useEffect(() => {
      if(selectedDate && !bltData){
        getData()
      }
      
    }, []);

    function setDate(){
        let date = selectedDate
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Os meses são zero-indexados em JavaScript
        const year = date.getFullYear();
        const formattedDate = `${day}.${month}.${year}`;
        setAnDate(formattedDate)
        return formattedDate
    }

    function getMonthAndYear(){
      let date = selectedDate
      const month = date.getMonth() + 1
      const year = date.getFullYear() % 100
      return {month, year}
  }

  async function getPsData(){
    const { month, year } = getMonthAndYear()
    let rev = getRev(month)
    let safra = `${year}/${year+1}`
    let ps = await openPS(`${site}-${safra}-${rev}`)
    
    console.log('getPsData', ps)
    
    if (ps){
      const transformedData = ps.reduce((acc, item) => {
        acc[item.codigo] = item;
        return acc;
      }, {});
      let mes = rev.slice(-3).toLowerCase()
      setMes(mes)
      let p2 = await transformPsdata(transformedData, mes)
      // console.log('Dias Safra', diasSafra)
      setPsAnData(p2)
    }
    
  }

    async function getData(){
        let date = selectedDate
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Os meses são zero-indexados em JavaScript
        const year = date.getFullYear();

        const formattedDate = `${day}/${month}/${year}`;
        console.log('Data', formattedDate)
        
        let boletim = await buscarBoletim(site, formattedDate)
        if (!boletim){
          setBltData(null)
        }
        
        let secs  = [...new Set(dados.map(item => item.secao))]
        setBltData(boletim)
        setSecoes(secs)

        await getPsData()

        console.log('Boletim', boletim)

    }

    function setBoletimToBme(){
      let bmeData = data
      let flowCanaDia  = bltData['Cana Moída'].dia
      let flowCana  = bltData['Moagem Horária'].dia
      let horasEfetivas = flowCanaDia / flowCana
      bmeData['horasEfetivas'].valor = horasEfetivas

      Object.keys(bltData).forEach(key => {
        if (bltData[key].ipt === true){
          bmeData[bltData[key].bmeCod].valor = bltData[key].dia
        }
      })
      setData(bmeData)
      calcBME()
    }

    function getPsValue(cod, mes){
      // console.log('getPsValue', cod, mes)
      if (!psAnData[cod]){
          return ''
      }
      let item = psAnData[cod][mes] 
      if (item === 0){
        return 0
      }
      return item !== null && item !== undefined && !stdValue(item) ? stdValue(item) : '';
    }

    const fetchData2 = async () => {
      // newAction('calcularBME', site, user.email || '')
      // setIsLoading(true)
      // setModalText('Calculando...')
      // let progressValue = 0;
      // const interval = setInterval(() => {
      //   progressValue += 1;
      //   if (progressValue <= 100) {
      //     setProgress(progressValue)
      //   } else {
      //     clearInterval(interval)
      //     setModalText('Atualizando...')
      //   }
      // }, 1);
  
      try {
        const response = await fetch(''+global.endPoint+'/'+site+'/'+user?.uid+'/calcAllLoc', {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({data: data})
        });
        if (!response.ok) {
          throw new Error('Erro ao buscar dados da API');
        }
        const jsonData = await response.json()
        // setIsLoading(false)
        // setProgress(0)
        // hanldeAlert(false)
        setData(jsonData)
        console.log('Data Updated', jsonData)
      } catch (error) {
        console.error('Erro ao buscar dados da API:', error);
        // setIsLoading(false)
        // setProgress(0)
      }
    }
  
    const calcBME = async () => {
       await fetchData2()
    }

    const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
      <button
        className="custom-datepicker-input"
        onClick={onClick}
        ref={ref}
      >
        {value || "Selecione uma data"}
      </button>
    ));

    return (
        <div style={{ paddingLeft: '2vh', paddingRight: '2vh', textAlign: 'center', overflowY: 'scroll'}}>
          {/* <div style={{marginBottom: '1vh', fontSize: '2vh', fontWeight: 'bold'}}>Analisar Dia</div> */}
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
      <DatePicker
        selected={selectedDate}
        onChange={date => setSelectedDate(date)}
        dateFormat="dd/MM/yyyy"
        placeholderText="Selecione uma data"
        customInput={<CustomInput/>}
        
      />
      {/* <div style={{width: '0.5vh'}}>  </div> */}
      {selectedDate && <button className='green-button' onClick={getData} style={{marginLeft: '2vh'}}>OK </button>}
      {selectedDate &&
              <button  className='green-button' style={{marginLeft: '2vh'}} onClick={setBoletimToBme}>
                Exportar Boletim p/ BME</button>}
    </div>
          {bltData && 
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              {selectedDate &&
              <div style={{textAlign: 'left', marginLeft: '3.5vh', fontWeight: 'bold', fontSize: '1.6vh'}}>
              Data: {setDate()}
            </div>}
            </div>}
         
            <div style={{marginLeft: '3vh', marginRight: '3vh', marginTop: '0.5vh', marginBottom: '0vh', fontWeight: 'bold'}}>
                <div className='fullLine' style={{fontSize: '1vh'}}>
                    <div className='fullLineRow' style={{width: '25%'}}>DESCRIÇÃO</div>
                    <div className='fullLineRow' style={{width: '20%'}}>BOLETIM</div>
                    <div className='fullLineRow' style={{width: '20%'}}>BME</div>
                    <div className='fullLineRow' style={{width: '20%'}}>PLANO</div>
                    <div className='fullLineRow' style={{width: '15%', textAlign: 'right'}}>UNIDADE</div>
                </div>
          </div>

          {!bltData && <div>Boletim não importado</div>}

          <div style={{overflow: 'auto', maxHeight: '75vh'}}>
          {bltData && secoes &&
          
          secoes.map(secao => {
            return (
              <>
              <div className='psSectionHead' style={{marginLeft: '2vh', marginRight: '0vh'}}>{secao} </div>
              {bltData && 
              dados.filter(item => item.secao === secao)
              .map((item,index) => {
                return (
                  <div className={`psLine ${index%2 === 0 && "psLineImp"}`} style={{marginLeft: '2vh', marginRight: '0vh', paddingLeft: '1.7vh', paddingRight: '2.5vh'}}>
                    <div className='fullLineRow' style={{width: '25%'}}>{item.name}</div>
                    <div className='fullLineRow' style={{width: '20%'}}>{bltData[item.name] ? stdValue(bltData[item.name].dia) : ''}</div>
                    <div className='fullLineRow' style={{width: '20%', color: `${bltData[item.name] && bltData[item.name].ipt === true ? 'blue' : 'black'}`}}>{data[item.bmeCod] ?  stdValue(data[item.bmeCod].valor) : ''}</div>
                    <div className='fullLineRow' style={{width: '20%'}}>{psAnData && item.psCod ? (getPsValue(item.psCod, mes)) : ''}</div>
                    <div className='fullLineRow' style={{width: '15%', textAlign: 'right'}}>{bltData[item.name] ? bltData[item.name].unidade : ''}</div>
                  </div>
                )
              })}
              </>
            )
          })
          
          }
          </div>
        <div style={{height: '5vh'}}></div>
        
        <Modal show={isLoading} progress={progress} texto={modalText}/>
        </div>
      );
}

export default DayAnalyzer;

function getRev(n){
  switch (n){
    case 1: return '1_Jan'
    case 2: return '2_Fev'
    case 3: return '3_Mar'
    case 4: return '4_Abr'
    case 5: return '5_Mai'
    case 6: return '6_Jun'
    case 7: return '7_Jul'
    case 8: return '8_Ago'
    case 9: return '9_Set'
    case 10: return '10_Out'
    case 11: return '11_Nov'
    case 12: return '12_Dez'
  }
}

async function transformPsdata(ps, mes){
  let ps2 = ps
  let diasSafra = ps['psDiasSafra'][mes]
  let uptimeGeral = ps['psUptimeGeral'][mes]/100  
  ps2['psDiasSafra'][mes] = ps['psDiasSafra'][mes]/diasSafra
  ps2['psProdAcucarVHPSC'][mes] = ps['psProdAcucarVHPSC'][mes]/diasSafra
  ps2['psProdEtanolAnidro'][mes] = ps['psProdEtanolAnidro'][mes]/diasSafra/uptimeGeral
  ps2['psProdEtanolTotalHid'][mes] = ps['psProdEtanolTotalHid'][mes]/diasSafra/uptimeGeral
  ps2['psProdEtanolTotal'][mes] = ps['psProdEtanolTotal'][mes]/diasSafra/uptimeGeral
  ps2['psProdTotalEtanol100'][mes] = ps['psProdTotalEtanol100'][mes]/diasSafra/uptimeGeral
  ps2['psProdBagacao'][mes] = ps['psProdBagacao'][mes]/diasSafra
  ps2['psConsBagacao'][mes] = ps['psConsBagacao'][mes]/diasSafra
  ps2['psSobraBagaco'][mes] = ps['psSobraBagaco'][mes]/diasSafra
  ps2['psVendaBagaco'][mes] = ps['psVendaBagaco'][mes]/diasSafra
  
  return ps2
}