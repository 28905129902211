
import React, { createContext, useState } from 'react';

// Criando o contexto
export const AnalisesContext = createContext();

// Provedor de contexto
export const AnalisesContextProvider = ({ children }) => {
  const [showImportBoletim, setShowImportBoletim] = useState(false); 
  const [showAnaliseDia, setShowAnaliseDia] = useState(true); 
  const [showBltxPlano, setShowBltxPlano] = useState(false); 
  const [isDataComplete, setIsDataComplete] = useState(false); 
  const [selectedDate, setSelectedDate] = useState(null); 
  const [bltData, setBltData] = useState(null)
  const [psAnData, setPsAnData] = useState(null)
  const [anDate, setAnDate] = useState('')
  
  
  return (
    <AnalisesContext.Provider value={{ 
        showImportBoletim, setShowImportBoletim, showAnaliseDia, setShowAnaliseDia, isDataComplete, setIsDataComplete, showBltxPlano, setShowBltxPlano,
        selectedDate, setSelectedDate, bltData, setBltData, psAnData, setPsAnData, anDate, setAnDate
        }}>
      {children}
    </AnalisesContext.Provider>
  )
}

export default AnalisesContext;