import React, {useContext, useState, useRef } from 'react';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import Modal from '../../components/BarraSuperior/Modal';
import Headlandscape from '../../components/ui/HeadLandscape';
import AnalyticsHooks from '../../Hooks/analyticsHooks';

import { FaRegFilePdf } from "react-icons/fa6";
import { ACContext } from './AnaliseCenariosContext';
import BodyAnaliseCenarios from './BodyAnaliseCenarios';
import GlobalStateContext from '../../components/GlobalStateContext';
import BodyAnaliseCenariosToPrint from './BodyAnaliseCenariosToPrint';

export default function BtExportCenariosToPDF() {
  const divRefs = useRef([]);
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [modalText, setModalText] = useState('...');
  const {isEditing, AcData, setIsPrinting, fileName} = useContext(ACContext)
  const {user, site} = useContext(GlobalStateContext)
  
  const {newAction} = AnalyticsHooks()


  const addToRefs = (el) => {

    if (el && !divRefs.current.includes(el)) {
      divRefs.current.push(el);
    }
  };

    async function exportPDF(){
      newAction('expPDFAnalCenario', site, user.email || '')
      setIsPrinting(true)
      setIsLoading(true)
      setProgress(0)
      setModalText('Gerando PDF...')
      let progressValue = 0;
      const interval = setInterval(() => {
        progressValue += 5;
        if (progressValue <= 100) {
          setProgress(progressValue)
        } else {
          clearInterval(interval)
          setIsLoading(false)
          setIsPrinting(false)
        }
      }, 6);
     
      const pdf = new jsPDF('l', 'mm', 'a4', true);
      
      let promiseChain = Promise.resolve();
  
      divRefs.current.forEach((div, index) => {
        
        promiseChain = promiseChain.then(async () => {
          const canvas = await html2canvas(div);
          const imgData = canvas.toDataURL('image/png');
          const imgWidth = 277; // largura em mm para A4
          const imgHeight = 190; //(canvas.height * imgWidth) / canvas.width;

          pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight);
          if (index < divRefs.current.length - 1){
            pdf.addPage("a4", "l");
          }
        })
      });
  
      promiseChain.then(() => {
        pdf.save(`${fileName}.pdf`)
      }).catch(err => {
        console.error('Erro ao gerar PDF:', err);
      });
    
    };

    const pages = [
        {'MOAGEM':[0,100], 'PRODUÇÃO AÇÚCAR':[0,100], 'PRODUÇÃO ETANOL':[0,100]},
        {'ENERGIA':[0,100], 'BAGAÇO':[0,100], 'PERFORMANCE':[0,100], 'RECEITA BRUTA':[0,100]},
        // {'EVAPORAÇÃO / FÁBRICA':[0,100], 'RECEITA BRUTA':[0,100]},
    ]


  return (
    <div className='psMenuButtom' >
        {AcData &&
            <div style={{ position: 'absolute', left: '-9999px' , top: '9999px'}}>
                {pages.map((page, index) => (
                        <div key={index} ref={addToRefs} style={{margin: '10px', backgroundColor: '#ffffff', height: '794px', position: 'relative', border: '1px solid black'}}>
                        <Headlandscape title='ANÁLISE DE CENÁRIOS' 
                            subtitle='' 
                            fileName={fileName}
                            nomeField='' page={`${index+1}`} pages={`${pages.length}`}/>
                        <div style={{height: '630px', width: '1000px', left: '0', top: '0', position: 'relative',  lineHeight: '1' }}>
                            <BodyAnaliseCenariosToPrint secs={page}/>
                        </div>
                    </div>
                ))}
                <></>
            </div>}

            <div style={{display: 'flex', flexDirection: 'row'}}>
                <button className='psButtom' onClick={exportPDF} disabled={isEditing}>
                <div className='psMenuButtom' >

                <FaRegFilePdf   className='psMenuIcon'/>
                <div className='psMenuText'>EXPORTAR</div></div>
                </button>
                <Modal show={isLoading} progress={progress} texto={modalText}/>
            </div>

    </div>
  )
}



