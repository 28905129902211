import React, {useContext, useEffect, useState, useRef } from 'react';
import PSContext from '../opoio/PSContext';
import GlobalStateContext from '../../components/GlobalStateContext';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import Modal from '../../components/BarraSuperior/Modal';
import Headlandscape from '../../components/ui/HeadLandscape';
import PsTest from '../PsTest';
import { UseColors } from '../../components/ui/colors';
import { FaRegFilePdf } from "react-icons/fa6";
import { BsFiletypeXlsx } from "react-icons/bs";
import AnalyticsHooks from '../../Hooks/analyticsHooks';
import MenuBtDivider from '../../components/ui/menuBtDivider';
import SubMenuCharts from '../subMenus/subMenuCharts';

import * as XLSX from 'xlsx';

export default function BtExportPsToXlsx() {
  const divRefs = useRef([]);
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [modalText, setModalText] = useState('...');
  const { site, user } = useContext(GlobalStateContext)
  const {isSave, isNew, isSaveAs, isOpen, isEdit, isChart, psData, setPsIsPrinting} = useContext(PSContext)
  const [isDisable, setIsDisable] = useState(false)
  
  const {newAction} = AnalyticsHooks()

  const COLORS = UseColors()

  useEffect(() => {
    if(isOpen || !psData){
      setIsDisable(true)
    }else{
      setIsDisable(false)
    }
  }, [isSave, isNew, isSaveAs, isOpen, isEdit, isChart])

  const addToRefs = (el) => {

    if (el && !divRefs.current.includes(el)) {
      divRefs.current.push(el);
    }
  };

  function exportJsonToExcel(jsonData, fileName = "export.xlsx") {
    // Preprocessar os dados para excluir e ordenar colunas
    const processedData = preprocessData(jsonData);
  
    // Converter o JSON para uma planilha
    const worksheet = XLSX.utils.json_to_sheet(processedData);
  
    // Criar um novo livro de trabalho (workbook)
    const workbook = XLSX.utils.book_new();

    let safra = jsonData[0].safra.replace("/", "-")
    let revisao = jsonData[0].revisao
    let sheetName = `${safra} - ${revisao}`
    // console.log('sheetName', sheetName)
  
    // Adicionar a planilha ao livro de trabalho
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
  
    // Gerar o arquivo Excel e fazer o download
    XLSX.writeFile(workbook, fileName);
  }

  function preprocessData(jsonData) {
    // Defina as chaves que serão excluídas
    const excludeKeys = ["ssr", "input", "codigo", "siteId"];
    
    // Ordem desejada das colunas
    const orderedKeys = ["safra", "revisao", "secao", "descricao", "unidade", "jan", "fev", "mar", "abr", "mai", "jun", "jul", "ago", "set", "out", "nov", "dez", "acu"];
    
    // Processa cada objeto no JSON
    return jsonData.map(item => {
      // Remove as colunas indesejadas
      const filteredItem = Object.fromEntries(
        Object.entries(item).filter(([key]) => !excludeKeys.includes(key))
      );
  
      // Ordena as colunas na ordem desejada
      const orderedItem = {};
      orderedKeys.forEach(key => {
        if (key in filteredItem) {
          orderedItem[key] = filteredItem[key];
        }
      });
  
      return orderedItem;
    });
  }

   

    if (!isOpen && !isEdit){
  return (
    <div style={{display: 'flex', flexDirection: 'row'}}>
     
      
      <MenuBtDivider/>
      <button className='psButtom' onClick={() => exportJsonToExcel(psData, `${psData[0].safra} - ${psData[0].revisao}.xlsx`)}
        disabled={isDisable}>
        <div className='psMenuButtom' >
        <BsFiletypeXlsx  className='psMenuIcon' />
        <div className='psMenuText'>EXPORTAR</div></div>
      </button>
      <Modal show={isLoading} progress={progress} texto={modalText}/>
    
  
    </div>
  )
}
}