module.exports = {
    getScreenDataUsSantaElisa
}
    

function getScreenDataUsSantaElisa(area, screen, data){
    if (area === 'resumoProd'){
      return resProd
    } 
    if (area === 'precos'){
      return precos
    } 

    switch (''+area+','+screen){
        case 'extracao,fluxos': return extFlows
        case 'extracao,acionM1': return extAcionM1
        case 'extracao,acionM2': return extAcionM2
        case 'extracao,capacidades': return extCap
        case 'tratAcucar,fluxos': return tratAcFlows
        case 'tratAcucar,aquec': return tratAcAquec(data)
        case 'tratAcucar,regen': return tratAcRegen(data)
        case 'tratEtanol,fluxos': return tratEtFlows
        case 'tratEtanol,equipamentos': return tratEtEquips
        case 'filtLodo,equipamentos': return filtEquips
        case 'evaporacao,fluxos': return evapFlows
        case 'evaporacao,equipamentos': return evapEquips
        case 'evaporacao,capacidades': return evapCap
        case 'fermentacao,equipamentos': return fermEquips
        case 'fermentacao,fluxos': return fermFlows
        case 'fermentacao,capacidades': return fermCap
        case 'destilaria,premissas': return destPremissas(data)
        case 'destilaria,equipamentos': return destEquips(data)
        case 'fabrica,premissas': return fabPrem
        // case 'fabrica,fluxos': return fabFlows
        case 'fabrica,equipamentos': return fabEquips
        case 'fabrica,capacidades': return fabCap
        case 'vapor,premissas': return vapPrem(data)
        case 'vapor,turboger': return vapTGs(data)
        case 'vapor,boilers': return vapBoilers(data)
        case 'vapor,capacidades': return vapCap
        case 'balHidrico,fluxos': return bhPrem
        case 'balHidrico,equipamentos': return bhEquips
        default: return [{}]
    }
}

const resProd = 
  [
    { 
      col: 1,
      sectionName: 'MOAGEM',
      props: ['flowCana', 'flowCanaDia', 'artCana', 'fibraCana', 'embCana', 'fibraEmbeb'],
      ipt: [false,false,false,false,false, false]
    },
    { 
      col: 1,
      sectionName: 'PRODUÇÃO AÇÚCAR',
      props: ['mixAcucar', 'flowAcucar', 'flowAcucarScsdia', 'flowAcucarScsTc', 'flowAcucarTondia', 'recupSJM'],
      ipt: [false,false,false,false,false,false]
    },
    { 
      col: 1,
      sectionName: 'PRODUÇÃO ETANOL',
      props: ['flowVolEtanolProd100Dia', 'flowEtanolDia', 'flowEtanolHidDia', 'mixEtanolAnidro', 'flowEtanolAnidroDia', 'mixEtanolNeutro', 'flowEtanolNeutroDia', 'flowEtanolTotal', 'efFermentacao', 'efDestilaria', 'rgd'],
      ipt: [false,false,false,false,false,false,false,false,false,false,false]
    },
    // { 
    //   col: 1,
    //   sectionName: 'EVAPORAÇÃO / FÁBRICA',
    //   props: ['taxaEvapPreEvapAcucar', 'taxaEvap2EfEvapAcucar', 'taxaMediaEvapUltimosEf', 'ciclosMassaA', 'ciclosMassaB'],
    //   ipt: [false,false,false,false,false]
    // },
    { 
      col: 2,
      sectionName: 'ENERGIA',
      props: ['expEnergia', 'consEnergia', 'expEnergiaDia', 'consEnergiaDia', 'expEnergiaTC', 'consEspEnergiaTC'],
      ipt: [false,false,false,false,false,false]
    },
    { 
      col: 2,
      sectionName: 'BAGAÇO',
      props: ['flowBagaco', 'umidBagaco', 'sobraBagaco'],
      ipt: [false,false,false]
    },
    { 
      col: 2,
      sectionName: 'PERFORMANCE',
      props: ['efIndustrial', 'ritSTAB', 'ritART', 'relProdAcucarAtrEnt', 'artProdutos', 'consVaporTC', 'consAguaTC'],
      ipt: [false,false,false,false,false,false,false]
    },
    { 
      col: 2,
      sectionName: 'PERDAS',
      props: ['perdaArtExtracao', 'perdaFermDest', 'perdaArtLavagemCana', 'perdaArtTorta', 'perdaArtMultijatoFabrica', 'perdaArtAguaResiduaria', 'perdaArtIndeterminda', 'perdaTotalArt'],
      ipt: [false,false,true,true,true,true,true,false]
    }
  ]

const precos = 
[
  { 
    col: 1,
    sectionName: 'PREÇOS',
    props: ['precoAcucar', 'precoEtanolHid', 'precoEtanolAnidro', 'precoEtanolNeutro', 'precoEnergia', 'precoBagaço', 'receitaTotal'],
    ipt: [true,true,true,true,true,true, false]
  }
]

const extFlows = 
  [
    { 
      col: 1,
      sectionName: 'CANA',
      props: ['flowCana', 'horasEfetivas', 'flowCanaDia', 'artCana', 'atrCanaPCTS', 'purezaCana', 'fibraCana', 'arCana', 'tempCana','polCana', 'brixCana'],
      ipt: [true,true,false,true,false,true,true,true,true,false,false]
    },
    { 
      col: 1,
      sectionName: 'EMBEBIÇÃO',
      props: ['flowEmbeb', 'embCana', 'fibraEmbeb', 'tempEmbeb'],
      ipt: [false, true, false, true]
    },
    { 
      col: 2,
      sectionName: 'BAGAÇO',
      props: ['flowBagaco', 'efExtMoenda', 'fibraBagaco', 'umidBagaco', 'artBagaco','polBagaco'],
      ipt: [false,true,true,true,true,false,false]
    },
    { 
      col: 2,
      sectionName: 'CALDO',
      props: ['extCaldo1T', 'caldoPrimToAcucar', 'caldoSecToAcucar'],
      ipt: [true, true, true]
    },
    { 
      col: 2,
      sectionName: 'OUTROS',
      props: ['consCondVGLimpPeneira'],
      ipt: [true]
    }
  ]

const extAcionM1 = 
  [
    { 
      col: 1,
      sectionName: 'MOAGEM',
      props: ['relM1M2', 'flowCanaM1', 'tfhM1'],
      ipt: [true, false, false]
    },
    { 
      col: 1,
      sectionName: 'ACIONAMENTO PICADOR 1',
      props: ['tempVaporVEPic1M1', 'consEspEnergiaPic1M1', 'consEspVaporPic1M1', 'consVaporV22Pic1M1', 'ptPic1M1'],
      ipt: [true, true, true, false, false]
    },
    { 
      col: 1,
      sectionName: 'ACIONAMENTO PICADOR 2',
      props: ['tempVaporVEPic2M1', 'consEspEnergiaPic2M1', 'consEspVaporPic2M1', 'consVaporV22Pic2M1', 'ptPic2M1'],
      ipt: [true, true, true, false, false]
    },
    { 
      col: 1,
      sectionName: 'ACIONAMENTO DESFIBRADOR',
      props: ['tempVaporVEDesfM1', 'consEspEnergiaDesfM1', 'consEspVaporDesfM1', 'consVaporV22DesfM1', 'ptDesfM1'],
      ipt: [true, true, true, false, false]
    },
    { 
      col: 2,
      sectionName: 'ACIONAMENTO 1º e 2º TERNOS',
      props: ['tempVaporVEAc12TM1', 'consEspEnergiaAc12TM1', 'consEspVaporAc12TM1', 'consVaporV22Ac12TM1', 'ptAc12TM1'],
      ipt: [true, true, true, false, false]
    },
    { 
      col: 2,
      sectionName: 'ACIONAMENTO 3º e 4º TERNOS',
      props: ['tempVaporVEAc34TM1', 'consEspEnergiaAc34TM1', 'consEspVaporAc34TM1', 'consVaporV22Ac34TM1', 'ptAc34TM1'],
      ipt: [true, true, true, false, false]
    },
    { 
      col: 2,
      sectionName: 'ACIONAMENTO 5º e 6º TERNOS',
      props: ['tempVaporVEAc56TM1', 'consEspEnergiaAc56TM1', 'consEspVaporAc56TM1', 'consVaporV22Ac56TM1', 'ptAc56TM1'],
      ipt: [true, true, true, false, false]
    }
  ]

const extAcionM2 = 
  [
    { 
      col: 1,
      sectionName: 'MOAGEM',
      props: ['flowCanaM2', 'tfhM2'],
      ipt: [false, false]
    },
    { 
      col: 1,
      sectionName: 'ACIONAMENTO NIVELADOR',
      props: ['tempVaporVENivM2', 'consEspEnergiaNivM2', 'consEspVaporNivM2', 'consVaporV22NivM2', 'ptNivM2'],
      ipt: [true, true, true, false, false]
    },
    { 
      col: 1,
      sectionName: 'ACIONAMENTO PICADOR 1',
      props: ['tempVaporVEPic1M2', 'consEspEnergiaPic1M2', 'consEspVaporPic1M2', 'consVaporV22Pic1M2', 'ptPic1M2'],
      ipt: [true, true, true, false, false]
    },
    { 
      col: 1,
      sectionName: 'ACIONAMENTO PICADOR 2',
      props: ['tempVaporVEPic2M2', 'consEspEnergiaPic2M2', 'consEspVaporPic2M2', 'consVaporV22Pic2M2', 'ptPic2M2'],
      ipt: [true, true, true, false, false]
    },
    { 
      col: 2,
      sectionName: 'ACIONAMENTO DESFIBRADOR',
      props: ['tempVaporVEDesfM2', 'consEspEnergiaDesfM2', 'consEspVaporDesfM2', 'consVaporV22DesfM2', 'ptDesfM2'],
      ipt: [true, true, true, false, false]
    },
    { 
      col: 2,
      sectionName: 'ACIONAMENTO 1º e 2º TERNOS',
      props: ['tempVaporVEAc12TM2', 'consEspEnergiaAc12TM2', 'consEspVaporAc12TM2', 'consVaporV22Ac12TM2', 'ptAc12TM2'],
      ipt: [true, true, true, false, false]
    },
    { 
      col: 2,
      sectionName: 'ACIONAMENTO 3º e 4º TERNOS',
      props: ['tempVaporVEAc34TM2', 'consEspEnergiaAc34TM2', 'consEspVaporAc34TM2', 'consVaporV22Ac34TM2', 'ptAc34TM2'],
      ipt: [true, true, true, false, false]
    },
    { 
      col: 2,
      sectionName: 'ACIONAMENTO 5º e 6º TERNOS',
      props: ['tempVaporVEAc56TM2', 'consEspEnergiaAc56TM2', 'consEspVaporAc56TM2', 'consVaporV22Ac56TM2', 'ptAc56TM2'],
      ipt: [true, true, true, false, false]
    }
  ]

  const extCap = 
  [
    { 
      col: 1,
      sectionName: 'MOENDA FARREL',
      props: ['tfhM1', 'tfhM1'],
      ipt: ['min', 'max'],
      desc: ['Moagem Mínima', 'Capacidade Moagem']
    },

    { 
      col: 2,
      sectionName: 'MOENDA ZANINI',
      props: ['tfhM2', 'tfhM2'],
      ipt: ['min', 'max'],
      desc: ['Moagem Mínima', 'Capacidade Moagem']
    },
    
  ]

// function extEquips(data){ 
//     return [
//     { 
//       col: 1,
//       sectionName: 'AQUECIMENTO',
//       props: [
//         'qtdeOpAqDifusor', 'areaAqDifusor', 'coefTrocaAqDifusor', 'relCaldoEscCana', 'flowCaldoEscaldante', 'tempCaldoEscaldante','tempOutAqDifusor', 'utilAqDifusor',
//         data['vaporUtilizadoDifusor'].valor === 0 ? 'consVaporVV2AquecDifusor' : 'consVaporVV1AquecDifusor', 
//         data['vaporUtilizadoDifusor'].valor === 0 ? 'consVaporVV2BorbDifusor' : 'consVaporVV1BorbDifusor', 
//         data['vaporUtilizadoDifusor'].valor === 0 ? 'consVaporVV2AquecDirDifusor' : 'consVaporVV1AquecDirDifusor', 
//         data['vaporUtilizadoDifusor'].valor === 0 ? 'consVV2TotalDifusor' : 'consVV1TotalDifusor', 
//     ],
//       ipt: ['combo',true,true,true,false,true,true,false,false,true,false,false]
//     },
//     { 
//       col: 1,
//       sectionName: 'ACIONAMENTO NIVELADOR',
//       props: ['tempVaporVENivelador', 'consEspEnergiaNivelador', 'consEspVaporNivelador', 'consVaporVMNivelador', 'ptNivelador'],
//       ipt: [true,true,true,false,false]
//     },
//     { 
//       col: 1,
//       sectionName: 'ACIONAMENTO PICADOR',
//       props: ['tempVaporVEPicador', 'consEspEnergiaPicador', 'consEspVaporPicador', 'consVaporVMPicador', 'ptPicador'],
//       ipt: [true,true,true,false,false]
//     },
//     { 
//       col: 2,
//       sectionName: 'ACIONAMENTO DESFIBRADOR',
//       props: ['tempVaporVEDesfibrador', 'consEspEnergiaDesfibrador', 'consEspVaporDesfibrador', 'consVaporVMDesfibrador', 'ptDesfibrador'],
//       ipt: [true,true,true,false,false]
//     },
//     { 
//       col: 2,
//       sectionName: 'ACIONAMENTO TERNO DESAGUADOR',
//       props: ['tempVaporVEAcion12T', 'consEspEnergiaAcion12T', 'consEspVaporAcion12T', 'consVaporAcion12T', 'ptAcion12T'],
//       ipt: [true,true,true,false,false]
//     },
//     { 
//       col: 2,
//       sectionName: 'ACIONAMENTO TERNO SECAGEM',
//       props: ['tempVaporVEAcion34T', 'consEspEnergiaAcion34T', 'consEspVaporAcion34T', 'consVaporAcion34T', 'ptAcion34T'],
//       ipt: [true,true,true,false,false]
//     }
//   ]
// }

const tratAcFlows = 
  [
    { 
      col: 1,
      sectionName: 'DOSAGEM CAL',
      props: ['grausBaume', 'dosagemCalAcucar'],
      ipt: [true,true]
    },
    { 
      col: 1,
      sectionName: 'DOSAGEM POLIMERO',
      props: ['propDosagemPolAcucar'],
      ipt: [true]
    },
    { 
      col: 2,
      sectionName: 'DECANTAÇÃO',
      props: ['lodoCaldoFlashAcucar', 'quedaTempDecantador'],
      ipt: [true,true]
    },
    { 
      col: 2,
      sectionName: 'DIVISÃO CALDO FILTRADO',
      props: ['caldoFiltToAcucar', 'flowCaldoFiltToAcucar', 'flowCaldoFiltToEtanol'],
      ipt: [true,false,false]
    }
  ]

function tratAcRegen(data) {
    return [
        { 
          col: 1,
          sectionName: 'REGENERADOR CALDO x VINHAÇA',
          props: ['opRegCaldoVinhaca', 'tempVinhaca', 'tempVinhacaReg', 'tempOutCaldoRegCaldoVinhaca', 'regCvCTareaTroca','uRegCaldoVinhaca', 'regCvCTareaTrocaTotal','regCvCTareaReq', 'mldtRegCaldoVinhaca'],
          ipt: ['combo',true,true,false,true,true,false,false,false]
        },
        { 
          col: 1,
          sectionName: 'REG. CALDO x CONDENSADO VEGETAL GERAL',
          props: ['opRegCaldoCond', 'tempCondVG', 'tempCondReg', 'tempOutCaldoRegCaldoCond', 'regCxCVGCTareaTroca', 'uRegCaldoCond', 'regCxCVGCTareaTrocaTotal', 'regCxCVGCTareaReq', 'mldtRegCaldoCond'],
          ipt: ['combo',true,true,false,true,true,false,false,false]
        },
        { 
          col: 2,
          sectionName: 'REG. CALDO x CONDENSADO VG1',
          props: ['opRegCaldoCondVG1', 'tempCondVG1EvapAc', 'tempCondVG1Reg', 'tempOutRegCaldoCondVG1', 'regCxCVG1CTareaTroca', 'uRegCaldoCondVG1', 'regCxCVG1CTareaTrocaTotal', 'regCxCVG1CTareaReq', 'mldtRegCaldoCondVG1'],
          ipt: ['combo',true,true,false,true,true,false,false,false]
        }
      ]
}
function tratAcAquec(data) {
return [
  { 
    col: 1,
    sectionName: 'PRIMEIRO AQUECIMENTO COM VV1',
    props: ['qtdeOpAqVV1Acucar2', 'tempOutAqVV1Acucar2', 'utilAqVV1Acucar2', 'consVaporVV1AqAcucar2', 'coefTrocaAqVV1Acucar2', 'areaAqVV1Acucar2'],
    ipt: ['combo',true,false,false,true,true]
  },
    { 
      col: 1,
      sectionName: 'AQUECIMENTO COM VV2',
      props: ['qtdeOpAqVV2Acucar', 'tempOutAqVV2Acucar', 'utilAqVV2Acucar', 'consVaporVV2AqAcucar', 'coefTrocaAqVV2Acucar', 'areaAqVV2Acucar'],
      ipt: ['combo',true,false,false,true,true]
    },
    { 
      col: 2,
      sectionName: 'AQUECIMENTO COM VV1',
      props: ['qtdeOpAqVV1Acucar', 'tempOutAqVV1Acucar', 'utilAqVV1Acucar', 'consVaporVV1AqAcucar', 'coefTrocaAqVV1Acucar', 'areaAqVV1Acucar'],
      ipt: ['combo',true,false,false,true,true]
    },
    { 
      col: 2,
      sectionName: 'AQUECIMENTO CALDO CLARIFICADO',
      props: ['qtdeOpAqCCAcucar',  'tempOutAqCCAcucar', 'utilAqCCAcucar', 
      data['opVaporAqCCAcucar'].valor === 1 ? 'consVaporVV2AqCCAcucar' : data['opVaporAqCCAcucar'].valor === 2 ? 'consVaporVV1AqCCAcucar' : 'consVaporVEAqCCAcucar',
      'coefTrocaAqCCAcucar', 'areaAqCCAcucar'],
      ipt: ['combo',true,false,false,true,true]
    }
  ]
}

const tratEtFlows = 
  [
    { 
      col: 1,
      sectionName: 'DOSAGEM CAL',
      props: [ 'dosagemCalEtanol'],
      ipt: [true]
    },
    { 
      col: 1,
      sectionName: 'DOSAGEM POLIMERO',
      props: ['propDosagemPolEtanol'],
      ipt: [true]
    },
    { 
      col: 2,
      sectionName: 'DECANTAÇÃO',
      props: ['lodoCaldoFlashEtanol', 'quedaTempDecantador'],
      ipt: [true,true]
    }
  ]

const tratEtEquips = [
    { 
      col: 1,
      sectionName: 'REGENERADOR CALDO x CALDO CLARIFICADO',
      props: ['opRegCaldoCaldo', 'tempCaldoClarifEtanol', 'tempCaldoClarReg', 'tempOutCaldoRegCaldoCaldo', 'regCEtxCCEtareaTroca','uRegCaldoCaldo', 'regCEtxCCEtareaTrocaTotal', 'regCEtxCCEtareaReq', 'mldtRegCaldoCaldo'],
      ipt: ['combo',true,true,false,true,true,false,false, false]
    },
    { 
      col: 2,
      sectionName: 'AQUECIMENTO COM VV2',
      props: ['qtdeOpAqVV2Etanol', 'tempOutAqVV2Etanol', 'utilAqVV2Etanol', 'consVaporVV2AqEtanol', 'coefTrocaAqVV2Etanol', 'areaAqVV2Etanol'],
      ipt: ['combo',true,false,false,true,true]
    },
    { 
      col: 2,
      sectionName: 'AQUECIMENTO COM VV1',
      props: ['qtdeOpAqVV1Etanol', 'tempOutAqVV1Etanol', 'utilAqVV1Etanol', 'consVaporVV1AqEtanol', 'coefTrocaAqVV1Etanol', 'areaAqVV1Etanol'],
      ipt: ['combo',true,false,false,true,true]
    }
]

const filtEquips = [
  { 
    col: 1,
    sectionName: 'GERAL',
    props: ['prodTorta', 'perdaArtTorta', 'tempCaldoFiltrado', 'relBagacilhoCana'],
    ipt: [true,true,true,true]
  },
  { 
    col: 1,
    sectionName: 'FILTRO ROTATIVO',
    props: ['embLodoFitroRot', 'relFiltroRotativo', 'flowAguaEmbebicaoFiltroRot', 'flowLodoFiltroRotativo'],
    ipt: [true,false,false,false]
  },
  { 
    col: 2,
    sectionName: 'FILTRO PRENSA',
    props: ['embLodoFiltroPrensa', 'flowAguaLavTelasFiltroPre', 'relFiltroPrensa', 'flowAguaEmbebicaoFiltroPre', 'flowLodoFiltroPrensa'],
    ipt: [true,true,true,false,false]
  }
]

const evapFlows = [
  { 
    col: 1,
    sectionName: 'XAROPE',
    props: ['brixXarope', 'desvioXaropeToEtanol'],
    ipt: [true,true]
  },
  // { 
  //   col: 2,
  //   sectionName: 'DESVIO CALDO 3º EFEITO',
  //   props: ['flowSangriaCaldo3Ef'],
  //   ipt: [true]
  // }
]

const evapEquips = [
  { 
    col: 1,
    sectionName: 'EVAPORAÇÃO AÇÚCAR',
    props: ['areaOpPreEvapAcucar', 'areaOp2EfEvapAcucar', 'areaOp3EfEvapAcucar', 'areaOp4EfEvapAcucar', 'areaOp5EfEvapAcucar'],
    ipt: [true,true,true,true,true]
  },
  { 
    col: 2,
    sectionName: 'GERAL',
    props: ['perdasTermicaEvap'],
    ipt: [true]
  }
  
]

const evapCap = [
  { 
    col: 1,
    sectionName: 'TAXA PRÉ-EVAPORAÇÃO',
    props: ['minTaxaEvapPreEvapAcucar', 'maxTaxaEvapPreEvapAcucar'],
    ipt: [true,true]
  },
  { 
    col: 1,
    sectionName: 'TAXA EVAPORAÇÃO 2º EFEITO',
    props: ['minTaxaEvap2Ef', 'maxTaxaEvap2Ef'],
    ipt: [true, true]
  },
  { 
    col: 1,
    sectionName: 'TAXA EVAPORAÇÃO 3º EFEITO',
    props: ['minTaxaEvap3Ef', 'maxTaxaEvap3Ef'],
    ipt: [true, true]
  },
  { 
    col: 2,
    sectionName: 'TAXA EVAPORAÇÃO 4º EFEITO',
    props: ['minTaxaEvap4Ef', 'maxTaxaEvap4Ef'],
    ipt: [true, true]
  },
  { 
    col: 2,
    sectionName: 'TAXA EVAPORAÇÃO 5º EFEITO',
    props: ['minTaxaEvap5Ef', 'maxTaxaEvap5Ef'],
    ipt: [true, true]
  }
  
]

const fermEquips = [
  { 
    col: 1,
    sectionName: 'CENTRÍFUGAS',
    props: ['eficCentrifugas', 'capacCentrifugas', 'sobraCentrifugas'],
    ipt: [true,false,false]
  },
  { 
    col: 1,
    sectionName: 'RESFRIAMENTO DORNAS',
    props: ['tempAguaFriaTorreFerm', 'tempAguaQuenteTorreFerm', 'flowAguaTorreFerm'],
    ipt: [false,false,false]
  },
  { 
    col: 2,
    sectionName: 'RESFRIAMENTO MOSTO',
    props: ['tempMostoReg', 'tempMostoResf', 'tempAguaFriaTorreFerm', 'tempAguaSaiResfMosto', 'coefTrocaResfMosto', 'flowAguaResfMosto', 'approachResfMosto', 'areaNecessariaResfMosto'],
    ipt: [false,true,false,true,true,false,false,false]
  },
  
]

const fermFlows = [
  { 
    col: 1,
    sectionName: 'PREMISSAS',
    props: ['brixMosto', 'concCelVinhoBruto', 'concCelCreme', 'concCelVinhoCent', 'taxaReciclo', 'rendCelula', 'efFermentacao'],
    ipt: [true,true,true,true,true,true,true]
  },
  { 
    col: 1,
    sectionName: 'DILUIÇÃO MOSTO',
    props: ['consCondContDilMosto', 'tempAguaDilMosto', 'consAguaTratPrepMosto', 'flowAguaDilMosto'],
    ipt: [true,true,false,false]
  },
  { 
    col: 2,
    sectionName: 'TEMPERATURAS',
    props: ['tempXarope', 'tempMelFinal', 'tempMostoResf', 'tempAguaDilMosto'],
    ipt: [true,true,true,true]
  },
  { 
    col: 2,
    sectionName: 'ESTOQUE MEL',
    props: ['flowMelpEstoque', 'flowMelEstoque', 'opPropMelEstoque', 'brixMelEstoque', 'artMelEstoque', 'tempMelEstoque'],
    ipt: [true,true,'switch', true,true, true]
  }
]

const fermCap = [
  {col:1, sectionName: "CENTRÍFUGAS VINHO",
  props: [['capCentVinho1', 'opCentVinho1'], ['capCentVinho2', 'opCentVinho2'],['capCentVinho3', 'opCentVinho3'],['capCentVinho4', 'opCentVinho4'],['capCentVinho5', 'opCentVinho5'],
  ['capCentVinho6', 'opCentVinho6'],['capCentVinho7', 'opCentVinho7'],['capCentVinho8', 'opCentVinho8'],['capCentVinho9', 'opCentVinho9'],['capCentVinho10', 'opCentVinho10']],
  ipt: ['cap', 'cap', 'cap', 'cap', 'cap', 'cap', 'cap', 'cap', 'cap', 'cap']},
  {col:2, sectionName: "RESFRIAMENTO MOSTO",
  props: [['capResfMosto1', 'opResfMosto1'], ['capResfMosto2', 'opResfMosto2'], ['capResfMosto3', 'opResfMosto3']],
  ipt: ['cap', 'cap', 'cap']},
  {col:2, sectionName: "TORRES",
    props: ['capTorreResfMosto', 'capTorreResfDornas'],
    ipt: [true, true]},
]

function destPremissas(data){
  return [
    { 
      col: 1,
      sectionName: 'GERAL',
      props: ['relEtanolSegProd', 'relOleoFuselProd', 'relPerdaDegasProducao', 'concEtanolVinhaca', 'concEtanolFlegmaca', 'concEtanolFlegma'],
      ipt: [true,true,true,true,true,true]
    },
    { 
      col: 1,
      sectionName: 'CONSUMO VAPOR',
      props: ['consEspVaporDestManual', 'consEspVaporDestilaria', 'glVinhoBoletim', 'glVinhoConsVapor'],
      ipt: ['switch',
      data['consEspVaporDestManual'].valor === 0 ? false : true,
      true,'combo'],
      comboList: ['CALCULADO', 'BOLETIM']
    },
    { 
      col: 1,
      sectionName: 'OUTROS',
      props: ['flowFlegmaca', 'flowVinhaca'],
      ipt: [false,false]
    },
    { 
      col: 2,
      sectionName: 'ETANOL ANIDRO',
      props: ['mixEtanolAnidro', 'flowEtanolAnidro', 'flowEtanolAnidroDia'],
      ipt: [true,false,false]
    },
    { 
      col: 2,
      sectionName: 'ETANOL NEUTRO',
      props: ['mixEtanolNeutro', 'flowEtanolNeutro', 'flowEtanolNeutroDia'],
      ipt: [true,false,false]
    },
    { 
      col: 2,
      sectionName: 'PRODUÇÃO',
      props: ['efDestilaria', 'flowVolEtanolProd100', 'flowEtanol', 'flowEtanolProdToHid', 'flowEtanolAnidro', 'flowEtanolNeutro'],
      ipt: [false,false,false,false,false, false]
    },
    
  ]
} 

function destEquips(data){
  return [
    { 
      col: 1,
      sectionName: 'COLUNA A',
      props: ['vaporColAConv',  'consEspVaporColunaA', 'flowVaporVV1ColAConv', 'flowVaporVEColAConv'
    ],
      ipt: [true,false,false,false]
    },
    { 
      col: 1,
      sectionName: 'COLUNA B',
      props: ['consEspVaporColunaB', 'consVaporVV1ColB'],
      ipt: [true,false]
    },
    { 
      col: 2,
      sectionName: 'COLUNA A2',
      props: data['opVaporEtNeutro'].valor === 0 ? ['opVaporEtNeutro', 'consEspVV1EtNeutro', 'consVaporVV1ColA2'] : 
      ['opVaporEtNeutro', 'consEspVEEtNeutro', 'consVaporVEEtNeutro'],
      ipt: ['combo', true,false]
    },
    
    { 
      col: 2,
      sectionName: 'PENEIRA MOLECULAR',
      props: ['consEspVEAnid', 'consVaporVEPenMol', 'consEspV22Anidro', 'consVaporV22Desidratacao'],
      ipt: [true,false,true,false]
    }
  ]
} 

const fabPrem = [
  { col: 1, sectionName: "PUREZA",
    props: ['purezaXarope', 'purezaMelARico', 'purezaMelAPobre', 'purezaMelBRico', 'purezaMelFinal', 'purezaAcucar', 'purezaAcucarB', 'purezaMagma'],
    ipt: [true, true, true, true, true, true, true, true]},
  { col: 1, sectionName: "DIVISÃO DE MÉIS",
    props: ['marRelTotal', 'mbrRelTotal', 'relMAPDilToDest'],
    ipt: [true, true, true]},
  { col: 1, sectionName: "DIVISÃO DE XAROPE",
    props: ['xaropeToMassaA', 'xaropeToMassaB'],
    ipt: [true, false]},
  { col: 1, sectionName: "VAPOR",
    props: ['relVaporAguaCoz'],
    ipt: [true]},
  { col: 2, sectionName: "BRIX",
    props: ['brixMassaA', 'brixMassaB', 'brixMelARico', 'brixMARDiluido', 'brixMelAPobre', 'brixMAPDiluido', 'brixMelBRico', 'brixMelBRicoDil', 'brixMelFinal', 'brixAcucar', 'brixAcucarB', 'brixMagma'],
    ipt: [true, true, true, true, true, true, true, true, true, true, true, true]},
  { col: 2, sectionName: "CALCULOS",
    props: ['recupSJM', 'concCristaisMassaA', 'concCristaisMassaB', 'retencaoMassaA', 'retencaoMassaB'],
    ipt: [false, false, false, false, false]},
]

// const fabFlows = [
//   { 
//     col: 1,
//     sectionName: 'PUREZA',
//     props: ['purezaXarope', 'purezaMelARico', 'purezaMelAPobre', 'purezaMelFinal', 'purezaAcucar', 'purezaAcucarB', 'purezaMagma'],
//     ipt: [true,true,true,true,true,true,true]
//   },
//   { 
//     col: 1,
//     sectionName: 'BRIX',
//     props: ['brixMassaA', 'brixMelARico', 'brixMARDiluido', 'brixMelAPobre', 'brixMAPDiluido', 'brixMelFinal', 'brixMagma', 'brixMassaB', 'brixAcucarB', 'brixAcucar'],
//     ipt: [true,true,true,true,true,true,true]
//   },
//   { 
//     col: 2,
//     sectionName: 'DIVISÃO DE XAROPE',
//     props: ['xaropeToMassaA', 'xaropeToMassaB'],
//     ipt: [true, false]
//   },
//   { 
//     col: 2,
//     sectionName: 'OPCÃO VAPOR',
//     props: ['qtdeCozMassaAVV1'],
//     ipt: ['combo']
//   },
//   { 
//     col: 2,
//     sectionName: 'CALCULOS',
//     props: ['recupSJM', 'concCristaisMassaA', 'concCristaisMassaB'],
//     ipt: [false,false,false]
//   }
// ]

const fabEquips = [
  {col:1, sectionName: "COZEDORES MASSA A",
  props: ['ciclosMassaA', 'consVaporVV1CozedorMassaA', 'consVaporVELimpCozMA'],
  ipt: [false, false, true]},
  {col:1, sectionName: "COZEDORES MASSA B",
  props: ['ciclosMassaB', 'consVaporVV1CozedorMassaB', 'consVaporVV2CozedorMassaB', 'consVaporVELimpCozMB'],
  ipt: [false, false, false, true]},
  {col:1, sectionName: "SECADOR",
  props: ['consEspVaporSecadorAcucar', 'consVaporVESecadorAcucar', 'flowAcucarSecador'],
  ipt: [true, false, false]},
  {col:2, sectionName: "CENTRÍFUGAS MASSA A",
  props: ['ciclosCentMassaA', 'efCentMassaA', 'capTotalCentMassaA', 'sobraCentMassaA'],
  ipt: [true, true, false, false]},
  {col:2, sectionName: "CENTRÍFUGAS MASSA B",
  props: ['efCentMassaB', 'capTotalCentMassaB', 'sobraCentMassaB'],
  ipt: [true, false, false]}
]

const fabCap = [
    {col:1, sectionName: "COZEDORES MASSA A",
    props: [['capCozMA1', 'opCozMA1'], ['capCozMA2', 'opCozMA2'], ['capCozMA3', 'opCozMA3'], ['capCozMA4', 'opCozMA4'], ['capCozMA5', 'opCozMA5'], ['capCozMA6', 'opCozMA6'], 'maxCiclosCozMA'],
    ipt: ['cap', 'cap', 'cap', 'cap', 'cap', 'cap', true]},
    {col:1, sectionName: "CENTRÍFUGAS MASSA A",
    props: [['capCentMA1', 'opCentMA1'], ['capCentMA2', 'opCentMA2'], ['capCentMA3', 'opCentMA3'], ['capCentMA4', 'opCentMA4'], ['capCentMA5', 'opCentMA5'],
    ['capCentMA6', 'opCentMA6'], ['capCentMA7', 'opCentMA7'], ['capCentMA8', 'opCentMA8'], ['capCentMA9', 'opCentMA9'], ['capCentMA10', 'opCentMA10']],
    ipt: ['cap', 'cap', 'cap', 'cap','cap', 'cap', 'cap', 'cap','cap', 'cap']},
    {col:2, sectionName: "COZEDORES MASSA B",
        props: [['capCozMB1', 'vapCozMB1', 'opCozMB1', 'V2V1'], ['capCozMB2', 'vapCozMB2', 'opCozMB2', 'V2V1'], ['capCozMB3', 'vapCozMB3', 'opCozMB3', 'V2V1'], 'maxCiclosCozMB'],
        ipt: ['capVap', 'capVap', 'capVap', true]},
    {col:2, sectionName: "CENTRÍFUGAS MASSA B",
        props: [['capCentMB1', 'opCentMB1'], ['capCentMB2', 'opCentMB2'], ['capCentMB3', 'opCentMB3'], ['capCentMB4', 'opCentMB4'], ['capCentMB5', 'opCentMB5'],
        ['capCentMB6', 'opCentMB6'], ['capCentMB7', 'opCentMB7']],
        ipt: ['cap', 'cap', 'cap', 'cap','cap', 'cap', 'cap']},
    {col:2, sectionName: "SECADOR",
        props: ['maxFlowAcucarSecador'],
        ipt: [true]},
  ]

function vapPrem(data){
  return [
    { 
      col: 1,
      sectionName: 'ENERGIA',
      props: ['expEnergia', 'expEnergiaTC', 'consEnergia', 'consEspEnergiaTC'],
      ipt: [false, false, false, true]
    },
    { 
      col: 1,
      sectionName: 'PRESSÕES',
      props: ['pressureVE', 'pressureVV1', 'pressureVV2', 'pressureVV3', 'pressureVV4', 'pressureVV5'],
      ipt: [true,true,true,true,true,true,true]
    },
    { 
      col: 1,
      sectionName: 'TEMPERATURAS',
      props: ['tempVaporVE', 'tempDesaerador', 'tempDesaerador2', 'tempRetCondVEDesaerador', 'tempsAguaDesmiDesaerador'],
      ipt: [true, true, true, true, true]
    },
    { 
      col: 2,
      sectionName: 'PERDAS',
      props: ['perdasVM', 'perdasVM', 'perdasVM', 'perdasVE', 'perdasVV1', 'perdasVV2', 'perdasVV3'],
      ipt: [true, true, true, true, true, true, true]
    },
    { 
      col: 2,
      sectionName: 'ALÍVIO',
      props: ['consVaporVEAlivio', 'consVaporVV1Alivio', 'consVaporVV2Alivio'],
      ipt: [false, true, true]
    },
    { 
      col: 2,
      sectionName: 'DESAERADOR',
      props: ['retornoCondVEProcesso', 'relRetCondProDesVAVM', 'gerCondVETgCdVA',
      'consAguaDesmiDesaerador', 'consVaporVEDesaerador'],
      ipt: [true, true, false, false, false]
    }
  ]
} 

function vapTGs(data){
  return [
    { 
      col: 1,
      sectionName: 'TG 2,3 CONTRAPRESSÃO V67',
      props: ['ptTgCpVA', 'consVaporVATgCpVA', 'pressInTgCpVA', 'tempInTgCpVA', 'pressEscTgCpVA', 'tempEscTgCpVA', 'gerVaporVETgCpVA', 'consEspMEdTgCpVA', 'eficIsoTgCpVA'],
      ipt: [false, false, false, false, true, true, false, false, false]
    },
    { 
      col: 1,
      sectionName: 'TG 6,7 CONDENSAÇÃO V42',
      props: ['ptTgCdVM', 'consVaporVMTgCdVM', 'pressInTgCdVM', 'tempInTgCdVM', 'gerCondVETgCdVM', 'pressEscTgCdVM', 'opModeTgCdVM',
      data['opModeTgCdVM'].valor === 1 ? 'titEscTgCdVM' : '', 'tempEscTgCdVM', 'eficIsoTgCdVM', 'consEspMEdTgCdVM'
      ],
      ipt: [true, false, false, false, false, true, 'switch', 
      data['opModeTgCdVM'].valor === 1 ? true : '',
      data['opModeTgCdVM'].valor === 1 ? false : true, false, false 
    ]
    },
    { 
      col: 2,
      sectionName: 'TG 4,5 CONTRAPRESSÃO V42',
      props: ['ptTgCpVM', 'consVaporVMTgCpVM', 'pressInTgCpVM', 'tempInTgCpVM', 'pressEscTgCpVM', 'tempEscTgCpVM', 'gerVaporVETgCpVM', 'consEspTgCpVM', 'eficIsoTgCpVM'],
      ipt: [false, false, false, false, true, true, false, false, false]
    },
  ]
} 

function vapBoilers(data){
  return [
    { col: 1,
      sectionName: 'CALDEIRA V67',
      subSection: 'GERAL',
      props: ['opCalcCaldeirasVA', 'gerVaporVACaldeiras', 'consBagCaldeirasVA', 'pressureVA', 'tempVaporVA', 'purgaDesCaldeirasVA'],
      ipt: ['switch', false, false, true, true, true]
    },
    { col: 1,
      sectionName: '',
      subSection: 'PERFORMANCE',
      props: data['opCalcCaldeirasVA'].valor === 0 ? ['rendCaldeirasVA', 'efPCICaldeirasVA'] : 
      ['o2CaldeirasVA', 'tempGasesCaldeirasVA', 'perdasNqCaldeirasVA', 'perdasIrrCaldeirasVA', 'excessoArCaldeirasVA', 'rendCaldeirasVA', 'efPCICaldeirasVA'],
      ipt: data['opCalcCaldeirasVA'].valor === 0 ? [true, false] :
      [true, true, true, true, false, false, false]
    },
    { col: 1,
      sectionName: '',
      subSection: 'TURBO-BOMBA',
      props: data['opTurboBombaCaldeirasVA'].valor === 0 ? ['opTurboBombaCaldeirasVA'] : 
      ['opTurboBombaCaldeirasVA', 'consEspTBCaldeirasVA', 'tempVETBCaldeirasVA', 'ptTBCaldeirasVA', 'consVaporV22TBCaldeirasVA'],
      ipt: data['opTurboBombaCaldeirasVA'].valor === 0 ? ['switch'] :
      ['switch', true, true, false, false]
    },
    
    { col: 1,
      sectionName: 'CALDEIRA V42',
      subSection: 'GERAL',
      props: ['opCalcCaldeirasVM', 'gerVaporVMCaldeiras', 'consBagCaldeirasVM', 'pressureVM', 'tempVaporVM', 'purgaDesCaldeirasVM'],
      ipt: ['switch', false, false, true, true, true]
    },
    { col: 1,
      sectionName: '',
      subSection: 'PERFORMANCE',
      props: data['opCalcCaldeirasVM'].valor === 0 ? ['rendCaldeirasVM', 'efPCICaldeirasVM'] : 
      ['o2CaldeirasVM', 'tempGasesCaldeirasVM', 'perdasNqCaldeirasVM', 'perdasIrrCaldeirasVM', 'excessoArCaldeirasVM', 'rendCaldeirasVM', 'efPCICaldeirasVM'],
      ipt: data['opCalcCaldeirasVM'].valor === 0 ? [true, false] :
      [true, true, true, true, false, false, false]
    },
    { col: 1,
      sectionName: '',
      subSection: 'TURBO-BOMBA',
      props: data['opTurboBombaCaldeirasVM'].valor === 0 ? ['opTurboBombaCaldeirasVM'] : 
      ['opTurboBombaCaldeirasVM', 'consEspTBCaldeirasVM', 'tempVETBCaldeirasVM', 'ptTBCaldeirasVM', 'consVaporV22TBCaldeirasVM'],
      ipt: data['opTurboBombaCaldeirasVM'].valor === 0 ? ['switch'] :
      ['switch', true, true, false, false]
    },
    { col: 2,
      sectionName: 'CALDEIRAS V22',
      subSection: 'GERAL',
      props: ['opCalcCaldeirasV22', 'gerVaporV22Caldeiras', 'consBagCaldeirasV22', 'pressureV22', 'tempVaporV22', 'purgaDesCaldeirasV22'],
      ipt: ['switch', false, false, true, true, true]
    },
    { col: 2,
      sectionName: '',
      subSection: 'PERFORMANCE',
      props: data['opCalcCaldeirasV22'].valor === 0 ? ['rendCaldeirasV22', 'efPCICaldeirasV22'] : 
      ['o2CaldeirasV22', 'tempGasesCaldeirasV22', 'perdasNqCaldeirasV22', 'perdasIrrCaldeirasV22', 'excessoArCaldeirasV22', 'rendCaldeirasV22', 'efPCICaldeirasV22'],
      ipt: data['opCalcCaldeirasV22'].valor === 0 ? [true, false] :
      [true, true, true, true, false, false, false]
    },
    { col: 2,
      sectionName: '',
      subSection: 'TURBO-BOMBA',
      props: data['opTurboBombaCaldeirasV22'].valor === 0 ? ['opTurboBombaCaldeirasV22'] : 
      ['opTurboBombaCaldeirasV22', 'consEspTBCaldeirasV22', 'tempVETBCaldeirasV22', 'ptTBCaldeirasV22', 'consVaporV22TBCaldeirasV22'],
      ipt: data['opTurboBombaCaldeirasV22'].valor === 0 ? ['switch'] :
      ['switch', true, true, false, false]
    },
  ]
} 

const vapCap = [
  {col:1, sectionName: "CALDEIRAS",
  props: [['maxCaldeiraVA_I', 'opCaldeirasVA'], ['maxCaldeiraVM_I', 'opCaldeirasVM'], ['maxCaldeiraV22_I', 'opCaldeiraV22_I'], ['maxCaldeiraV22_II', 'opCaldeiraV22_II'], ['maxCaldeiraV22_III', 'opCaldeiraV22_III'], ['maxCaldeiraV22_IV', 'opCaldeiraV22_IV']],
  ipt: ['cap', 'cap', 'cap', 'cap', 'cap', 'cap']},
  {col:1, sectionName: "REBAIXADORAS",
  props: ['maxFlowRebVAVE', 'maxFlowRebVAVM', 'maxFlowRebVMV22', 'maxFlowRebV22VE'],
  ipt: [true, true, true, true]},
  {col:2, sectionName: "TURBO-GERADORES",
    props: [['maxFlowInTgCpVa_I', 'opTgCpVa_I'], ['maxFlowInTgCpVa_II', 'opTgCpVa_II'], ['maxFlowInTgCpVm_I', 'opTgCpVm_I'], ['maxFlowInTgCpVm_II', 'opTgCpVm_II'], ['maxFlowInTgCdVm_I', 'opTgCdVm_I'], ['maxFlowInTgCdVm_II', 'opTgCdVm_II']],
    ipt: ['cap', 'cap', 'cap', 'cap', 'cap', 'cap']},
]

const bhPrem = [
  { 
    col: 1,
    sectionName: 'ÁGUA BRUTA',
    props: ['consAguaBrutaGeral'],
    ipt: [true]
  },
  { 
    col: 1,
    sectionName: 'ÁGUA TRATADA',
    props: ['consAguaTratGeral',  'consAguaTratLimpEvap'],
    ipt: [true,true]
  },
  { 
    col: 1,
    sectionName: 'CONDENSADO VEGETAL',
    props: ['consCondContUsoGeral', 'tempCaixaCondProcesso'],
    ipt: [true,true]
  },
  { 
    col: 2,
    sectionName: 'SISTEMA LAVAGEM GASES',
    props: ['relAguaVapor', 'tempAguaEntLavadorGases', 'tempAguaSaidaLavadorGases', 'flowFuligemCaldeira', 'umidFuligemCaldeira', 'flowAguaLavadorGases', 'flowAguaEvapLavadorGases', 'flowAguaFuligem', 'consAguaResRepSistFuligem'],
    ipt: [true,true,true,true,true,false,false,false,false]
  },
]

const bhEquips = [
  { 
    col: 1,
    sectionName: 'TORRE MOSTO / DORNAS / DESTILARIA',
    props: ['tempAguaFriaTorreFerm', 'tempAguaSaiResfMosto', 'tempAguaQuenteTorreFerm', 'tempAguaQuenteTorreDest', 'tempAguaQuenteTotalTorreDest', 'perdasArrasteTorreFerm',
    'perdasPurgasTorreFerm', 'flowAguaResfMosto', 'flowAguaTorreFerm', 'flowAguaTotalTorreDest', 'flowPerdaTotalArrasteTorreDest', 'flowPerdaTotalEvapTorreDest', 'gerAguaResPurgasTorreDest', 'consAguaBrutaRepTorreDest'
  ],
    ipt: [true,true,true,true,false,true,true,false,false,false,false,false,false,false]
  },
  { 
    col: 1,
    sectionName: 'TORRE VINHAÇA',
    props: ['tempVinhacaReg', 'tempVinhacaFriaTorreVinhaca', 'flowVinhaca', 'perdasArrasteTorreVinhaca', 'flowPerdaArrasteTorreVinhaca', 'flowPerdaEvapTorreVinhaca', 'flowVinhacaFria'],
    ipt: [false,true,false,true,false,false,false]
  },
  { 
    col: 2,
    sectionName: 'TORRE FÁBRICA',
    props: ['tempAguaFriaTorreFab', 'tempAguaQuenteTorreFab', 'perdasArrasteTorreFab', 'perdasPurgasTorreFab', 'flowAguaEvapTorreFab', 'flowAguaMassaATorreFab', 'flowAguaMassaBTorreFab',
    'flowAguaTorreFab', 'flowPerdaArrasteTorreFab', 'gerAguaResPurgasTorreFab', 'consAguaBrutaRepTorreFab', 
  ],
    ipt: [true,true,true,true,false,false,false,false,false,false,false]
  },
  { 
    col: 2,
    sectionName: 'TORRE MANCAIS',
    props: ['tempAguaFriaTorreMancais', 'tempAguaQuenteTorreMancais', 'perdasArrasteTorreMancais', 'perdasPurgasTorreMancais', 'flowAguaTorreMancais', 'flowPerdaEvapTorreMancais', 'flowPerdaArrasteTorreMancais', 
    'gerAguaResPurgasTorreMancais', 'consAguaBrutaRepTorreMancais'],
    ipt: [true,true,true,true,true,false,false,false,false]
  },
]

