import React, {useContext, useState } from 'react';
import GlobalStateContext from '../../components/GlobalStateContext';
import { IoAlertCircleSharp } from "react-icons/io5";
import { LuFileCheck2, LuFileX2 } from "react-icons/lu";
import Modal from '../../components/BarraSuperior/Modal';
import AnalyticsHooks from '../../Hooks/analyticsHooks';

export default function BtCalcularBME() {
    const [isLoading, setIsLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [modalText, setModalText] = useState('Calculando...');
    const { isAlertOn, setAlert, setData, data, area, user, site } = useContext(GlobalStateContext);
    const {newAction} = AnalyticsHooks()

    const hanldeAlert = (value) => {
        setAlert(value)
      }
   
  const fetchData = async () => {
    setIsLoading(true)
    setModalText('Calculando...')
    let progressValue = 0;
    const interval = setInterval(() => {
      progressValue += 1;
      if (progressValue <= 100) {
        setProgress(progressValue)
      } else {
        clearInterval(interval)
        setModalText('Atualizando...')
      }
    }, 10);

    try {
      const response = await fetch(''+global.endPoint+'/'+user?.uid+'/calcAll');
      if (!response.ok) {
        throw new Error('Erro ao buscar dados da API');
      }
      const jsonData = await response.json()
      setIsLoading(false)
      setProgress(0)
      hanldeAlert(false)
      setData(jsonData)
    } catch (error) {
      console.error('Erro ao buscar dados da API:', error);
      setIsLoading(false)
      setProgress(0)
    }
  }

  const fetchData2 = async () => {
    newAction('calcularBME', site, user.email)
    setIsLoading(true)
    setModalText('Calculando...')
    let progressValue = 0;
    const interval = setInterval(() => {
      progressValue += 1;
      if (progressValue <= 100) {
        setProgress(progressValue)
      } else {
        clearInterval(interval)
        setModalText('Atualizando...')
      }
    }, 0.5);

    try {
      const response = await fetch(''+global.endPoint+'/'+site+'/'+user?.uid+'/calcAllLoc', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({data: data})
      });
      if (!response.ok) {
        throw new Error('Erro ao buscar dados da API');
      }
      const jsonData = await response.json()
      setIsLoading(false)
      setProgress(0)
      hanldeAlert(false)
      console.log('Data', jsonData)
      setData(jsonData)
      // console.log('Data Updated', jsonData)
    } catch (error) {
      console.error('Erro ao buscar dados da API:', error);
      setIsLoading(false)
      setProgress(0)
    }
  }

  const calcBME = async () => {
     await fetchData2()
  }

  return (
   
    <div style={{display: 'flex', flexDirection: 'row', marginTop: '0.2vh'}}>
        <button className='psButtom' onClick={calcBME}>
        <div className='psMenuButtom' >
        {isAlertOn ? <div className='badge'><IoAlertCircleSharp/></div>:<></>}
        {!isAlertOn && <LuFileCheck2  className='psMenuIcon' style={{scale: '0.95'}}/>}
        {isAlertOn && <LuFileX2  className='psMenuIcon' style={{scale: '0.95'}}/>}
        <div className='psMenuText'>CALCULAR</div></div>
        </button>
        <Modal show={isLoading} progress={progress} texto={modalText}/>
    </div>
  )
}