import React, { useEffect, useContext } from 'react'
import MenuLateral from './MenuLateral/MenuLateral'
import BarraSuperior from './BarraSuperior/BarraSuperior'
import '../App.css'
import GlobalStateContext from '../components/GlobalStateContext'


import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Fluxograma from './Conteudo/Fluxograma'
import Limites from './Conteudo/Limites/Limites'
import Alertas from './Conteudo/Alertas/Alertas';
import Listas from './Conteudo/Listas'

import { getAlertList } from '../Funcoes/GetAletrsList';
import ExtracaoSeg from './ui/ExtracaoSeg'
import PlanoSafra from '../PLANO SAFRA/planoSafra'
import CompRevisoes from '../PLANO SAFRA/comparativo/compRevisoes'
import { UseColors } from './ui/colors'
import TenYearPlan from '../PLANO SAFRA/10yearPlan/tenYearPlan'
import NewUser from './NewUser'
import MenuBarraSuperior from '../BME/menuResProd/MenuBME'
import ModalScreenMessage from './ui/ModalScreenMessage'
import AnaliseProducao from '../BME/Unidades/Geral/getAnaliseProducao'
import AnaliseCenarios from '../ANALISES/Analise Cenarios/AnaliseCenarios'
import FabFlowsTable from '../Resources/FabFlowsTable'
import LogoPMS from './ui/PmsLogo'
import { getVersion } from '../BME/Unidades/Geral/getVersion'
import { removeAllImages } from '../Resources/imgsIndex'


function Main() {
  const {area, setData, data, setlistAlerts, user, menu, site} = useContext(GlobalStateContext)

  const COLORS = UseColors()

  useEffect(() => {
    async function removeImages(){
      await removeAllImages()
    }
    document.documentElement.style.setProperty('--primary-color', COLORS.primaryColor);
    let version  = `${site}-${getVersion(site)}`
    let versionStoraged = localStorage.getItem(`${site}-version`)
    if (version !== versionStoraged){
      console.log('Version Not Updated', version !== versionStoraged, version)
      localStorage.setItem(`${site}-version`, version)
      removeImages()
    }else{
      console.log('Version Updated', version !== versionStoraged, version)
    }
  });

  useEffect(() => {
    if (data) {
      setlistAlerts(getAlertList(data));
    }
  }, [data]);

function resumirJSON(obj, siteId) {
  // Itera sobre cada chave do objeto principal
  const resultado = {};
  for (const key in obj) {
    // Remove as propriedades indesejadas e adiciona o siteId
    const { id, uid, criadoEm, atualizadoEm, ...rest } = obj[key];
    resultado[key] = { ...rest, siteId }; // Adiciona siteId ao objeto resumido
  }
  return resultado;
}

  const noSeg = ["resumoProd", "alertas", "limites", "toPrint", "analiseProducao"]

  return (
    <div className='main'>
     <BrowserRouter>
        <MenuLateral/>
        <div className='rightSide'>
            <BarraSuperior/>
            {area === 'resumoProd' && data && <MenuBarraSuperior/>}
            {menu === 'bme' && !noSeg.includes(area) && <ExtracaoSeg/>}
            {!data && <ModalScreenMessage texto={'Carregando Dados...'}/>}
              <Routes>
                <Route path='/resumoProd' element={<Listas/>}/>
                <Route path='/' element={<Listas/>}/>
                <Route path='/alertas' element={<Alertas/>}/>
                <Route path='/limites' element={<Limites/>}/>
                <Route path='/planoSafra' element={<PlanoSafra/>}/>
                <Route path='/compRevisoes' element={<CompRevisoes/>}/>
                <Route path='/plano10Anos' element={<TenYearPlan/>}/>
                <Route path={'/extracao/flxAcionamentos'} element={<Fluxograma/>}/>
                <Route path={'/fabrica/fluxos'} element={<FabFlowsTable/>}/>
                <Route path={'/'+area+'/fluxograma'} element={<Fluxograma/>}/>
                <Route path={'/'+area+'/premissas'} element={<Listas/>}/>
                <Route path={'/'+area+'/fluxos'} element={<Listas/>}/>
                <Route path={'/'+area+'/equipamentos'} element={<Listas/>}/>
                <Route path={'/'+area+'/capacidades'} element={<Listas/>}/>
                <Route path={'/precos'} element={<Listas/>}/>
                <Route path={'/extracao/aquecedores'} element={<Listas/>}/>
                <Route path={'/extracao/acionM1'} element={<Listas/>}/>
                <Route path={'/extracao/acionM2'} element={<Listas/>}/>
                <Route path={'/tratAcucar/aquecedores'} element={<Listas/>}/>
                <Route path={'/tratAcucar/regeneradores'} element={<Listas/>}/>
                <Route path={'/tratEtanol/aquecedores'} element={<Listas/>}/>
                <Route path={'/vapor/turboger'} element={<Listas/>}/>
                <Route path={'/vapor/caldeiras'} element={<Listas/>}/>
                <Route path={'/analiseProducao'} element={<AnaliseProducao/>}/>
                <Route path={'/analiseCenarios'} element={<AnaliseCenarios/>}/>
                <Route path={'/newUser'} element={<NewUser/>}/>
              </Routes>
        </div>
        </BrowserRouter>
    </div>
  )
}

export default Main