import React, { useState, useContext } from 'react'
import '../../css/InputLine.css'
import GlobalStateContext from '../GlobalStateContext'
import { getAlertList } from '../../Funcoes/GetAletrsList'
import { iptValue, stdValue } from '../../Resources/setNumber'

function InputLineMax({cod, desc}) {
  
  const [prevV, setPrevV] = useState('')
  const { data, setlistAlerts, user, screen, setData, site } = useContext(GlobalStateContext);
  const [v, setV] = useState(stdValue(data[cod]?.limiteMax) ?? '')

  const handleIpt = (e) => {
    setV(e.target.value)
  }
 
  const updateValue = async (e) => {
    let nv = e.target.value.replace(/\./g, '')
    nv = nv.replace(/\,/g, '.')
    if (nv === '' || !contemNumero(nv)){
      setV(prevV)
    }else{
      if (nv !== prevV){
        setV(stdValue(nv))
  
          setData(prevData => ({
            ...prevData,
            [cod]: {
              ...prevData[cod],
              limiteMax: parseFloat(nv),
            },
          }));
          setlistAlerts(getAlertList(data))
      }
    }
    
  }

  const handlePrevV = (e) => {
    setPrevV(e.target.value)
  }

  return (
    <div className={`inputLineCont`}>
            <div className='line'>
                <div className='desc'>{desc}</div>
                    <input className='ipt' value={v} id={`${screen}-${cod}`} onChange={handleIpt} 
                    onBlur={updateValue} onFocus={handlePrevV} type="text" />
                <div className='unit'>{data[cod]?.unidade}</div>
            </div>
    </div>
  )
}

export default InputLineMax

function contemNumero(string) {
  return !isNaN(parseFloat(string)) && isFinite(string);
}