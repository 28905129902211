import React, {useContext } from 'react';
import { ReactComponent as EditIcon } from '../../assets/icons/doc.badge.gearshape.svg';
import { LuFileEdit } from "react-icons/lu";
import { ACContext } from './AnaliseCenariosContext';

export default function BtEditCenario() {
    const {isEditing, setIsEditing} = useContext(ACContext)

  const handleMenuChanged = () => {
    setIsEditing(!isEditing)
    };

  return (

    <div style={{display: 'flex', flexDirection: 'row', marginTop: '0.35vh'}}>
          <button className='psButtom' onClick={handleMenuChanged}>
          <div className='psMenuButtom' >

          <LuFileEdit   className='psMenuIcon'/>
          <div className='psMenuText'>EDITAR</div></div>
          </button>
        </div>
  )
}