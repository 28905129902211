import React, { useState, useContext, useEffect } from 'react'
import PSContext from '../opoio/PSContext';
import MenuBtDivider from '../../components/ui/menuBtDivider';
import BtExportPdfTenYear from './btExpPDFTenYear';
import BtChartsTenYearPlan from './btChartsTenYearPlan';

    function Menu10YearPlan({seqSafras, listRevs, stdList}) {
    const {allPsData, firstCrop, setFirstCrop} = useContext(PSContext)

    const listaSafras = ['', '23/24', '24/25', '25/26', '26/27', '27/28', '28/29', '29/30', '30/31', '31/32', '32/33', '33/34']

    useEffect(() => {
       console.log(`first Crop: `, firstCrop)
      }, []);
      

  const handleChangeFirsCrop = (event) => {
    const newValue = event.target.value;
    setFirstCrop(newValue);
  };

    if (allPsData){
        return (
            <div className='psMainMenu' style={{marginLeft: '3vh'}}>
                <div style={{display: 'flex', alignItems: 'center', marginRight: '1.5vh'}}>  
                    <label htmlFor="comboBox" style={{fontSize: '1.2vh', textAlign: 'left', color: '#5c5c5c', fontWeight: 'bold', marginRight: '1vh'}}>ANO INÍCIO:</label>
                    <select id="comboBox" value={firstCrop} onChange={handleChangeFirsCrop} style={{color: 'black', width: '12vh', marginRight: '1vh', height: '3.5vh'}}>
                        {listaSafras.map((item) => {
                            return (
                                <option value={item} key={item}>{item}</option>
                            )
                        })}
                    </select> 
                </div>
                <MenuBtDivider/>
                <BtExportPdfTenYear seqSafras={seqSafras} listRevs={listRevs} stdList={stdList}/>
                <MenuBtDivider/>
                <BtChartsTenYearPlan/>
            </div>
        )
    }
}

export default Menu10YearPlan