import React, {useContext } from 'react';
import { LuFilePlus2 } from "react-icons/lu";
import GlobalStateContext from '../../components/GlobalStateContext';
import { ACContext } from './AnaliseCenariosContext';
import AnalyticsHooks from '../../Hooks/analyticsHooks';

export default function BtAdicionarCenario() {
    const {data, bmeFileName, site, user} = useContext(GlobalStateContext)
    const {AcData, setAcData, isEditing} = useContext(ACContext)

    const {newAction} = AnalyticsHooks()

  const handleMenuChanged = () => {
    newAction('AdicionarCenario', site, user.email)
    let name = bmeFileName === '' ? `bme${AcData ? Object.keys(AcData)?.length + 1 : 1}` : bmeFileName
    let newBME = {}
    newBME[name] = data
    const newData = [...AcData, newBME];
    setAcData(newData);
    };

  return (

    <div style={{display: 'flex', flexDirection: 'row', marginTop: '0.35vh'}}>
          <button className='psButtom' onClick={handleMenuChanged} disabled={isEditing || AcData?.length >=9 ? true : false}>
          <div className='psMenuButtom' >

          <LuFilePlus2   className='psMenuIcon' />
          <div className='psMenuText'>ADICIONAR</div></div>
          </button>
        </div>
  )
}