

const psHooks = () => {

    const newPS = async (ps, userEmail) => {
      try{
          const response  = await fetch(`${global.endPoint}/newps?userEmail=${userEmail}`, {
              method: 'PUT',
              headers: {
              'Content-Type': 'application/json'
              },
              body: JSON.stringify(ps)
          })
          if (!response.ok) {
              throw new Error('Erro ao buscar dados da API');
          }
          const jsonData = await response.json();
          return jsonData
          }catch (error){
          console.error('Erro ao Importar Arquivo:', error);
          return null
          }
    }

    const resumeAllPS = async (site) => {
        try{
            const response = await fetch(`${global.endPoint}/getallps?siteId=${site}`, {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                },
              })
                if (!response.ok) {
                    throw new Error('Erro ao buscar dados da API')
                }
                let impData = await response.json();
                console.log(impData)
                let tempData = {}
                impData.forEach(item => {
                    if (!tempData[item.safra]) {
                        tempData[item.safra] = {}
                    }
                    if (!tempData[item.safra][item.revisao]) {
                        tempData[item.safra][item.revisao] = {}
                    }
                    tempData[item.safra][item.revisao]['atualizadoEm'] = item.atualizadoEm
                    tempData[item.safra][item.revisao]['criadoEm'] = item.criadoEm
                    tempData[item.safra][item.revisao]['userEmail'] = item.userEmail
                })
                // console.log('All PS', tempData)
                return tempData
            }catch (error){
            console.error('Erro ao Importar Arquivo:', error);
                return null
            }
    }

    const openPS = async (ssr) => {
      try{
          const response  = await fetch(`${global.endPoint}/getps?ssr=${ssr}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            }
          })
          if (!response.ok) {
              throw new Error('Erro ao buscar dados da API');
          }
          const jsonData = await response.json();
          return jsonData
          }catch (error){
          console.error('Erro ao Importar Arquivo:', error);
          return null
          }
    }

    const updatePS = async (ps, userEmail = '') => {
        // console.log('Update PS', ps)
      try{
          const response  = await fetch(`${global.endPoint}/updateps?userEmail=${userEmail}`, {
              method: 'PUT',
              headers: {
              'Content-Type': 'application/json'
              },
              body: JSON.stringify(ps)
          })
          if (!response.ok) {
              throw new Error('Erro ao buscar dados da API');
          }
          const jsonData = await response.json();
          return jsonData
          }catch (error){
          console.error('Erro ao Importar Arquivo:', error);
          return null
          }
    }

    const deletePS = async (ssr) => {
        try{
            const response  = await fetch(`${global.endPoint}/delps?ssr=${ssr}`, {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json'
              }
            })
            if (!response.ok) {
                throw new Error('Erro ao buscar dados da API');
            }else{
                console.log('Ps Deletado')
            }
            }catch (error){
            console.error('Erro ao Importar Arquivo:', error);
            }
      }
  
    return {newPS, resumeAllPS, openPS, updatePS,  deletePS};
  };

  export default psHooks;

