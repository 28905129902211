import React, { useContext, useEffect } from 'react';
import MenuAnaliseCenarios from './MenuAnaliseCenarios';
import { ACContext } from './AnaliseCenariosContext';
import BodyAnaliseCenarios from './BodyAnaliseCenarios';
import EditableList from './TesteListEdit';

const AnaliseCenarios = () => {
    const {AcData} = useContext(ACContext)

  return (
    <div>
        <MenuAnaliseCenarios/>
        <BodyAnaliseCenarios/>
    </div>
  )
}

export default AnaliseCenarios