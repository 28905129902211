import React, { PureComponent, useEffect, useState, useContext } from 'react';
import {  XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, BarChart, Bar, LabelList } from 'recharts';
import { stdValue } from '../../Resources/setNumber';
import PSContext from '../opoio/PSContext';
import { UseColors } from '../../components/ui/colors';

  class CustomizedAxisTick extends PureComponent {
    render() {
      const { x, y, stroke, payload } = this.props;
  
      return (
        <g transform={`translate(${x},${y})`}>
          <text x={0} y={0} dy='1vh' textAnchor="middle" fill="gray" transform="rotate(0)" >
            {payload.value}
          </text>
        </g>
      );
    }
  }


export default function PsBarChart({cod, h = '36vh', bgColor = 'white'}) {
    const {psData1, psData2} = useContext(PSContext)
    const [chartData, setChartData] = useState(null)
    const [varDesc, setVarDesc] = useState('')
    const [varDesc2, setVarDesc2] = useState('')
    const [chartTitle, setChartTitle] = useState('')
    const [maxValue, setMaxValue] = useState(0.0)

    const COLORS = UseColors()

    const CustomLabel = (props) => {
        const { x, y, value } = props;
        return (
          <text
            x={value === 0 ? x : value < 0 ? x : x+5} // x+5+65*(window.innerHeight/691)*(-value/(Math.ceil(maxValue)))
            y={value < 0 ? y+11 : y+5} // Ajusta a posição vertica do rótulo para fora da barra
            textAnchor="start"
            fill="#666"
            
            fontSize='1.2vh'
            fontWeight='bold'
            transform={`rotate(-90 ${x}, ${y})`} // Aplica rotação de 90 graus
          >
            {stdValue(value)}
          </text>
        );
      };

function getItem(data, cod){
    let temp = []
    Object.keys(data).map(sec => {
        data[sec].map(item => {
            if (item.codigo === cod){
                temp.push(item)
            }
        })
    })
    return temp[0]
}

function getMax(data){
  if (data){
    const maiorValorDinamico = Math.max(...Object.keys(data)
        .filter(key => ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'].includes(key))
        .map(key => data[key]))
    return maiorValorDinamico
  }else{
    return null
  }
    
}

useEffect(() => {

    async function fetchData() {
      if (psData1 && psData2){
          // console.log('hhhh: '+ window.innerHeight)
        let months = ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez']
        let tempdata = getItem(psData1, cod)//psData1?.filter(item => item.codigo === cod)[0]
        let tempdata2 = getItem(psData2, cod)
        if (tempdata && tempdata2){
          let maxTempData = getMax(tempdata)
        let maxTempData2 = getMax(tempdata2)
        let max = Math.max(maxTempData, maxTempData2)
        setMaxValue(max > 99999.999 ? max/1000 : max)
        let tempData3 = []
        setVarDesc(`${tempdata.safra}_${tempdata.revisao}`)
        setVarDesc2(`${tempdata2.safra}_${tempdata2.revisao}`)
        setChartTitle(`${tempdata.descricao} [${max > 99999.999 ? 'k' : ''}${tempdata.unidade}]`)
        for (const month in months){
            let dblValue = max > 99999.999 ? tempdata[months[month]]/1000 : tempdata[months[month]]
            let dblValue2 = max > 99999.999 ? tempdata2[months[month]]/1000 : tempdata2[months[month]]
            let newItem = {name: months[month], valor: dblValue, valor2: dblValue2, valor3: (dblValue2 - dblValue)}
            tempData3.push(newItem)
        }
        setChartData(tempData3)
        }
      }
    }
    fetchData();
  }, [psData1, psData2]);

  const formatTooltipValue = (value, name, props) => {
    return [`${stdValue(value)}`, name]; 
  };

  const formatYAxis = (tickItem) => {
    return `${stdValue(tickItem)}`;
  };

  
    return (
        
        <div style={{height: `${h}`, width: '100%', borderRadius: '2%', backgroundColor: `${bgColor}`, margin: '1vh'}}>
            <div style={{marginTop: '0.8vh', color: '#666', fontWeight: 'bold', fontSize: '1.6vh'}}>{chartTitle}</div>
        <ResponsiveContainer width="90%" height="90%">
        <BarChart width={400} height={200} data={chartData} 
        margin={{ top: 34, right: 15, left: 20, bottom: 15 }} style={{fontSize:'1.5vh', padding: '1vh'}}>
        <CartesianGrid strokeDasharray="2 3" />
        <XAxis dataKey="name" axisLine={false} tickLine={false}  height={25} tick={<CustomizedAxisTick />} interval={0} />
        <YAxis axisLine={false} tickLine={false} width={25} tickFormatter={formatYAxis} />
        <Tooltip formatter={formatTooltipValue}/>
        
        <Legend />

        <Bar type="monotone" dataKey="valor" name={varDesc}  stroke={'white'} fill={COLORS.primaryColor} strokeWidth={2}
        animationDuration={250} barSize={5} radius={[2,2,0,0]}><LabelList dataKey="valor" content={<CustomLabel />}/></Bar>

        <Bar type="monotone" dataKey="valor2" name={varDesc2}  stroke={'white'} fill={COLORS.secondaryColor} strokeWidth={2}
        animationDuration={250} barSize={5} radius={[2,2,0,0]}><LabelList dataKey="valor2" content={<CustomLabel />}/></Bar>

        <Bar type="monotone" dataKey="valor3" name={'Desvio'}  stroke={'white'} fill={COLORS.terciaryColor} strokeWidth={2}
        animationDuration={250} barSize={5} radius={[2,2,0,0]}><LabelList dataKey="valor3" content={<CustomLabel />}/></Bar>   
      </BarChart>
     </ResponsiveContainer>
     </div>
     
    );
 
}