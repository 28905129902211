import React from 'react';
import '../../css/screenMessage.css';

const ModalScreenMessage = ({ texto }) => {
  return (
    <div className="screenMessage">
        <div className="screenMessage-content">
          <div className='screenMessage-text'>{texto}</div>
        </div>
      </div>
  );
};

export default ModalScreenMessage;