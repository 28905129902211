const dadosPCTS = [
    {"codigo": "PCPRE", "descricao": "Pol % Cana"},
    {"codigo": "FIBPRE", "descricao":"Fibra % Cana"},
    {"codigo": "ARPRE", "descricao":"Açúcar Redutor Calculado"},
    {"codigo": "ARTPREI", "descricao":"Ac. Redutor Totais (% Cana)"},
    {"codigo": "UMICANA", "descricao":"Umidade Calculada da Cana (%)"},
    {"codigo": "PZPRO", "descricao":"Pureza Própria (%)"},
    {"codigo": "PZFOR", "descricao":"Pureza Fornecedor (%)"},
    {"codigo": "PZMED", "descricao":"Pureza Média (%)"},
    {"codigo": "ATRPREP", "descricao":"ATR Próprio (kg/tc)"},
    {"codigo": "ATRPREF", "descricao":"ATR Fornecedor (kg/tc)"},
    {"codigo": "ATRPRE", "descricao":"ATR Geral (kg/tc)"},
    {"codigo": "ATRSORGO", "descricao":"ATR Sorgo (kg/tc)"},
    {"codigo": "MATMIP", "descricao":"kg de Terra / tc - Própria"},
    {"codigo": "MATMIF", "descricao":"kg de Terra / tc - Fornecedor"},
    {"codigo": "MATMIN", "descricao":"kg de Terra / tc - Média"},
    {"codigo": "IMPTGD", "descricao":"Impureza Total (%)"},
    {"codigo": "HOQP", "descricao":"Horas Queima Própria"},
    {"codigo": "HOQF", "descricao":"Horas Queima Fornecedor"},
    {"codigo": "HOQT", "descricao":"Horas Queima Total"},
    {"codigo": "HPQCMCD", "descricao":"Horas Queima Manual Crua"}, 
    {"codigo": "HPQCMAD", "descricao":"Horas Queima Manual Queimada"}, 
    {"codigo": "HPQCMED", "descricao":"Horas Queima Mec. Crua"},
    {"codigo": "HPQCMQD", "descricao":"Horas Queima Mec. Queimada"}, 
    {"codigo": "OCELSAC", "descricao":"Índice de Preparo (%)"},
    {"codigo": "DEPPMBP", "descricao":"Dextrana C. Própria (% brix)"},
    {"codigo": "DEPPMBF", "descricao":"Dextrana C. Fornec. (% brix)"},
    {"codigo": "DEPPMBM", "descricao":"Dextrana C. Total (% brix)"},
    {"codigo": "ALBPCTS", "descricao":"Álcool Cana Própria (% brix)"},
    {"codigo": "ALBFORN", "descricao":"Álcool Cana Fornecedor (% brix)"},
    {"codigo": "ALCANTO", "descricao":"Álcool Cana Total (% brix)"},
    {"codigo": "PHPROP", "descricao":"pH Cana Própria"},
    {"codigo": "PHFOR", "descricao":"pH Cana Fornecedor"},
    {"codigo": "PHCANA", "descricao":"pH Cana Total"},
    {"codigo": "AMIPBX", "descricao":"Amido C. Própria (% brix)"},
    {"codigo": "AMIFBX", "descricao":"Amido C. Fornec. (% brix)"},
    {"codigo": "AMIGER", "descricao":"Amido C. Total (% brix)"},
    {"codigo": "P2O5PCTS", "descricao":"P2O5 Caldo PCTS"},
    {"codigo": "BROCANA", "descricao":"% Entrenos Danificados"},
    {"codigo": "ENBROCD", "descricao":"Intensidade de Infestação"}
]

const dadosMoagem = [
    {"codigo": "CMOIMT", "descricao": "Moagem (t)"},
    {"codigo": "TCHMED", "descricao": "TCH Efetiva"},
    {"codigo": "HEFMM", "descricao": "Horas Efetivas"},
    {"codigo": "HPMMED", "descricao": "Horas Perdidas"},
    {"codigo": "APRMM", "descricao": "Tempo Aproveitamento (%h)"},
    {"codigo": "ARTBAGM", "descricao": "ART % Bagaço"},
    {"codigo": "POBAM", "descricao": "Pol % Bagaço"},
    {"codigo": "UMBAM", "descricao": "Umidade % Bagaço"},
    {"codigo": "EXPOLM", "descricao": "Extração % Pol"},
    {"codigo": "EXREDM", "descricao": "Extração Reduzida"},
    {"codigo": "POLCAM", "descricao": "Pol % Cana"},
    {"codigo": "FICAM", "descricao": "Fibra % Cana"},
    {"codigo": "BACM", "descricao": "Bagaço % Cana"},
    {"codigo": "PUUTM", "descricao": "Pureza Último Terno (%)"},
    {"codigo": "EMBVMM", "descricao": "Embebição % Cana"},
    {"codigo": "EMFIM", "descricao": "Embebição % Fibra"},
    {"codigo": "OCELLM", "descricao": "Índice de Preparo"},
    {"codigo": "DISPON", "descricao": "Disponibilidade (%h)"},
    {"codigo": "EFICIND", "descricao": "Aproveitamento Moagem - Indústria (%T)"},
    {"codigo": "EFICAGR", "descricao": "Aproveitamento Moagem - Agrícola (%T)"},
    {"codigo": "EFTOT", "descricao": "Aproveitamento Moagem - Total (%T)"},
    {"codigo": "CEEMT", "descricao": "Eletrodos (g/tc)"},
    {"codigo": "DESPAT", "descricao": "Descarga no Pátio"},
    {"codigo": "MOMED", "descricao": "Moagem Média"},
    {"codigo": "%HPAGMT", "descricao": "% Aproveitamento Agrícola  (%h)"},
    {"codigo": "APRAGIND", "descricao": "Aproveitamento Agricola + Indústria (%)"},
    {"codigo": "DISPMM", "descricao": "Disponibilidade - Moendas  (%h)"},
    {"codigo": "ARTCANMD", "descricao": "ART Canaleta Moenda (%)"}
]

const dadosFabAcucar = [
    {"codigo": "P2O5CMG", "descricao": "P2O5 C. Misto Geral (ppm)"},
    {"codigo": "PHCALFA", "descricao": "pH Caldo Caleado Fábrica"},
    {"codigo": "PHDECFA", "descricao": "pH Caldo Decantado"},
    {"codigo": "BXDEC", "descricao": "Brix Caldo Decantado (%)"},
    {"codigo": "PUDECFA", "descricao": "Pureza Caldo Decantado (%)"},
    {"codigo": "P2O5DECF", "descricao": "P2O5 Caldo Decantado"},
    {"codigo": "PHFILT", "descricao": "pH do Filtrado"},
    {"codigo": "BXCAFIF", "descricao": "Brix Filtrado"},
    {"codigo": "PHCASUL", "descricao": "pH Caldo Sulfitado"},
    {"codigo": "BXXA", "descricao": "Brix do Xarope (%)"},
    {"codigo": "PUXAR", "descricao": "Pureza do Xarope (%)"},
    {"codigo": "CORSIFL", "descricao": "Cor do Xarope"},
    {"codigo": "PHXAR", "descricao": "pH do Xarope"},
    {"codigo": "BXMAPRI", "descricao": "Brix Massa A (%)"},
    {"codigo": "PUMAPRI", "descricao": "Pureza Massa A (%)"},
    {"codigo": "BXMASEG", "descricao": "Brix Massa B (%)"},
    {"codigo": "PUMASEG", "descricao": "Pureza Massa B (%)"},
    {"codigo": "BXMAG", "descricao": "Brix Magma (%)"},
    {"codigo": "PUMAG", "descricao": "Pureza Magma (%)"},
    {"codigo": "BXMAGA", "descricao": "Brix Magma Afinado (%)"},
    {"codigo": "PUMAGA", "descricao": "Pureza Magma Afinado (%)"},
    {"codigo": "CORMAGAF", "descricao": "Cor Magma Afinado (%)"},
    {"codigo": "BXMERI", "descricao": "Brix Mel Rico (%)"},
    {"codigo": "PUMERI", "descricao": "Pureza Mel Rico (%)"},
    {"codigo": "BXMEPO", "descricao": "Brix Mel Pobre (%)"},
    {"codigo": "PUMEPO", "descricao": "Pureza Mel Pobre (%)"},
    {"codigo": "BXMELD", "descricao": "Brix do Mel B (%)"},
    {"codigo": "ARTMELD", "descricao": "ART do Mel B (%)"},
    {"codigo": "BXMEFI", "descricao": "Brix Mel Final (%)"},
    {"codigo": "PUMEFI", "descricao": "Pureza Mel Final (%)"},
    {"codigo": "ACBXEST", "descricao": "Acidez Mel (% brix) - Estoque"},
    {"codigo": "ACIBRIX", "descricao": "Acidez Mel (% brix) - Produção"},
    {"codigo": "BXMECO", "descricao": "Brix do Mel Comprado (%)"},
    {"codigo": "ARTMELC", "descricao": "ART do Mel Comprado (%)"},
    {"codigo": "BXMELV", "descricao": "Brix do Mel Vendido (%)"},
    {"codigo": "ARTMELV", "descricao": "ART do Mel Vendido (%)"},
    {"codigo": "ARTMELTR", "descricao": "ART do Mel Transferido (%)"},
    {"codigo": "KGTORTC", "descricao": "kg Torta / TC"},
    {"codigo": "ARTTOG", "descricao": "ART da Torta"},
    {"codigo": "UMTOG", "descricao": "Umidade da Torta"},
    {"codigo": "POLACUB", "descricao": "Pol Açúcar Branco (%)"},
    {"codigo": "CORAB", "descricao": "Cor Açúcar Branco (UI)"},
    {"codigo": "UMACUB", "descricao": "Umidade Açúcar Branco (%)"},
    {"codigo": "PONPRET", "descricao": "Pontos Pretos Açúcar Branco"},
    {"codigo": "RINSB", "descricao": "Resíduo Insolúvel Açúcar Branco"},
    {"codigo": "SULFITO", "descricao": "Sulfito Açúcar Branco"},
    {"codigo": "PHREFAB", "descricao": "pH Refrigeração Fábrica"}, 
    {"codigo": "PHLCAN", "descricao": "pH Lavagem Cana"},
    {"codigo": "SOLODOG", "descricao": "Sólidos Lodo Filtros"},
    {"codigo": "ARTCANTC", "descricao": "ART Canaleta Trat. Caldo (%)"},
    {"codigo": "RECFAB2", "descricao": "Recuperação Fábrica - Xarope"},
    {"codigo": "ARTMELGP", "descricao": "ART Mel Granulado"},
    {"codigo": "DIFPRAC", "descricao": "Diferença Processo Açúcar (sc)"}
]

const dadosDestilaria = [
    {"codigo": "PHDECDE", "descricao": "pH Decantado Destilaria"},
    {"codigo": "BXDEDES", "descricao": "Brix Decantado (%)"},
    {"codigo": "PUDDES", "descricao": "Pureza Decantado (%)"},
    {"codigo": "P2O5DEC", "descricao": "P2O5 Caldo Decantado"},
    {"codigo": "BXMOSG", "descricao": "Brix Mosto (%)"},
    {"codigo": "ARTMOSG", "descricao": "ART do Mosto"},
    {"codigo": "ARTMOST1", "descricao": "ART do Mosto - IC"},
    {"codigo": "PUMOM", "descricao": "Pureza ART do Mosto (%)"},
    {"codigo": "PHMOF1", "descricao": "pH do Mosto"},
    {"codigo": "ACIMOM", "descricao": "Acidez do Mosto (mg/l)"},
    {"codigo": "ACBXMF", "descricao": "Acidez / brix Mosto"},
    {"codigo": "SOLMF1", "descricao": "% Sólidos Mosto"},
    {"codigo": "TEMOA", "descricao": "Temperatura do Mosto"},
    {"codigo": "P2O5MF1", "descricao": "P2O5 Mosto (ppm)"},
    {"codigo": "ALBXMF", "descricao": "% Álcool Mosto Amostrador"},
    {"codigo": "NIAMOST", "descricao": "Nitrogênio Amoniacal Mosto"},
    {"codigo": "LACMOS", "descricao": "Ácido Lático Mosto"},
    {"codigo": "PALCFEG", "descricao": "Grau Alc. Pé de Cuba"},
    {"codigo": "PHLEVM", "descricao": "pH do Pé de Cuba"},
    {"codigo": "ACMLEV", "descricao": "Acidez do Fermento (mg/l)"},
    {"codigo": "PHVIM", "descricao": "pH do Vinho"},
    {"codigo": "ALVINME", "descricao": "Grau Alc. Vinho Levurado"},
    {"codigo": "ACIVIM", "descricao": "Acidez Fixa do Vinho (mg/l)"},
    {"codigo": "INFVING", "descricao": "Contaminação no Vinho (x107)"},
    {"codigo": "VID4F1", "descricao": "Viabilidade Cel.Vinho (%)"},
    {"codigo": "BROVIG", "descricao": "Brotamento no Vinho (%)"},
    {"codigo": "ARVIM", "descricao": "ART do Vinho (%)"},
    {"codigo": "P2O5VF1", "descricao": "P2O5 Vinho (ppm)"},
    {"codigo": "KARTVIN", "descricao": "% ART Totais Vinho"},
    {"codigo": "ACILACVO", "descricao": "Ácido Lático Vinho Volante"},
    {"codigo": "PLEVVIN", "descricao": "% Fermento Vinho Levurado"},
    {"codigo": "VTURB", "descricao": "% Fermento Vinho Delevurado"},
    {"codigo": "LEVCENT", "descricao": "% Conc. Fermento Centrífugas"},
    {"codigo": "VINCENT", "descricao": "% Conc. Levedo Vinho Centrífugas"},
    {"codigo": "VINHADO", "descricao": "Perda Vinhaça % Alc. Prod."},
    {"codigo": "ALCVIT", "descricao": "Perdas na Vinhaça (%)"},
    {"codigo": "ALFLT", "descricao": "Perdas na Flegmaça (%)"},
    {"codigo": "INPMM", "descricao": "Grau Alc. Anidro (INPM)"},
    {"codigo": "INPMMH", "descricao": "Grau Alc. Hidratado (INPM)"},
    {"codigo": "INPMMN1", "descricao": "Grau Alc. Neutro (INPM)"},
    {"codigo": "INPMMF", "descricao": "Grau Alc. Industrial (INPM)"},
    {"codigo": "TEMTOFE", "descricao": "Tempo Total de Fermentação (h)"},
    {"codigo": "TEMMADO", "descricao": "Temperatura da Dorna (ºC)"},
    {"codigo": "TEMESPH", "descricao": "Tempo de Espera"},
    {"codigo": "TEMTRATH", "descricao": "Tempo Tratamento Ferm. (h)"},
    {"codigo": "RECTCO2", "descricao": "Recuperação Torre CO2 (%)"},
    {"codigo": "GACLEV", "descricao": "Gramas de ácido/Levedo tratado (g/L)"},
    {"codigo": "VFEVOVI", "descricao": "Vol. Fermento / Vol. Vinho"},
    {"codigo": "PLEVCE", "descricao": "Perda Levedo Centríf. / Levedo Dorna"},
    {"codigo": "PLEVVOL", "descricao": "Perda Levedo Volante / Levedo Dorna"},
    {"codigo": "ARTCANDE", "descricao": "ART Canaleta Destilaria (%)"},
    {"codigo": "MOSTLEV", "descricao": "Relação Mosto/MS Levedo"},
    {"codigo": "BALATFER", "descricao": "Delta Ácido Lático"}, 
    {"codigo": "DIFPRAL", "descricao": "Diferença Processo Álcool (L)"},
]

const dadosEnergia = [
    {"codigo": "ENERGER", "descricao": "Energia Gerada MWH"},
    {"codigo": "ENERCOM", "descricao": "Energia Adquirida MWH"},
    {"codigo": "ENERVEN", "descricao": "Energia Vendida MWH"},
    {"codigo": "ENEVENTC", "descricao": "Cons. Específico En. Vend. (KWH/t)"},
    {"codigo": "ENERCON", "descricao": "Consumo Total MWH"},
    {"codigo": "KWH/TC", "descricao": "Cons. Específico En. Cons. (KWH/t)"},
    {"codigo": "CONSMED", "descricao": "Consumo Medio KWH/h"},
    {"codigo": "GERPRO", "descricao": "Geração Própria"},
    {"codigo": "TOVAPOR", "descricao": "Vapor Gerado (t)"},
    {"codigo": "KGVAPTC", "descricao": "kg Vapor / tc"},
    {"codigo": "BAGGER", "descricao": "Bagaço Gerado (t)"},
    {"codigo": "BAGCONCA", "descricao": "Bagaço Consumido na Cald. (t)"},
    {"codigo": "SOBBAG", "descricao": "Sobra de bagaço (t)"},
    {"codigo": "REPAGCAL", "descricao": "Reposição Água na Cald. (%)"}
]

const dadosEficART = [
    {"codigo": "RITART", "descricao": "RIT STAB / ART (%)"},
    {"codigo": "EFIDEST", "descricao": "Destilação (%)"},
    {"codigo": "EFIFER", "descricao": "Fermentação - Med. Mosto Vazão (%)"},
    {"codigo": "EFIFER4", "descricao": "Fermentação - Med. Mosto Vazão - IC (%)"},
    {"codigo": "RENSPR", "descricao": "Fermentação - Subprodutos (%)"},
    {"codigo": "EFIDES3", "descricao": "Geral da Destilaria (%)"},
    {"codigo": "EFIDESIC", "descricao": "Geral da Destilaria - IC (%)"},
    {"codigo": "%ARTPEB", "descricao": "Perdas Bagaço % ART Entrado"},
    {"codigo": "%ARTPET", "descricao": "Perdas Torta % ART Entrado"},
    {"codigo": "%ARTPDSP", "descricao": "Perdas Destilaria % ART Entrado - Subprodutos"},
    {"codigo": "%ARTPD3", "descricao": "% ART Perdido Indeterminado - Subprodutos"},
    {"codigo": "%ARTPLC", "descricao": "Perdas Lav. Cana % ART Entrado"},
    {"codigo": "%ARTPMJ", "descricao": "Perdas Multijato Fábrica % ART Entr."},
    {"codigo": "ARTPEF", "descricao": "Perdas Efluente Final % ART Entr."},
    {"codigo": "%ARTEFT", "descricao": "Perdas Efluente Fábrica % ART Entr."},
    {"codigo": "%ARTPEFD", "descricao": "Perdas Efluente Destilaria % ART Entr."}, 
    // {"codigo": "%ARTPETOT", "descricao": "Perdas Totais % ART"},
]

const dadosOutros = [
    {"codigo": "TCNNL", "descricao": "Ton. Cana Não Lavada"},
    {"codigo": "PCNNL", "descricao": "% Cana Não Lavada"},
    {"codigo": "PANALT", "descricao": "% Cana Analisada"},
    {"codigo": "LODO", "descricao": "Lodo (Barro) (kg)"},
    {"codigo": "FULIGEM", "descricao": "Fuligem (Cinza) (kg)"},
    {"codigo": "LODOTC", "descricao": "Lodo (Barro) / tc (kg/tc)"},
    {"codigo": "FULIGTC", "descricao": "Fuligem (Cinza) / tc (kg/tc)"},
    {"codigo": "MELCOM", "descricao": "Mel Comprado (kg)"},
    {"codigo": "MELTRAN", "descricao": "Mel Transferido (kg)"},
    {"codigo": "FUSELTQ", "descricao": "Volume Fúsel Tanque (l)"},
    {"codigo": "FUSEL", "descricao": "Óleo Fúsel Vendido (kg)"}
]

const dadosGerAcucar = [
    {"codigo": "ACVHP", "descricao": "VHP"},
    {"codigo": "VVHPP", "descricao": "VHP Plus"},
    {"codigo": "ACVVHP", "descricao": "VVHP"},
    {"codigo": "ACSUP", "descricao": "MP Refino 185"},
    {"codigo": "ACSUP1", "descricao": "MP Refino 300"},
    {"codigo": "ACSUP2", "descricao": "MP Refino 450"},
    {"codigo": "ACTOT", "descricao": "Açúcar Total"},
    {"codigo": "ACPRODIN", "descricao": "Açúcar Ind. Mel"},
    {"codigo": "KGACATR", "descricao": "kg Açúcar / kg ATR"},
]

const dadosGerEtanol = [
    {"codigo": "ALCHIDT", "descricao": "Hidratado"},
    {"codigo": "HJAPAO", "descricao": "Ind. REN"},
    {"codigo": "HCOREIA", "descricao": "Ind. Grade B"},
    {"codigo": "HIDNEUT", "descricao": "Hid. Neutro"},
    {"codigo": "PENMOL", "descricao": "Anidro Peneira"},
    {"codigo": "EXTNEUT", "descricao": "Extra Neutro"},
    {"codigo": "ANIEURO", "descricao": "An. Europa"},
    {"codigo": "ALCTOT", "descricao": "Etanol Total"},
    {"codigo": "LATR", "descricao": "L.Etanol / ATR"},
]

const dadosGerRend = [
    {"codigo": "RELATRS", "descricao": "Relação ATRs"},
    {"codigo": "UNICOP", "descricao": "Unicop"},
    {"codigo": "RITSTAB", "descricao": "RIT-STAB"},
    {"codigo": "RITART", "descricao": "RIT-STAB / ART"},
    {"codigo": "EFCONV", "descricao": "Efic. de Conv. ART"},
    {"codigo": "ATRCONV", "descricao": "ATR Conv."},
    {"codigo": "ATRPRE", "descricao": "ATR Calc."},
    {"codigo": "BSMIXAC", "descricao": "Mix Açúcar"},
    {"codigo": "SCTC", "descricao": "Sc Açúcar/tc"},
    {"codigo": "SCATR", "descricao": "Sc Açúcar/ATR"},
]

module.exports = {
    dadosPCTS,
    dadosMoagem,
    dadosFabAcucar,
    dadosDestilaria,
    dadosEnergia,
    dadosOutros,
    dadosGerAcucar,
    dadosGerEtanol,
    dadosGerRend,
    dadosEficART

}