import React, {useContext, useEffect, useState } from 'react';
import PSContext from '../opoio/PSContext';
import { listaSafras } from '../listasPlanoSafra';
import { UseColors } from '../../components/ui/colors' 
import BtExportPdfCompPS from './btExpPDFCompPS';
import MenuBtDivider from '../../components/ui/menuBtDivider';
import BtChartsCompPS from './btChartsCompRevs';
import GlobalStateContext from '../../components/GlobalStateContext';
import psHooks from '../../Hooks/psHooks';
import { getStdPlanoSafra } from '../../BME/Unidades/Geral/getStdPlanoSafra';

export default function MenuCompRevs() {
  const {site} = useContext(GlobalStateContext)
  const {allPsData, setAllPsData, listSafras, setListSafras, listRevs1, setListRevs1,
    listRevs2, setListRevs2, selectedSafra1, setSelectedSafra1, selectedSafra2, setSelectedSafra2, selectedRev1, setSelectedRev1,
    selectedRev2, setSelectedRev2, psData1, setPsData1, psData2, setPsData2} = useContext(PSContext)
    const [showModal, setShowModal] = useState(false);
    const {openPS, resumeAllPS} = psHooks()

    const stdPlanoVars = getStdPlanoSafra(site)

let seqSecs = ['MATÉRIA PRIMA', 'UPTIME', 'MOAGEM', 'MIX', 'EFICIÊNCIAS', 'PERDAS', 'AÇÚCAR', 'ETANOL', 'ENERGIA', 'VAPOR', 'BIOMASSA', 'DIAS NÃO EFETIVOS']

const COLORS = UseColors()

useEffect( () => {
    // console.log('s1: '+selectedSafra1)
    async function fetchData() {
      try {
        // if (!allPsData) {
            // console.log(allPsData);
            // console.log('s2: '+selectedSafra1)
          await getAllPsData()
        
          // console.log('s4: '+selectedSafra1)
        // }
      } catch (error) {
        console.error("Erro ao buscar os dados:", error);
      }
    }
     fetchData();
    // console.log('s5: '+selectedSafra1)
  }, []);

   useEffect(() => {
    // console.log('s6: '+selectedSafra1)
    if (allPsData && selectedSafra1 ) {
        if (allPsData[selectedSafra1]){
            const revs = Object.keys(allPsData[selectedSafra1]) 
            const listaOrdenada = revs.sort((a, b) => {
            const numA = parseInt(a.split('_')[0], 10)
            const numB = parseInt(b.split('_')[0], 10)
            return numA - numB})

            setListRevs1(listaOrdenada)
            let prevRev = selectedRev1
        if (listaOrdenada.includes(prevRev)){
            setSelectedRev1(prevRev)
            updatePsData1()
        }else{
          if (listaOrdenada[0] !== ''){
            listaOrdenada.unshift('')
          }
            setSelectedRev1('')
        }
        
        }
    }
    console.log('updated selectSafra1')
  }, [selectedSafra1]);

  useEffect(() => {
    if (allPsData && selectedSafra2 !== null) {
        if (allPsData[selectedSafra2]){
            const revs = Object.keys(allPsData[selectedSafra2]) 
            const listaOrdenada = revs.sort((a, b) => {
                const numA = parseInt(a.split('_')[0], 10)
                const numB = parseInt(b.split('_')[0], 10)
                return numA - numB})
    
        setListRevs2(listaOrdenada)

        let prevRev = selectedRev2
        if (listaOrdenada.includes(prevRev)){
            setSelectedRev2(prevRev)
            updatePsData2()
        }else{
          if (listaOrdenada[0] !== ''){
            listaOrdenada.unshift('')
          }
            setSelectedRev2('')
        }
        }

        
    }
  }, [selectedSafra2]);

  useEffect(() => {
    updatePsData1()
  }, [selectedRev1]);

  useEffect(() => {
    updatePsData2()
  }, [selectedRev2]);

  async function updatePsData1(){
    let ssr = `${site}-${selectedSafra1}-${selectedRev1}`
    let ps = await openPS(ssr)
    
    // setPsData1(ps)
   try{
    if (allPsData && selectedSafra1 && selectedRev1){
      const groupedItems1 = ps.reduce((acc, item) => {
          if (stdPlanoVars.filter(row => row.cod === item.codigo)[0]?.show !== false){
              if (!acc[item.secao]) {
                acc[item.secao] = [];
            }
              acc[item.secao].push(item);
          }
          return acc;
      }, {});
      const listaOrdenada1 = ordenarJsonPorKeys(groupedItems1, seqSecs)

      setPsData1(listaOrdenada1)
      // console.log('PSData 1', listaOrdenada1)
  }
   }catch{
    
   }
  }

  async function updatePsData2(){
    let ssr = `${site}-${selectedSafra2}-${selectedRev2}`
    let ps = await openPS(ssr)
    // setPsData2(ps)
    try{
      if (allPsData && selectedSafra2 && selectedRev2){
        const groupedItems2 = ps.reduce((acc, item) => {
            if (stdPlanoVars.filter(row => row.cod === item.codigo)[0]?.show !== false){
                if (!acc[item.secao]) {
                  acc[item.secao] = [];
              }
                acc[item.secao].push(item);
            }
            return acc;
        }, {});
        const listaOrdenada2 = ordenarJsonPorKeys(groupedItems2, seqSecs)
        setPsData2(listaOrdenada2)
    }
    }catch{

    }
  }

  function ordenarJsonPorKeys(jsonObj, ordemPadrao) {
    const jsonOrdenado = {};
    ordemPadrao.forEach(key => {
        if (jsonObj.hasOwnProperty(key)) {
            jsonOrdenado[key] = jsonObj[key];
        }
    });
    return jsonOrdenado;
}

  const handleChangeSafra1 = (event) => {
    console.log('hange change selectedsafra1')
    const newValue = event.target.value;
    setSelectedSafra1(newValue);
  };

  const handleChangeSafra2= (event) => {
    const newValue = event.target.value;
    setSelectedSafra2(newValue);
  };

  const handleChangeRev1 = (event) => {
    const newValue = event.target.value;
    setSelectedRev1(newValue);
  };

  const handleChangeRev2 = (event) => {
    const newValue = event.target.value;
    setSelectedRev2(newValue);
  };

  async function getAllPsData() {
    let allPs =  await resumeAllPS(site)
    console.log('allPS', allPs)
    setAllPsData(allPs)

    // try {
    //   const response = await fetch(`${global.endPoint}/getallps?siteId=${site}`, {
    //     method: 'GET',
    //     headers: {
    //       'Content-Type': 'application/json',
    //     },
    //   });

    //   if (!response.ok) {
    //     throw new Error('Erro de requisição.');
    //   }

    //   let impData = await response.json();

    //   let tempListSafra = []
    //   const groupedData = impData.reduce((acc, item) => {
    //     const { safra, revisao, codigo } = item;
    //     if (!acc[safra]) {
    //       acc[safra] = {};
    //       tempListSafra.push(safra)
    //     }
    //     if (!acc[safra][revisao]) {
    //       acc[safra][revisao] = [];
    //     }
    //     acc[safra][revisao].push(item);

    //     return acc;
    //   }, {});
      const listaOrdenada = Object.keys(allPs).sort((a, b) => {
        const numA = parseInt(a.split('/')[0], 10);
        const numB = parseInt(b.split('/')[0], 10);
        return numA - numB;
    });
    console.log('eeeeeee: '+selectedSafra1)
    setListSafras(listaOrdenada)
    
    if (!selectedSafra1){
        console.log('eeeeeee: '+selectedSafra1)
        setSelectedSafra1(listaOrdenada[0])
    }
    if (!selectedSafra2){
        setSelectedSafra2(listaOrdenada[0])
    }
    // setAllPsData(groupedData);
    //   return groupedData;
    // } catch (error) {
    //   console.error('Erro na requisição:', error);
    // }
    // return null;
  }

  const handleCloseModal = () => {
    setShowModal(false);
  };

  if (allPsData){
    return (
      <div>
         <div className='psMainMenu' style={{marginLeft: '3vh'}}>
 
             <div style={{display: 'flex', direction:'row', textAlign: 'left', marginRight: '1.5vh', alignItems: 'center'}}>
         {listSafras && 
         <><label htmlFor="comboBox" style={{fontSize: '1.2vh', textAlign: 'left', color: '#5c5c5c', fontWeight: 'bold', marginRight: '1vh'}}>REVISÃO 1:</label>
         <select id="comboBox" value={selectedSafra1 || ""} onChange={handleChangeSafra1} style={{color: 'black', width: '12vh', marginRight: '1vh'}}>
           {listSafras.map((item) => {
               return (
                 <option value={item} key={item}>{item}</option>
             )
           })}
         </select></>}
         {listRevs1 && 
         <select id="comboBox2" value={selectedRev1 || ""} onChange={handleChangeRev1} style={{color: 'black', width: '15vh'}}>
         {listRevs1.map((item) => {
             return (
               <option value={item} key={item}>{item}</option>
           )
         })}
       </select>}
             </div>
         {listSafras && <MenuBtDivider/>}
             
 
             <div style={{display: 'flex', direction:'row', textAlign: 'left', marginRight: '1.5vh', marginLeft: '1.5vh', alignItems: 'center'}}>
         {listSafras &&
         <><label htmlFor="comboBox3" style={{fontSize: '1.2vh', textAlign: 'left', color: '#5c5c5c', fontWeight: 'bold', marginRight: '1vh'}}>REVISÃO 2:</label>
         <select id="comboBox3" value={selectedSafra2 || ""} onChange={handleChangeSafra2} style={{color: 'black', width: '12vh', marginRight: '1vh'}}>
           {listSafras.map((item) => {
               return (
                 <option value={item} key={item}>{item}</option>
             )
           })}
         </select></>}
         {listRevs2 && 
         <select id="comboBox4" value={selectedRev2 || ""} onChange={handleChangeRev2} style={{color: 'black', width: '15vh'}}>
         {listRevs2.map((item) => {
             return (
               <option value={item} key={item}>{item}</option>
           )
         })}
       </select>}
       
        </div>
            {listSafras && <MenuBtDivider/>}
            <BtExportPdfCompPS disable={listaSafras ? false : true}/>
            <MenuBtDivider/>
            <BtChartsCompPS disable={listaSafras ? false : true}/>
         </div>
     </div>
   )
  }
  
}