const BoletinsHooks = () => {

    const novoBoletim = async (site, date, dados) => {
      try{
          let newItem = {siteId: site, data: date, dados: dados}
          const response  = await fetch(`${global.endPoint}/novoboletim`, {
              method: 'PUT',
              headers: {
              'Content-Type': 'application/json'
              },
              body: JSON.stringify({data: newItem})
          })
          if (!response.ok) {
              throw new Error('Erro ao buscar dados da API');
          }
          const jsonData = await response.json();
          return jsonData
          }catch (error){
          console.error('Erro ao Importar Arquivo:', error);
          return null
          }
    }

    const atualizaBoletim = async (site, date, dados) => {
        try{
            let newItem = {siteId: site, data: date, dados: dados}
            const response  = await fetch(`${global.endPoint}/atualizaboletim`, {
                method: 'PUT',
                headers: {
                'Content-Type': 'application/json'
                },
                body: JSON.stringify({data: newItem})
            })
            if (!response.ok) {
                throw new Error('Erro ao buscar dados da API');
            }
            const jsonData = await response.json();
            console.log('return novo Boletim', jsonData)
            }catch (error){
            console.error('Erro ao Importar Arquivo:', error);
            }
      }

      const buscarBoletim = async (site, date) => {
        try{
            let buscarItem = {siteId: site, data: date}
            const response  = await fetch(`${global.endPoint}/buscarboletim`, {
                method: 'PUT',
                headers: {
                'Content-Type': 'application/json'
                },
                body: JSON.stringify({data: buscarItem})
            })
            if (!response.ok) {
                throw new Error('Erro ao buscar dados da API');
            }
            const jsonData = await response.json();
            return jsonData.dados
            }catch (error){
            console.error('Erro ao Importar Arquivo:', error);
            }
      }
  
    return {novoBoletim, atualizaBoletim, buscarBoletim};
  };
  
  export default BoletinsHooks;