import React, { useState, useContext, useEffect } from 'react';
import GlobalStateContext from '../../../../components/GlobalStateContext';
import {varsAcucar, varsEtanol, varsMoagem, varsProd, varsTrat, varsRend, varsAll, varsPerdas} from './stdAnalyze'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { stdValue } from '../../../../Resources/setNumber'
import Modal from '../../../../components/BarraSuperior/Modal';
import PsHooks from '../../../../Hooks/psHooks';

const DayAnalyzer = () => {
    const {openPS} = PsHooks()
    const [dataBlt, setDataBlt] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const {data, setData, user, site} = useContext(GlobalStateContext)
    const [psData, setPsData] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [progress, setProgress] = useState(0)
    const [mes, setMes] = useState(null)
    const [modalText, setModalText] = useState('Exportando p/ BME...')

    useEffect(() => {
      if (dataBlt === null && global.dataBlt !== null){
        setDataBlt(global.dataBlt)
        setSelectedDate(global.selectedDate)
      }else{
        global.dataBlt = dataBlt
        global.selectedDate = selectedDate
      }
    }, [dataBlt]);

    useEffect(() => {
      console.log('Selected Date Changed: ', selectedDate)
      if (selectedDate){
        getPsData()
      }
      
    }, [selectedDate]);

    function setDate(){
        let date = selectedDate
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Os meses são zero-indexados em JavaScript
        const year = date.getFullYear();
        const formattedDate = `${day}.${month}.${year}`;
        return formattedDate
    }

    function getMonthAndYear(){
      let date = selectedDate
      const month = date.getMonth() + 1
      const year = date.getFullYear() % 100
      return {month, year}
  }

  async function getPsData(){
    const { month, year } = getMonthAndYear()
    let rev = getRev(month)
    let safra = `${year}/${year+1}`
    let ps = await openPS(safra, rev)
    
    if (ps){
      setMes(rev.slice(-3).toLowerCase())
      setPsData(ps)
    }
    // console.log('PsData', rev, safra, mes, ps)
  }

    async function getData(){
        let date = selectedDate
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Os meses são zero-indexados em JavaScript
        const year = date.getFullYear();

        const formattedDate = `${day}.${month}.${year}`;
        
        try {
            const response = await fetch(`${global.endPoint}/getBltDate?data=${formattedDate}`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json'
              }
            });
          
            if (!response.ok) {
              throw new Error('Erro de requisição.');
            }
            let impData = await response.json();
            if (Object.keys(impData).length === 0){
                setDataBlt(null)
                global.dataBlt = null
            }else{
            
                setDataBlt(impData)
                global.dataBlt = dataBlt
                global.selectedDate = selectedDate
            }
            
          } catch (error) {
            console.error('Erro na requisição:', error);
          }

    }

    async function exportToBME(){
      setModalText('Exportando p/ BME...')
      let progressValue = 0;
      const interval = setInterval(() => {
        progressValue += 1;
        if (progressValue <= 100) {
          setProgress(progressValue)
        } else {
          clearInterval(interval)
          
        }
      }, 20);

      setIsLoading(true)
      let tempData = await updateValuesToBME()
      await calcBME(tempData)
    }

    async function updateValuesToBME(){
      let tempData = data
      const promises = []
      for (const v of varsAll){
        if (v.codigo === "ALCTOT"){
          let PENMOL = dataBlt['PENMOL'].valor
          let ACNEUTOT = dataBlt['ACNEUTOT'].valor
          // let ACHIDTOT = dataBlt['ACHIDTOT'].valor
          let ALCTOT = dataBlt['ALCTOT'].valor

          let mixEtanolAnidro = PENMOL * 100 / ALCTOT
          let mixEtanolNeutro = ACNEUTOT * 100 / ALCTOT
          tempData['mixEtanolAnidro'].valor = mixEtanolAnidro
          tempData['mixEtanolNeutro'].valor = mixEtanolNeutro

        }
        
        if (v.codigo === '%ARTPEB'){
          let ARTPEB = dataBlt['%ARTPEB'].valor
          let efExtracao = 100 - ARTPEB
          tempData['efExtMoenda'].valor = efExtracao
        }
        

        if (v.codigo === 'EFIDEST'){
          let EFIDEST = dataBlt['EFIDEST'].valor
          let concEtanolVinhaca = data['concEtanolVinhaca'].valor
          let flowVinhaca = data['flowVinhaca'].valor
          let flowEtanolEntColA = data['flowEtanolEntColA'].valor
          let efDestilaria = data['efDestilaria'].valor

          let p0 = (1 - efDestilaria/100)*flowEtanolEntColA
          let p1 = (1 - EFIDEST/100)*flowEtanolEntColA
          let dC = (p1-p0)*100/flowVinhaca/0.79
          tempData['concEtanolVinhaca'].valor = concEtanolVinhaca+dC
        }
        
        if (v.ipt){
            let bltValue = dataBlt[v.codigo]?.valor
            if (v.codigo === "TCHMED"){
              let moagemDia = dataBlt['CMOIMT'].valor
              let horasEf = dataBlt['HEFMM'].valor
              bltValue = moagemDia / horasEf
            }
            if (v.codigo === "FIBBAG"){
              let bagCana = dataBlt['BACM'].valor
              let fibCana = dataBlt['FIBPRE'].valor
              bltValue = fibCana * 100 / bagCana
            }
            
          if (bltValue){
            tempData[v.bmeCod].valor = parseFloat(bltValue)
          }
        }
      }
      return tempData
    }

    const calcBME = async (tempData) => {
      setModalText('Recalculando BME...')
      let progressValue = 0;
      const interval = setInterval(() => {
        progressValue += 1;
        if (progressValue <= 100) {
          setProgress(progressValue)
        } else {
          clearInterval(interval)
          
        }
      }, 2);
      try {
        const response = await fetch(''+global.endPoint+'/'+site+'/'+user?.uid+'/calcAllLoc', {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({data: tempData})
        });
        if (!response.ok) {
          throw new Error('Erro ao buscar dados da API');
        }
        const jsonData = await response.json()
        setModalText('Atualizando...')
        setIsLoading(false)
        setProgress(0)
        setData(jsonData)
      } catch (error) {
        console.error('Erro ao buscar dados da API:', error);
        setIsLoading(false)
        setProgress(0)
      }
    }

    async function changeBmeValue(cod, newValue){
      setData((prevData) => ({
        ...prevData,
        [cod]: {
          ...prevData[cod],
          valor: parseFloat(newValue),
        },
      }))
    }

    function getPsValue(cod, mes){
      let item  = psData?.filter(item => item.codigo == cod)[0][mes]
      // console.log('GetPsVAlue: ', mes, cod, psData?.filter(item => item.codigo == cod)[0])
      return item ? item.toLocaleString() : ''
    }

    return (
        <div style={{ paddingLeft: '2vh', paddingRight: '2vh', textAlign: 'center', overflowY: 'scroll'}}>
          {/* <div style={{marginBottom: '1vh', fontSize: '2vh', fontWeight: 'bold'}}>Analisar Dia</div> */}
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
      <DatePicker
        selected={selectedDate}
        onChange={date => setSelectedDate(date)}
        dateFormat="dd/MM/yyyy"
        placeholderText="Selecione uma data"
      />
      <div style={{width: '0.5vh'}}>  </div>
      {selectedDate && <button className='green-button' onClick={getData}>OK </button>}
      {selectedDate &&
              <button  className='green-button' style={{textAlign: 'left', marginRight: '2.5vh', fontSize: '1.6vh', marginLeft: '2vh'}} onClick={exportToBME}>
                Exportar Boletim p/ BME</button>}
      
    </div>
          {dataBlt && 
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              {selectedDate &&
              <div style={{textAlign: 'left', marginLeft: '3.5vh', fontWeight: 'bold', fontSize: '1.6vh'}}>
              Data: {setDate()}
            </div>}
            
             
            </div>}
         
            <div style={{marginLeft: '3vh', marginRight: '3vh', marginTop: '0.5vh', marginBottom: '0vh', fontWeight: 'bold'}}>
                <div className='fullLine' style={{fontSize: '1vh'}}>
                    <div className='fullLineRow' style={{width: '15%'}}>CÓDIGO</div>
                    <div className='fullLineRow' style={{width: '25%'}}>DESCRIÇÃO</div>
                    <div className='fullLineRow' style={{width: '15%'}}>BOLETIM</div>
                    <div className='fullLineRow' style={{width: '15%'}}>BME</div>
                    <div className='fullLineRow' style={{width: '15%'}}>PLANO</div>
                    <div className='fullLineRow' style={{width: '15%', textAlign: 'right'}}>UNIDADE</div>
                    
                </div>
          </div>
         
        <div style={{overflow: 'auto', height: '80vh'}}>
        {dataBlt &&<div className='psSectionHead' style={{backgroundColor: '#F2F2F7', border: '0', marginLeft: '1vh'}}>MOAGEM</div>}
        <div style={{backgroundColor: 'white', borderRadius: '1vh', marginLeft: '2vh'}}>
          {data && dataBlt && varsMoagem.map((item) => {
            let filteredData = dataBlt[item.codigo]
                  return (
              <div className='fullLine' style={{marginLeft: '1vh', marginRight: '2vh', backgroundColor: 'white', borderBottom: '1px solid #f2f2f7', padding: '0.8vh'}}>
                  <div className='fullLineRowBol' style={{width: '15%'}}>{item.codigo}</div>
                  <div className='fullLineRowBol' style={{width: '25%'}}>{data[item.bmeCod]?.descricao}</div>
                  <div className='fullLineRowBol' style={{width: '15%'}}>{filteredData?.valor === 'undefined' ? null : stdValue(filteredData?.valor)}</div>
                  <div className='fullLineRowBol' style={{width: '15%', color: `${item.ipt ? 'blue' : 'black'}`}}>{stdValue(data[item.bmeCod]?.valor)}</div>
                  <div className='fullLineRowBol' style={{width: '15%'}}>{psData ? (item.psCod && getPsValue(item.psCod, mes)) : ''}</div>
                  <div className='fullLineRowBol' style={{width: '15%', textAlign: 'right'}}>{data[item.bmeCod]?.unidade}</div>
              </div>
          )})}
          </div>

        {dataBlt && <div className='psSectionHead' style={{backgroundColor: '#F2F2F7', border: '0', marginLeft: '1vh'}}>TRATAMENTO DO CALDO</div>}
        <div style={{backgroundColor: 'white', borderRadius: '1vh', marginLeft: '2vh'}}>
        {data && dataBlt && varsTrat.map((item) => {
            let filteredData = dataBlt[item.codigo]
                return (
            <div className='fullLine' style={{marginLeft: '1vh', marginRight: '2vh', backgroundColor: 'white', borderBottom: '1px solid #f2f2f7', padding: '0.8vh'}}>
                <div className='fullLineRowBol' style={{width: '15%'}}>{item.codigo}</div>
                <div className='fullLineRowBol' style={{width: '25%'}}>{data[item.bmeCod]?.descricao}</div>
                <div className='fullLineRowBol' style={{width: '15%'}}>{filteredData?.valor === 'undefined' ? null : stdValue(filteredData?.valor)}</div>
                <div className='fullLineRowBol' style={{width: '15%', color: `${item.ipt ? 'blue' : 'black'}`}}>{stdValue(data[item.bmeCod]?.valor)}</div>
                <div className='fullLineRowBol' style={{width: '15%'}}>{psData ? (item.psCod && getPsValue(item.psCod, mes)) : ''}</div>
                <div className='fullLineRowBol' style={{width: '15%', textAlign: 'right'}}>{data[item.bmeCod]?.unidade}</div>
            </div>
        )})}
        </div>

        {dataBlt && <div className='psSectionHead' style={{backgroundColor: '#F2F2F7', border: '0', marginLeft: '1vh'}}>FÁBRICA AÇÚCAR</div>}
        <div style={{backgroundColor: 'white', borderRadius: '1vh', marginLeft: '2vh'}}>
        {data && dataBlt && varsAcucar.map((item) => {
            let filteredData = dataBlt[item.codigo]
                return (
            <div className='fullLine' style={{marginLeft: '1vh', marginRight: '2vh', backgroundColor: 'white', borderBottom: '1px solid #f2f2f7', padding: '0.8vh'}}>
                <div className='fullLineRowBol' style={{width: '15%'}}>{item.codigo}</div>
                <div className='fullLineRowBol' style={{width: '25%'}}>{data[item.bmeCod]?.descricao}</div>
                <div className='fullLineRowBol' style={{width: '15%'}}>{filteredData?.valor === 'undefined' ? null : stdValue(filteredData?.valor)}</div>
                <div className='fullLineRowBol' style={{width: '15%', color: `${item.ipt ? 'blue' : 'black'}`}}>{stdValue(data[item.bmeCod]?.valor)}</div>
                <div className='fullLineRowBol' style={{width: '15%'}}>{psData ? (item.psCod && getPsValue(item.psCod, mes)) : ''}</div>
                <div className='fullLineRowBol' style={{width: '15%', textAlign: 'right'}}>{data[item.bmeCod]?.unidade}</div>
            </div>
  
        )})}
        </div>

        {dataBlt && <div className='psSectionHead' style={{backgroundColor: '#F2F2F7', border: '0', marginLeft: '1vh'}}>FERMENTAÇÃO / DESTILARIA</div>}
        <div style={{backgroundColor: 'white', borderRadius: '1vh', marginLeft: '2vh'}}>
        {data && dataBlt && varsEtanol.map((item) => {
            let filteredData = dataBlt[item.codigo]
                return (
            <div className='fullLine' style={{marginLeft: '1vh', marginRight: '2vh', backgroundColor: 'white', borderBottom: '1px solid #f2f2f7', padding: '0.8vh'}}>
                <div className='fullLineRowBol' style={{width: '15%'}}>{item.codigo}</div>
                <div className='fullLineRowBol' style={{width: '25%'}}>{data[item.bmeCod]?.descricao}</div>
                <div className='fullLineRowBol' style={{width: '15%'}}>{filteredData?.valor === 'undefined' ? null : stdValue(filteredData?.valor)}</div>
                <div className='fullLineRowBol' style={{width: '15%', color: `${item.ipt ? 'blue' : 'black'}`}}>{stdValue(data[item.bmeCod]?.valor)}</div>
                <div className='fullLineRowBol' style={{width: '15%'}}>{psData ? (item.psCod && getPsValue(item.psCod, mes)) : ''}</div>
                <div className='fullLineRowBol' style={{width: '15%', textAlign: 'right'}}>{data[item.bmeCod]?.unidade}</div>
            </div>

        )})}
        </div>

      {dataBlt && <div className='psSectionHead' style={{backgroundColor: '#F2F2F7', border: '0', marginLeft: '1vh'}}>EFICIÊNCIA / PERDAS ART</div>}
      <div style={{backgroundColor: 'white', borderRadius: '1vh', marginLeft: '2vh'}}>
      {data && dataBlt && varsPerdas.map((item) => {
          let filteredData = dataBlt[item.codigo]
              return (
          <div className='fullLine' style={{marginLeft: '1vh', marginRight: '2vh', backgroundColor: 'white', borderBottom: '1px solid #f2f2f7', padding: '0.8vh'}}>
              <div className='fullLineRowBol' style={{width: '15%'}}>{item.codigo}</div>
              <div className='fullLineRowBol' style={{width: '25%'}}>{data[item.bmeCod]?.descricao}</div>
              <div className='fullLineRowBol' style={{width: '15%'}}>{filteredData?.valor === 'undefined' ? null : stdValue(filteredData?.valor)}</div>
              <div className='fullLineRowBol' style={{width: '15%', color: `${item.ipt ? 'blue' : 'black'}`}}>{stdValue(data[item.bmeCod]?.valor)}</div>
              <div className='fullLineRowBol' style={{width: '15%'}}>{psData ? (item.psCod && getPsValue(item.psCod, mes)) : ''}</div>
              <div className='fullLineRowBol' style={{width: '15%', textAlign: 'right'}}>{data[item.bmeCod]?.unidade}</div>
          </div>
      )})}
      </div>

        {dataBlt && <div className='psSectionHead' style={{backgroundColor: '#F2F2F7', border: '0', marginLeft: '1vh'}}>PRODUÇÃO</div>}
        <div style={{backgroundColor: 'white', borderRadius: '1vh', marginLeft: '2vh'}}>
        {data && dataBlt && varsProd.map((item) => {
            let filteredData = dataBlt[item.codigo]
                return (
            <div className='fullLine' style={{marginLeft: '1vh', marginRight: '2vh', backgroundColor: 'white', borderBottom: '1px solid #f2f2f7', padding: '0.8vh'}}>
                <div className='fullLineRowBol' style={{width: '15%'}}>{item.codigo}</div>
                <div className='fullLineRowBol' style={{width: '25%'}}>{data[item.bmeCod]?.descricao ? data[item.bmeCod]?.descricao : item.descricao}</div>
                <div className='fullLineRowBol' style={{width: '15%'}}>{filteredData?.valor === 'undefined' ? null : stdValue(parseFloat(filteredData?.valor))}</div>
                <div className='fullLineRowBol' style={{width: '15%', color: `${item.ipt ? 'blue' : 'black'}`}}>{data[item.bmeCod]?.valor ? stdValue(data[item.bmeCod]?.valor) : ''}</div>
                <div className='fullLineRowBol' style={{width: '15%'}}>{psData ? (item.psCod && getPsValue(item.psCod, mes)) : ''}</div>
                <div className='fullLineRowBol' style={{width: '15%', textAlign: 'right'}}>{data[item.bmeCod]?.unidade ? data[item.bmeCod]?.unidade : item.unidade}</div>
            </div>

        )})
        }
        {data && dataBlt &&
          <div className='fullLine' style={{marginLeft: '1vh', marginRight: '2vh', backgroundColor: 'white', borderBottom: '1px solid #f2f2f7', padding: '0.8vh'}}>
          <div className='fullLineRowBol' style={{width: '15%'}}></div>
          <div className='fullLineRowBol' style={{width: '25%'}}>Produção Açúcar BME - var. Processo</div>
          <div className='fullLineRowBol' style={{width: '15%'}}></div>
          <div className='fullLineRowBol' style={{width: '15%'}}>{data['flowAcucarTondia']?.valor ? stdValue(`${parseFloat(data['flowAcucarTondia']?.valor) - parseFloat(dataBlt['DIFPRAC']?.valor)}`) : ''}</div>
          <div className='fullLineRowBol' style={{width: '15%'}}></div>
          <div className='fullLineRowBol' style={{width: '15%', textAlign: 'right'}}></div>
          </div>
        }
        {data && dataBlt &&
          <div className='fullLine' style={{marginLeft: '1vh', marginRight: '2vh', backgroundColor: 'white', borderBottom: '1px solid #f2f2f7', padding: '0.8vh'}}>
          <div className='fullLineRowBol' style={{width: '15%'}}></div>
          <div className='fullLineRowBol' style={{width: '25%'}}>Produção Etanol BME - var. Processo</div>
          <div className='fullLineRowBol' style={{width: '15%'}}></div>
          <div className='fullLineRowBol' style={{width: '15%'}}>{data['flowEtanolTotal']?.valor ? stdValue(`${parseFloat(data['flowEtanolTotal']?.valor) - parseFloat(dataBlt['DIFPRAL']?.valor)}`) : ''}</div>
          <div className='fullLineRowBol' style={{width: '15%'}}></div>
          <div className='fullLineRowBol' style={{width: '15%', textAlign: 'right'}}></div>
          </div>
        }
        
        </div>

        {dataBlt && <div className='psSectionHead' style={{backgroundColor: '#F2F2F7', border: '0', marginLeft: '1vh'}}>RENDIMENTOS</div>}
        <div style={{backgroundColor: 'white', borderRadius: '1vh', marginLeft: '2vh'}}>
        {data && dataBlt && varsRend.map((item) => {
            let filteredData = dataBlt[item.codigo]
                return (
            <div className='fullLine' style={{marginLeft: '1vh', marginRight: '2vh', backgroundColor: 'white', borderBottom: '1px solid #f2f2f7', padding: '0.8vh'}}>
                <div className='fullLineRowBol' style={{width: '15%'}}>{item.codigo}</div>
                <div className='fullLineRowBol' style={{width: '25%'}}>{data[item.bmeCod]?.descricao}</div>
                <div className='fullLineRowBol' style={{width: '15%'}}>{filteredData?.valor === 'undefined' ? null : stdValue(filteredData?.valor)}</div>
                <div className='fullLineRowBol' style={{width: '15%', color: `${item.ipt ? 'blue' : 'black'}`}}>{stdValue(data[item.bmeCod]?.valor)}</div>
                <div className='fullLineRowBol' style={{width: '15%'}}>{psData ? (item.psCod && getPsValue(item.psCod, mes)) : ''}</div>
                <div className='fullLineRowBol' style={{width: '15%', textAlign: 'right'}}>{data[item.bmeCod]?.unidade}</div>
            </div>
        )})}
        </div>

        <div style={{height: '5vh'}}></div>
        </div>
        
        <Modal show={isLoading} progress={progress} texto={modalText}/>
        </div>
      );
}

export default DayAnalyzer;

function getRev(n){
  switch (n){
    case 1: return '1_Jan'
    case 2: return '2_Fev'
    case 3: return '3_Mar'
    case 4: return '4_Abr'
    case 5: return '5_Mai'
    case 6: return '6_Jun'
    case 7: return '7_Jul'
    case 8: return '8_Ago'
    case 9: return '9_Set'
    case 10: return '10_Out'
    case 11: return '11_Nov'
    case 12: return '12_Dez'
  }
}