import { UseColors } from "../../../../components/ui/colors"; 

const TenYearChartsDataUBT = () => {
    const COLORS = UseColors();

    return {
        'MATÉRIA PRIMA': [
            [{cod: 'psAtrPcts', secao: 'MATÉRIA PRIMA', color: COLORS.primaryColor}, {cod: 'psAtrDigestor', secao: 'MATÉRIA PRIMA', color: COLORS.secondaryColor}],
            [{cod: 'psFibraCana', secao: 'MATÉRIA PRIMA', color: COLORS.terciaryColor}, {cod: 'psBagacoCana', secao: 'MATÉRIA PRIMA', color: COLORS.fourColor}],
            [{cod: '', secao: 'EFICIÊNCIAS', color: COLORS.sevenColor}, {cod: '', secao: 'EFICIÊNCIAS', color: COLORS.eightColor}],
        ],
        'MOAGEM': [
            [{cod: 'psUptimeGeral', secao: 'UPTIME', color: COLORS.primaryColor}, {cod: 'psDiasSafra', secao: 'UPTIME', color: COLORS.secondaryColor}],
            [{cod: 'psTaxaMoagem', secao: 'MOAGEM', color: COLORS.terciaryColor}, {cod: 'psMoagemTotal', secao: 'MOAGEM', color: COLORS.fourColor}],
            [{cod: '', secao: 'EFICIÊNCIAS', color: COLORS.sevenColor}, {cod: '', secao: 'EFICIÊNCIAS', color: COLORS.eightColor}],
        ],
        'EFICIÊNCIAS': [
            [{cod: 'psArtEntrado', secao: 'EFICIÊNCIAS', color: COLORS.primaryColor}, {cod: 'psArtRecuperado', secao: 'EFICIÊNCIAS', color: COLORS.secondaryColor}],
            [{cod: 'psRitStab', secao: 'EFICIÊNCIAS', color: COLORS.terciaryColor}, {cod: 'psRitArt', secao: 'EFICIÊNCIAS', color: COLORS.fourColor}],
            [{cod: 'psEficienciaIndustrial', secao: 'EFICIÊNCIAS', color: COLORS.fiveColor}, {cod: 'psRelProdAcAtrEnt', secao: 'EFICIÊNCIAS', color: COLORS.sixColor}],
        ],
        'PERDAS': [
            [{cod: 'psPerdaBagaco', secao: 'EFICIÊNCIAS', color: COLORS.primaryColor}, {cod: 'psPerdaLavavem', secao: 'EFICIÊNCIAS', color: COLORS.secondaryColor}],
            [{cod: 'psPerdaTorta', secao: 'EFICIÊNCIAS', color: COLORS.terciaryColor}, {cod: 'psPerdaMultijato', secao: 'EFICIÊNCIAS', color: COLORS.fourColor}],
            [{cod: 'psPerdaFermentacao', secao: 'EFICIÊNCIAS', color: COLORS.fiveColor}, {cod: 'psPerdaResiduaria', secao: 'EFICIÊNCIAS', color: COLORS.sixColor}],
            [{cod: 'psPerdaIndeterminada', secao: 'EFICIÊNCIAS', color: COLORS.sevenColor}, {cod: '', secao: 'EFICIÊNCIAS', color: COLORS.eightColor}],
        ],
        'PRODUÇÕES': [
            [{cod: 'psProdAcucarTotal', secao: 'AÇÚCAR', color: COLORS.primaryColor}, {cod: 'psProdAcucarTotalSC', secao: 'AÇÚCAR', color: COLORS.secondaryColor}],
            [{cod: 'psProdEtanolTotalHid', secao: 'ETANOL', color: COLORS.terciaryColor}, {cod: 'psProdEtanolAnidro', secao: 'ETANOL', color: COLORS.fourColor}],
            // [{cod: 'psProdEtanolNeutro', secao: 'ETANOL', color: COLORS.fiveColor}, {cod: 'psEnergiaExportada', secao: 'ENERGIA', color: COLORS.sixColor}],
        ],
        'ESTOQUES': [
            [{cod: 'psEstoqueInicialBag', secao: 'BIOMASSA', color: COLORS.primaryColor}, {cod: 'psEstoqueFinalBag', secao: 'BIOMASSA', color: COLORS.secondaryColor}],
            [{cod: '', secao: 'EFICIÊNCIAS', color: COLORS.sevenColor}, {cod: '', secao: 'EFICIÊNCIAS', color: COLORS.eightColor}],
            [{cod: '', secao: 'EFICIÊNCIAS', color: COLORS.sevenColor}, {cod: '', secao: 'EFICIÊNCIAS', color: COLORS.eightColor}],
        ],
        'PAGE1': [
            [{cod: 'psAtrDigestor', secao: 'MATÉRIA PRIMA', color: COLORS.primaryColor}, {cod: 'psFibraCana', secao: 'MATÉRIA PRIMA', color: COLORS.secondaryColor}],
            [{cod: 'psUptimeGeral', secao: 'UPTIME', color: COLORS.terciaryColor}, {cod: 'psMoagemTotal', secao: 'MOAGEM', color: COLORS.fourColor}],
            [{cod: 'psTaxaMoagem', secao: 'MOAGEM', color: COLORS.fiveColor}, {cod: 'psRitStab', secao: 'EFICIÊNCIAS', color: COLORS.sixColor}],
        ],
        'PAGE2': [
            [{cod: 'psRitArt', secao: 'EFICIÊNCIAS', color: COLORS.primaryColor}, {cod: 'psRelProdAcAtrEnt', secao: 'EFICIÊNCIAS', color: COLORS.secondaryColor}],
            [{cod: 'psMixAcucar', secao: 'MIX', color: COLORS.terciaryColor}, {cod: 'psProdAcucarTotalSC', secao: 'AÇÚCAR', color: COLORS.fourColor}],
            [{cod: 'psProdEtanolTotal', secao: 'ETANOL', color: COLORS.fiveColor}, {cod: '', secao: 'ENERGIA', color: COLORS.sixColor}],
        ]
    };
};

export default TenYearChartsDataUBT;
