import React, {useContext, useEffect, useState } from 'react';
import PSContext from '../opoio/PSContext';
import GlobalStateContext from '../../components/GlobalStateContext';
import ChartsSegControl from '../charts/chartSegment';
import LineChart10YearPlan from '../charts/lineChart10YearPlan';
import { get10YearChartData } from '../../BME/Unidades/Geral/get10YearChartData';

export default function ChartsTenYearPlan({seqSafras, stdList, page = '', h = '30vh'}) {
  const {site} = useContext(GlobalStateContext)
  const {allPsData, selectedValues, selectedCharts, psIsPrinting} = useContext(PSContext);
  const [renderKey, setRenderKey] = useState(0);
  const [chartsData, setChartsData] = useState(null);

  useEffect(() => {
    setRenderKey(prevKey => prevKey + 1);
  }, [seqSafras]);

  useEffect(() => {
    async function getChartData() {
      let chartdata = await get10YearChartData(site)
      setChartsData(chartdata)
      console.log('ChartData', chartsData)
    }
    getChartData()
    
  }, []);

  return (
    <>
    {!psIsPrinting &&
    <ChartsSegControl/>
    }
      
      {allPsData && chartsData && seqSafras && stdList &&
        Object.keys(chartsData).map((item, index) => {
          if ((page === '' && selectedCharts === index) || (page !== '' && item === page)) {
            return (
              <div className='psBody' style={psIsPrinting ? {maxHeight: '150vh'} : {maxHeight: '80vh'}} 
              key={`${item}-${renderKey}-${index}`}>
                {chartsData[item].map((line, lineIndex) => (
                  <div key={`${item}-${renderKey}-${index}-${lineIndex}`} style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginLeft: '1vh', marginRight: '1vh', marginBottom: '0.5vh'}}>
                    {line.map((chart) => {
                      if (chart.cod !== '') {
                        return (
                          <LineChart10YearPlan 
                            key={`${chart.cod}-${renderKey}-${lineIndex}`} 
                            seqSafras={seqSafras} 
                            chart={chart} 
                            stdList={stdList} 
                            selectedValues={selectedValues}
                            h={h}
                          />
                        );
                      } else {
                        return (<div key={`${lineIndex}-${renderKey}`} style={{height: '30vh', width: '100%', borderRadius: '2%', margin: '1vh'}}></div>);
                      }      
                    })}
                  </div>
                ))}
              </div>
            );
          }
          return null;
        })
      }
    </>
  );
}
