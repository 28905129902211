import React, {useContext, useRef, useState }  from 'react';
import { LuFileSearch } from "react-icons/lu";
import PSContext from '../opoio/PSContext';
import MenuBtDivider from '../../components/ui/menuBtDivider';
import GlobalStateContext from '../../components/GlobalStateContext';
import { getStdPlanoSafra } from '../../BME/Unidades/Geral/getStdPlanoSafra';
import PsHooks from '../../Hooks/psHooks';

export default function BtImportPsOld() {
  const {isSave, setIsChart, isNew, isSaveAs, isOpen, isExpPdf, isEdit, isChart, psData, setAllPsData} = useContext(PSContext)
  const {site, user} = useContext(GlobalStateContext)
  const [isDisable, setIsDisable] = useState(false)
  const stdPlanoVars = getStdPlanoSafra(site)
  const { newPS, resumeAllPS } = PsHooks()

  const [jsonData, setJsonData] = useState(null);
  const fileInputRef = useRef(null)

  // Função para manipular o upload e transformar em JSON
  const handleFileUpload = (e) => {
    const fileInput = document.getElementById('fileInputPsOld');
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        const text = event.target.result;

        // Dividindo o texto por linhas
        const lines = text.split('\n');

        // Separando os cabeçalhos (primeira linha)
        const headers = lines[0].split(',').map(header => header.trim());

        // Inicializa um array para armazenar os dados JSON
        const jsonArray = [];

        // Itera sobre as linhas restantes (a partir da segunda linha)
        lines.slice(1).forEach((line) => {
          const values = line.split(',').map(value => value.trim());

          // Cria um objeto para cada linha, usando os cabeçalhos como chaves
          const obj = {};
          headers.forEach((header, index) => {
            obj[header] = values[index]; // Associa cada valor ao seu respectivo cabeçalho
          });

          // Adiciona o objeto ao array JSON
          jsonArray.push(obj);
          
        }); 
        // Define o estado com os dados JSON
        setJsonData(jsonArray)
        setPS(jsonArray)

        if (fileInputRef.current) {
            fileInputRef.current.value = null; // Limpa o campo de input
          }
         
      };



      // Ler o arquivo como texto
      reader.readAsText(file);
    }
  };

  async function setPS(importedPS){
    let ps = []
    console.log('jsonData', importedPS)
    let rev = importedPS[0].rev
    let safra = importedPS[0].Safra
        for (const item of stdPlanoVars){
            
                if (item.show !== false){
                    let impItem = importedPS.filter(i => getEquivCod(i.cod) === item.cod)
                    // console.log('impItem', impItem, item.cod)
                    if (impItem.length > 0){
                        let imp = impItem[0]
                        let newItem = {
                            ssr: `${site}-${safra}-${rev}`,
                            siteId: site, 
                            safra: safra, 
                            revisao: rev, 
                            secao: imp.grupo, 
                            descricao: item.desc,
                            codigo: item.cod, 
                            unidade: item.unid,
                            jan: parseFloat(imp.jan),
                            fev: parseFloat(imp.fev),
                            mar: parseFloat(imp.mar),
                            abr: parseFloat(imp.abr),
                            mai: parseFloat(imp.mai),
                            jun: parseFloat(imp.jun),
                            jul: parseFloat(imp.jul),
                            ago: parseFloat(imp.ago),
                            set: parseFloat(imp.set),
                            out: parseFloat(imp.out),
                            nov: parseFloat(imp.nov),
                            dez: parseFloat(imp.dez),
                            acu: 0.0, 
                            input: !item.ipt ? null : item.ipt}
                            ps.push(newItem)
                    }else{
                        let newItem = {
                            ssr: `${site}-${safra}-${rev}`,
                            siteId: site, 
                            safra: safra, 
                            revisao: rev, 
                            secao: item.grp, 
                            descricao: item.desc,
                            codigo: item.cod, 
                            unidade: item.unid,
                            jan: 0.0,
                            fev: 0.0,
                            mar: 0.0,
                            abr: 0.0,
                            mai: 0.0,
                            jun: 0.0,
                            jul: 0.0,
                            ago: 0.0,
                            set: 0.0,
                            out: 0.0,
                            nov: 0.0,
                            dez: 0.0,
                            acu: 0.0, 
                            input: !item.ipt ? null : item.ipt}
                            ps.push(newItem)
                    }
                }
            
        }
        console.log('New PS', ps)
        await newPS(ps, user.email)
        let allPs = await resumeAllPS(site)
        setAllPsData(allPs)
  }

  function getEquivCod(cod){
    switch(cod){
        case 'psperdaResiduaria': return 'psPerdaResiduaria'
        case 'psperdaFermentacao': return 'psPerdaFermentacao'
        case 'psArtemRecuperado': return 'psArtRecuperado'
        default: return cod
    }
  }

  if (isOpen && !isEdit){
    return (
        <div style={{display: 'flex', flexDirection: 'row', marginTop: '0.2vh'}} >
          <MenuBtDivider/>
          <button className='psButtom'>
            <div className='psMenuButtom' >
                <label htmlFor="fileInputPsOld">
                <LuFileSearch  className='psMenuIcon'/>
                <div className='psMenuText'>ABRIR .psUSL</div>
                </label>
            </div>
          </button>
        <input id="fileInputPsOld" type="file" accept={'.psUSL'} onChange={handleFileUpload} style={{ display: 'none'}}  ref={fileInputRef}/>
      </div>
      );
  }
}