import React, { useState, useContext, useEffect, useRef } from 'react'
import '../../css/InputLine.css'
import GlobalStateContext from '../GlobalStateContext'
import SwitchControl from "../../components/ui/Switch";
import { getAlertList } from '../../Funcoes/GetAletrsList'
import { stdValue } from '../../Resources/setNumber';
import ModalUpdateValue from '../ui/ModalUpdateValue';
import Segment from '../ui/Segment';


function IptLineCapVap({cod}) {
  const { setAlert, data, setlistAlerts, user, setData, setShowUpdating } = useContext(GlobalStateContext);
 
  const [selectedValue1, setSelectedValue1] = useState(parseInt(data[cod[0]].valor));
  const [selectedValue2, setSelectedValue2] = useState(parseInt(data[cod[0]].op2));
  const [selectedValue3, setSelectedValue3] = useState(parseInt(data[cod[1]]?.valor))
  const [v, setV] = useState(stdValue(data[cod[0]]?.valor))
  const [prevV, setPrevV] = useState('')
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    setSelectedValue1(`${parseInt(data[cod[0]]?.valor)}`) 
    setSelectedValue2(`${parseInt(data[cod[0]]?.op2)}`) 
  }, [ cod, data]);

  const handleAlert = (v) =>{
    setAlert(true)
}

  const handleIpt = (e) => {
    setV(e.target.value)
  }


  const updateValue = async (e) => {
    if (selectedValue1 === '' || !contemNumero(selectedValue1)){
      setV(prevV)
      
    }else{
      if (selectedValue1 !== prevV){
        setShowUpdating(true)
        setV(e.target.value)
        handleAlert(true)
          setData(prevData => ({
            ...prevData,
            [cod[0]]: {
              ...prevData[cod[0]],
              valor: parseFloat(selectedValue1),
            },
          }))
          setShowUpdating(false)
          setlistAlerts(getAlertList(data))
      }
    }
    
  }

  const handlePrevV = (e) => {
    setPrevV(e.target.value)
  }

  const updateValue2 = async (val) => {
    setSelectedValue1(val)
    setShowUpdating(true)
      console.log('updated: '+val)
      setData(prevData => ({
        ...prevData,
        [cod[0]]: {
          ...prevData[cod[0]],
          op2: val
        },
      }))
      setAlert(true)
      setShowUpdating(false)

     
}

function SegVap() {
        
    
      
    const updateValue = async (val) => {
        setSelectedValue3(val)
        setShowUpdating(true)
          setData(prevData => ({
            ...prevData,
            [cod]: {
              ...prevData[cod],
              valor: val
            },
          }))
          setAlert(true)
          setShowUpdating(false)
    }
  
   
  
    const getSeg = () => {
      if (cod[0] == 'glVinhoConsVapor'){
        return getSegVaporDest
      }else if (cod == 'opVaporEtNeutro'){
        return getSegV1VE
      }
      
      if (cod[3] === 'V2V1'){
        return getSegV2V1
      }
      console.log('Seg2', cod[3], cod)
  
      if (cod[0] === 'opVaporAqCCAcucar'){
        return getSegVEV2V1
      }
  
      let max = data[cod[0]]?.limiteMax
      let q = 6
      if (max !== null){
        q = max
      }
      console.log('Max: '+ max)
      switch(q){
        case 2: return getSeg02
        case 3: return getSeg03
        case 4: return getSeg04
        case 5: return getSeg05
        case 6: return getSeg06
        default: return getSeg01
      }
    }
  
    const getSegVaporDest = [
      {label: "CALCULADO",  value: 0, ref: useRef()},
      {label: "BOLETIM", value: 1, ref: useRef()}
    ]
  
    const getSegV1VE = [
      {label: "VV1",  value: 0, ref: useRef()},
      {label: "VE", value: 1, ref: useRef()}
    ]
  
    const getSegV2V1 = [
      {label: "VV2",  value: 0, ref: useRef()},
      {label: "VV1", value: 1, ref: useRef()}
    ]
  
    const getSegVEV2V1 = [
      {label: "VV2",  value: 0, ref: useRef()},
      {label: "VV1", value: 1, ref: useRef()},
      {label: "VE", value: 2, ref: useRef()}
    ]
  
    const getSeg01 = [
      {label: "0",  value: 0, ref: useRef()},
      {label: "1", value: 1, ref: useRef()}
    ]
    const getSeg02 = [
      {label: "0", value: 0, ref: useRef()},
      {label: "1", value: 1, ref: useRef()},
      {label: "2", value: 2, ref: useRef()}
    ]
    const getSeg03 = [
      {label: "0", value: 0, ref: useRef()},
      {label: "1", value: 1, ref: useRef()},
      {label: "2", value: 2, ref: useRef()},
      {label: "3",value: 3,ref: useRef()}
    ]
  
    const getSeg04 = [
      {label: "0", value: 0, ref: useRef()},
      {label: "1", value: 1, ref: useRef()},
      {label: "2", value: 2, ref: useRef()},
      {label: "3", value: 3, ref: useRef()},
      {label: "4", value: 4, ref: useRef()}
    ]
    const getSeg05 = [
      {label: "0", value: 0, ref: useRef()},
      {label: "1", value: 1, ref: useRef()},
      {label: "2", value: 2, ref: useRef()},
      {label: "3", value: 3, ref: useRef()},
      {label: "4", value: 4, ref: useRef()},
      {label: "5", value: 5, ref: useRef()}
    ]
    const getSeg06 = [
      {label: "0", value: 0, ref: useRef()},
      {label: "1", value: 1, ref: useRef()},
      {label: "2", value: 2, ref: useRef()},
      {label: "3", value: 3, ref: useRef()},
      {label: "4", value: 4, ref: useRef()},
      {label: "5", value: 5, ref: useRef()},
      {label: "6", value: 6, ref: useRef()}
    ]
  
    const controlRef = useRef();
  
    if (data[cod[0]].hide !== true){
      return (
        <>
        <Segment
          descricao={cod[3] !== '' ? '' : data[cod]?.descricao}
            name="group-1"
            callback={(val) => updateValue(val)}
            controlRef={controlRef}
            defaultIndex={selectedValue3}
            segments={getSeg()}
            w={cod[3] !== '' && 0}
          />
        </>
      )
    }else{
      <></>
    }
  }

  return (
    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
         <div className='inputLineCont'>
            <div className='line'>
                <div className='desc'>{data[cod[0]]?.descricao}</div>
                {/* <div className='iptCont'> */}
                    <input className={`ipt ${data[cod[0]]?.limiteMax !== null && data[cod[0]]?.valor > data[cod[0]]?.limiteMax ? 'outOfLimit' : ''}`} value={selectedValue1} id={cod[0]} onChange={handleIpt} onBlur={updateValue} onFocus={handlePrevV} type="text" />
                {/* </div> */}
                <div className='unit'>{data[cod[0]]?.unidade}</div>
            </div>
    </div>

    <div style={{width: '22%'}}>
    {/* <ComboLine cod={cod[1]}  seg={cod[3]}/> */}
    <SegVap/>
    
    </div>
            
        <SwitchControl
                descricao={""} name="group-1" callback={(val) => updateValue2(val)} controlRef={useRef()} 
                defaultIndex={selectedValue2} 
                segments={[
                    {
                    label: "0",
                    value: 0,
                    ref: useRef()
                    },
                    {
                    label: "1",
                    value: 1,
                    ref: useRef()
                    }
                ]}
                w={0}
      />
      <ModalUpdateValue show={showModal}><p>Atualizando...</p></ModalUpdateValue>
    </div>
    
  )
}

export default IptLineCapVap

function contemNumero(string) {
    return !isNaN(parseFloat(string)) && isFinite(string);
  }