const AnalyticsHooks = () => {

  const newAction = async (action, site, user) => {
    try{
        let newItem = {siteId: site, userId: user, acao: action}
        const response  = await fetch(`${global.endPoint}/newaction`, {
            method: 'PUT',
            headers: {
            'Content-Type': 'application/json'
            },
            body: JSON.stringify({data: newItem})
        })
        if (!response.ok) {
            throw new Error('Erro ao buscar dados da API');
        }
        const jsonData = await response.json();
        console.log('return', jsonData)
        }catch (error){
        console.error('Erro ao Importar Arquivo:', error);
        }
  }

  return {newAction};
};

export default AnalyticsHooks;
