import React, {useContext } from 'react';
import { LuFileSearch } from "react-icons/lu";
import { ACContext } from './AnaliseCenariosContext';
import GlobalStateContext from '../../components/GlobalStateContext';
import CryptoJS from 'crypto-js';
import AnalyticsHooks from '../../Hooks/analyticsHooks';

export default function BtImportCenario() {
    const { AcData, setAcData, isEditing} = useContext(ACContext)
    const {user, site} = useContext(GlobalStateContext); 

    const {newAction} = AnalyticsHooks()

      const handleFileChange = (event) => {
        newAction('ImportarCenario', site, user.email || '')
      const fileInput = document.getElementById('fileInput');
      const file = event.target.files[0];
  
      if (file) {
          const fileExtension = file.name.split('.').pop();
          if (fileExtension === 'pms'+user.sites[site].sigla) {
              const reader = new FileReader();
              reader.onload = async (e) => {
                  const encryptedContent = e.target.result
                  const jsonData = decryptData(encryptedContent); 
                  if (jsonData) {
                    const fileNameWithExtension = fileInput.value.split('\\').pop()
                    const fileName = fileNameWithExtension.split('.').slice(0, -1).join('.')
                    let newBME = {}
                    newBME[fileName] = jsonData
                    const newData = [...AcData, newBME];
                    setAcData(newData);
                  } else {
                      alert('Arquivo foi alterado ou não pertence à unidade atual e não pode ser aberto.'); 
                  }
              };
              reader.readAsText(file);
          } else {
              alert('Por favor, selecione um arquivo com a extensão .pms');
          }
      }
  }

    function decryptData(encryptedData) {
    const secretKey = 'my-secret-key'+site; // A mesma chave secreta usada na criptografia
    try {
        const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
        const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
        if (decryptedData) {
            return JSON.parse(decryptedData);
        } else {
            return null
        }
    } catch (error) {
        console.error('Erro na descriptografia:', error.message);
        return null;
    }
}

  return (
              <div style={{display: 'flex', flexDirection: 'row', marginTop: '0.3vh'}} >
        <button className='psButtom' style={isEditing ? {scale: '1.0', opacity: '0.7'} : {}}>
          <div className='psMenuButtom' >
              <label htmlFor="fileInput">
              <LuFileSearch  className='psMenuIcon'/>
              <div className='psMenuText'>IMPORTAR</div>
              </label>
          </div>
        </button>
      {!isEditing && <input id="fileInput" type="file" accept={`.pms${user.sites[site].sigla}`} onChange={handleFileChange} style={{ display: 'none' }} />}
    </div>
  )
}