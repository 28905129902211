const varsMoagem = [
    {"codigo": "CMOIMT", "descricao": "Moagem (t)", "bmeCod": "flowCanaDia", psCod: 'psMoagemDiaria', "ipt": false},
    {"codigo": "HEFMM", "descricao": "Horas Efetivas", "bmeCod": "horasEfetivas", "ipt": true},
    {"codigo": "TCHMED", "descricao": "TCH Efetiva", "bmeCod": "flowCana", psCod: 'psTaxaMoagem', "ipt": true},
    {"codigo": "ARTPREI", "descricao":"Ac. Redutor Totais (% Cana)", "bmeCod": "artCana", "ipt": true},
    {"codigo": "ATRPRE", "descricao":"ATR Geral (kg/tc)", "bmeCod": "atrCanaPCTS", psCod: 'psAtrPcts', "ipt": false},
    {"codigo": "PZMED", "descricao":"Pureza Média (%)", "bmeCod": "purezaCana", "ipt": true},
    {"codigo": "FIBPRE", "descricao":"Fibra % Cana", "bmeCod": "fibraCana", psCod: 'psFibraCana', "ipt": true},
    {"codigo": "ARPRE", "descricao":"Açúcar Redutor Calculado", "bmeCod": "arCana", "ipt": true},
    {"codigo": "EMBVMM", "descricao": "Embebição % Cana", "bmeCod": "embCana", "ipt": true},
    {"codigo": "EMFIM", "descricao": "Embebição % Fibra", "bmeCod": "fibraEmbeb", "ipt": false},

    {"codigo": "EXREDM", "bmeCod": "efExtMoenda", "ipt": true},
    {"codigo": "FIBBAG", "bmeCod": "fibraBagaco", "ipt": true},
    {"codigo": "UMBAM", "bmeCod": "umidBagaco", "ipt": true},
    {"codigo": "ARTBAGM", "bmeCod": "artBagaco", "ipt": false},
    {"codigo": "POBAM", "bmeCod": "polBagaco", "ipt": true}
]

const varsTrat = [
    {"codigo": "BXCAFIF", "bmeCod": "brixCaldoFiltrado", "ipt": false},
    {"codigo": "BXDEC", "bmeCod": "brixCaldoClarifAcucar", "ipt": false},
    // {"codigo": "PUDECFA", "bmeCod": "purezaCaldoClarifAcucar", "ipt": false},
    {"codigo": "BXDEDES", "bmeCod": "brixCaldoClarifEtanol", "ipt": false},
    // {"codigo": "PUDDES", "descricao": "Pureza Decantado (%)"},
    {"codigo": "KGTORTC", "bmeCod": "prodTorta", "ipt": true},
    {"codigo": "ARTTOG", "bmeCod": "artTorta", "ipt": false},
    // {"codigo": "ARTCANTC", "descricao": "ART Canaleta Trat. Caldo (%)"},
]

const varsAcucar = [
    {"codigo": "BXXA", "bmeCod": "brixXarope", "ipt": true},
    {"codigo": "PUXAR", "bmeCod": "purezaXarope", "ipt": true},
    {"codigo": "BXMAPRI", "bmeCod": "brixMassaA", "ipt": true},
    {"codigo": "PUMAPRI", "bmeCod": "purezaMassaA", "ipt": false},
    {"codigo": "BXMASEG", "bmeCod": "brixMassaB", "ipt": true},
    {"codigo": "PUMASEG", "bmeCod": "purezaMassaB", "ipt": false},
    {"codigo": "BXMAG", "bmeCod": "brixMagma", "ipt": true},
    {"codigo": "PUMAG", "bmeCod": "purezaMagma", "ipt": true},
    {"codigo": "BXMERI", "bmeCod": "brixMelARico", "ipt": true},
    {"codigo": "PUMERI", "bmeCod": "purezaMelARico", "ipt": true},
    {"codigo": "BXMEPO", "bmeCod": "brixMelAPobre", "ipt": true},
    {"codigo": "PUMEPO", "bmeCod": "purezaMelAPobre", "ipt": true},
    {"codigo": "BXMELD", "bmeCod": "brixMelBRico", "ipt": true},
    // {"codigo": "ARTMELD", "descricao": "ART do Mel B (%)"},
    {"codigo": "BXMEFI", "bmeCod": "brixMelFinal", "ipt": true},
    {"codigo": "PUMEFI", "bmeCod": "purezaMelFinal", "ipt": true},
    {"codigo": "RECFAB2", "bmeCod": "recupSJM", psCod: 'psRecSJM', "ipt": false},
]

const varsEtanol = [
    {"codigo": "BXMOSG", "bmeCod": "brixMosto", "ipt": true},
    {"codigo": "ARTMOSG", "bmeCod": "artMosto", "ipt": false},
    // {"codigo": "PUMOM", "bmeCod": "purezaMosto", "ipt": false},
    // {"codigo": "SOLMF1", "bmeCod": "brixMassaMosto", "ipt": false},
    {"codigo": "TEMOA", "bmeCod": "tempMostoResf", "ipt": true},
    {"codigo": "ALVINME", "bmeCod": "concEtanolVinhoBruto", "ipt": false},//"descricao": "Grau Alc. Vinho Levurado"},
    // {"codigo": "VID4F1", "bmeCod": "concCelVinhoBruto", "ipt": true},//"descricao": "Viabilidade Cel.Vinho (%)"},
    // {"codigo": "BROVIG", "descricao": "Brotamento no Vinho (%)"},
    // {"codigo": "ARVIM", "descricao": "ART do Vinho (%)"},

]

const varsPerdas = [
    // {"codigo": "RITART", "descricao": "RIT STAB / ART (%)"},
    {"codigo": "EFIDEST", "bmeCod": "efDestilaria"},
    // {"codigo": "EFIFER", "bmeCod": "efFermentacao", "ipt": true},
    // {"codigo": "EFIFER4", "descricao": "Fermentação - Med. Mosto Vazão - IC (%)"},
    {"codigo": "RENSPR", "bmeCod": "efFermentacao","ipt": true},
    {"codigo": "EFIDES3", "bmeCod": "rgd",  psCod: 'psRGD', },
    // {"codigo": "EFIDESIC", "descricao": "Geral da Destilaria - IC (%)"},
    {"codigo": "%ARTPEB", "bmeCod": "perdaArtExtracao",  psCod: 'psPerdaBagaco'},
    {"codigo": "%ARTPET", "bmeCod": "perdaArtTorta",  psCod: 'psPerdaTorta', "ipt": true},
    {"codigo": "%ARTPDSP", "bmeCod": "perdaFermDest", psCod: 'psPerdaFermentacao'},
    {"codigo": "%ARTPD3", "bmeCod": "perdaArtIndeterminda",  psCod: 'psPerdaIndeterminada', "ipt": true},
    {"codigo": "%ARTPLC", "bmeCod": "perdaArtLavagemCana",  psCod: 'psPerdaLavavem', "ipt": true},
    {"codigo": "%ARTPMJ", "bmeCod": "perdaArtMultijatoFabrica",  psCod: 'psPerdaMultijato', "ipt": true},
    {"codigo": "ARTPEF", "bmeCod": "perdaArtAguaResiduaria",  psCod: 'psPerdaResiduaria', "ipt": true},
    // {"codigo": "%ARTEFT", "bmeCod": "Perdas Efluente Fábrica % ART Entr."},
    // {"codigo": "%ARTPEFD", "bmeCod": "Perdas Efluente Destilaria % ART Entr."}, 
    {"codigo": "%ARTPETOT", "bmeCod": "perdaTotalArt"},
]

const varsProd = [
    {"codigo": "ACTOT", "bmeCod": "flowAcucarTondia",  psCod: 'psTaxaEfProdAcucar', "ipt": false},
    // {"codigo": "ALCHIDT", "descricao": "Hidratado"},
    // {"codigo": "HJAPAO", "descricao": "Ind. REN"},
    // {"codigo": "HCOREIA", "descricao": "Ind. Grade B"},
    // {"codigo": "HIDNEUT", "descricao": "Hid. Neutro"},
    {"codigo": "PENMOL", "bmeCod": "flowEtanolAnidroDia",  psCod: 'psProdEfEtanolAnidro'},
    // {"codigo": "EXTNEUT", "descricao": "Extra Neutro"},
    // {"codigo": "ANIEURO", "descricao": "An. Europa"},
    {"codigo": "ACNEUTOT", "bmeCod": "flowEtanolNeutroDia",  psCod: 'psProdEfEtanolNeutro'},
    {"codigo": "ACHIDTOT", "bmeCod": "flowEtanolHidDia",  psCod: 'psProdEfEtanolHidpTq'},
    {"codigo": "ALCTOT", "bmeCod": "flowEtanolTotal"},
    {"codigo": "DIFPRAC", "descricao": "Variação Processo Açucar", "bmeCod": "", "unidade": "ton"},
    {"codigo": "DIFPRAL", "descricao": "Variação Processo Etanol", "bmeCod": "", "unidade": "m³"},
]

const varsRend = [
    {"codigo": "BSMIXAC", "bmeCod": "mixAcucar", psCod: 'psMixAcucar'},
    // {"codigo": "UNICOP", "bmeCod": "prodUnicops"},
    {"codigo": "RITSTAB", "bmeCod": "ritSTAB", psCod: 'psRitStab'},
    {"codigo": "RITART", "bmeCod": "ritART", psCod: 'psRitArt'},
    {"codigo": "EFCONV", "bmeCod": "efIndustrial", psCod: 'psEficienciaIndustrial'},
    {"codigo": "SCTC", "bmeCod": "flowAcucarScsTc"},
    {"codigo": "KGACATR", "bmeCod": "relProdAcucarAtrEnt", psCod: 'psRelProdAcAtrEnt'},
    // {"codigo": "LATR", "descricao": "L.Etanol / ATR"},

]

const varsAll = [
    {"codigo": "CMOIMT", "descricao": "Moagem (t)", "bmeCod": "flowCanaDia", "ipt": false},
    {"codigo": "HEFMM", "descricao": "Horas Efetivas", "bmeCod": "horasEfetivas", "ipt": true},
    {"codigo": "TCHMED", "descricao": "TCH Efetiva", "bmeCod": "flowCana", "ipt": true},
    {"codigo": "ARTPREI", "descricao":"Ac. Redutor Totais (% Cana)", "bmeCod": "artCana", "ipt": true},
    {"codigo": "ATRPRE", "descricao":"ATR Geral (kg/tc)", "bmeCod": "atrCanaPCTS", "ipt": false},
    {"codigo": "PZMED", "descricao":"Pureza Média (%)", "bmeCod": "purezaCana", "ipt": true},
    {"codigo": "FIBPRE", "descricao":"Fibra % Cana", "bmeCod": "fibraCana", "ipt": true},
    {"codigo": "ARPRE", "descricao":"Açúcar Redutor Calculado", "bmeCod": "arCana", "ipt": true},
    {"codigo": "EMBVMM", "descricao": "Embebição % Cana", "bmeCod": "embCana", "ipt": true},
    {"codigo": "EMFIM", "descricao": "Embebição % Fibra", "bmeCod": "fibraEmbeb", "ipt": false},

    {"codigo": "EXREDM", "bmeCod": "efExtMoenda", "ipt": true},
    {"codigo": "FIBBAG", "bmeCod": "fibraBagaco", "ipt": true},
    {"codigo": "UMBAM", "bmeCod": "umidBagaco", "ipt": true},
    {"codigo": "ARTBAGM", "bmeCod": "artBagaco", "ipt": false},
    {"codigo": "POBAM", "bmeCod": "polBagaco", "ipt": true},

    {"codigo": "BXCAFIF", "bmeCod": "brixCaldoFiltrado", "ipt": false},
    {"codigo": "BXDEC", "bmeCod": "brixCaldoClarifAcucar", "ipt": false},
    // {"codigo": "PUDECFA", "bmeCod": "purezaCaldoClarifAcucar", "ipt": false},
    {"codigo": "BXDEDES", "bmeCod": "brixCaldoClarifEtanol", "ipt": false},
    // {"codigo": "PUDDES", "descricao": "Pureza Decantado (%)"},
    {"codigo": "KGTORTC", "bmeCod": "prodTorta", "ipt": true},
    {"codigo": "ARTTOG", "bmeCod": "artTorta", "ipt": false},
    // {"codigo": "ARTCANTC", "descricao": "ART Canaleta Trat. Caldo (%)"},

    {"codigo": "BXXA", "bmeCod": "brixXarope", "ipt": true},
    {"codigo": "PUXAR", "bmeCod": "purezaXarope", "ipt": true},
    {"codigo": "BXMAPRI", "bmeCod": "brixMassaA", "ipt": true},
    {"codigo": "PUMAPRI", "bmeCod": "purezaMassaA", "ipt": false},
    {"codigo": "BXMASEG", "bmeCod": "brixMassaB", "ipt": true},
    {"codigo": "PUMASEG", "bmeCod": "purezaMassaB", "ipt": false},
    {"codigo": "BXMAG", "bmeCod": "brixMagma", "ipt": true},
    {"codigo": "PUMAG", "bmeCod": "purezaMagma", "ipt": true},
    {"codigo": "BXMERI", "bmeCod": "brixMelARico", "ipt": true},
    {"codigo": "PUMERI", "bmeCod": "purezaMelARico", "ipt": true},
    {"codigo": "BXMEPO", "bmeCod": "brixMelAPobre", "ipt": true},
    {"codigo": "PUMEPO", "bmeCod": "purezaMelAPobre", "ipt": true},
    {"codigo": "BXMELD", "bmeCod": "brixMelBRico", "ipt": true},
    // {"codigo": "ARTMELD", "descricao": "ART do Mel B (%)"},
    {"codigo": "BXMEFI", "bmeCod": "brixMelFinal", "ipt": true},
    {"codigo": "PUMEFI", "bmeCod": "purezaMelFinal", "ipt": true},
    {"codigo": "RECFAB2", "bmeCod": "recupSJM", "ipt": false},

    {"codigo": "BXMOSG", "bmeCod": "brixMosto", "ipt": true},
    {"codigo": "ARTMOSG", "bmeCod": "artMosto", "ipt": false},
    {"codigo": "PUMOM", "bmeCod": "purezaMosto", "ipt": false},
    {"codigo": "SOLMF1", "bmeCod": "brixMassaMosto", "ipt": false},
    {"codigo": "TEMOA", "bmeCod": "tempMostoResf", "ipt": true},
    {"codigo": "ALVINME", "bmeCod": "concEtanolVinhoBruto", "ipt": false},//"descricao": "Grau Alc. Vinho Levurado"},
    // {"codigo": "VID4F1", "bmeCod": "concCelVinhoBruto", "ipt": true},//"descricao": "Viabilidade Cel.Vinho (%)"},
    // {"codigo": "BROVIG", "descricao": "Brotamento no Vinho (%)"},
    // {"codigo": "ARVIM", "descricao": "ART do Vinho (%)"},

    // {"codigo": "RITART", "descricao": "RIT STAB / ART (%)"},
    {"codigo": "EFIDEST", "bmeCod": "efDestilaria"},
    // {"codigo": "EFIFER", "bmeCod": "efFermentacao", "ipt": true},
    // {"codigo": "EFIFER4", "descricao": "Fermentação - Med. Mosto Vazão - IC (%)"},
    {"codigo": "RENSPR", "bmeCod": "efFermentacao", "ipt": true},
    {"codigo": "EFIDES3", "bmeCod": "rgd"},
    // {"codigo": "EFIDESIC", "descricao": "Geral da Destilaria - IC (%)"},
    {"codigo": "%ARTPEB", "bmeCod": "perdaArtExtracao"},
    {"codigo": "%ARTPET", "bmeCod": "perdaArtTorta", "ipt": true},
    {"codigo": "%ARTPDSP", "bmeCod": "perdaFermDest"},
    {"codigo": "%ARTPD3", "bmeCod": "perdaArtIndeterminda", "ipt": true},
    {"codigo": "%ARTPLC", "bmeCod": "perdaArtLavagemCana", "ipt": true},
    {"codigo": "%ARTPMJ", "bmeCod": "perdaArtMultijatoFabrica", "ipt": true},
    {"codigo": "ARTPEF", "bmeCod": "perdaArtAguaResiduaria", "ipt": true},
    // {"codigo": "%ARTEFT", "bmeCod": "Perdas Efluente Fábrica % ART Entr."},
    // {"codigo": "%ARTPEFD", "bmeCod": "Perdas Efluente Destilaria % ART Entr."}, 
    {"codigo": "%ARTPETOT", "descricao": "Perdas Totais % ART"},


    {"codigo": "ACTOT", "bmeCod": "flowAcucarTondia", "ipt": false},
    // {"codigo": "ALCHIDT", "descricao": "Hidratado"},
    // {"codigo": "HJAPAO", "descricao": "Ind. REN"},
    // {"codigo": "HCOREIA", "descricao": "Ind. Grade B"},
    // {"codigo": "HIDNEUT", "descricao": "Hid. Neutro"},
    {"codigo": "PENMOL", "bmeCod": "flowEtanolAnidroDia"},
    // {"codigo": "EXTNEUT", "descricao": "Extra Neutro"},
    // {"codigo": "ANIEURO", "descricao": "An. Europa"},
    {"codigo": "ACNEUTOT", "bmeCod": "flowEtanolNeutroDia"},
    {"codigo": "ACHIDTOT", "bmeCod": "flowEtanolHidDia"},
    {"codigo": "ALCTOT", "bmeCod": "flowEtanolTotal"},
    {"codigo": "DIFPRAC", "descricao": "Variação Processo Açucar", "bmeCod": "", "unidade": "ton"},
    {"codigo": "DIFPRAL", "descricao": "Variação Processo Etanol", "bmeCod": "", "unidade": "m³"},

    {"codigo": "BSMIXAC", "bmeCod": "mixAcucar"},
    {"codigo": "UNICOP", "bmeCod": "prodUnicops"},
    {"codigo": "RITSTAB", "bmeCod": "ritSTAB"},
    {"codigo": "RITART", "bmeCod": "ritART"},
    {"codigo": "EFCONV", "bmeCod": "efIndustrial"},
    {"codigo": "SCTC", "bmeCod": "flowAcucarScsTc"},
    {"codigo": "KGACATR", "bmeCod": "relProdAcucarAtrEnt"},
    {"codigo": "LATR", "descricao": "L.Etanol / ATR"},

]

module.exports = {
    varsMoagem,
    varsTrat,
    varsAcucar,
    varsEtanol,
    varsProd,
    varsRend,
    varsAll,
    varsPerdas
}