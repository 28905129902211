import { stdValue } from "../../../Resources/setNumber"

function AlertsLine({item}) {
    return (
        <div style={{ borderBottom: '1px solid #f2f2f7'}}>
            <div className='fullLine' style={{padding: '1vh'}}>
                <div className='fullLineRow' style={{width: '40%'}}>{item?.descricao}</div>
                <div className='fullLineRow' style={{width: '15%'}}>{stdValue(item?.valor)}</div>
                <div className='fullLineRow' style={{width: '15%'}}>{item?.limiteMin !== null ? stdValue(item?.limiteMin) : ''}</div>
                <div className='fullLineRow' style={{width: '15%'}}>{item?.limiteMax !== null ? stdValue(item?.limiteMax) : ''}</div>
                <div className='fullLineRow' style={{width: '15%', textAlign: 'right'}}>{item?.unidade}</div>
            </div>
        </div> 
        )  

}

export default AlertsLine