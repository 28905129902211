import { getScreenData } from "../../BME/Unidades/Geral/getScreenData"

export async function getStdAnalisesCenarios(site){
    let screenData = getScreenData('resumoProd', '', '', site)
    let stdAnaliseCenarios = {}
    screenData.forEach(item => {
        stdAnaliseCenarios[item.sectionName] = item.props
    })
    console.log('stdAnalises', stdAnaliseCenarios)
    return stdAnaliseCenarios
}

// const stdAnaliseCenarios = {
//     'MOAGEM': ['flowCana', 'artCana', 'fibraCana', 'fibraEmbeb'],
//     'PRODUÇÃO AÇÚCAR': ['mixAcucar', 'flowAcucar', 'flowAcucarScsdia', 'flowAcucarScsTc', 'flowAcucarTondia', 'recupSJM'],
//     'PRODUÇÃO ETANOL': ['flowVolEtanolProd100Dia', 'flowEtanolDia', 'flowEtanolHidDia', 'flowEtanolNeutroDia', 'flowEtanolAnidroDia', 'flowEtanolTotal', 'efFermentacao', 'efDestilaria', 'rgd'],
//     'ENERGIA': ['expEnergia', 'consEspEnergiaTC', 'consEnergia', 'consEnergiaDia'],
//     'BAGAÇO': ['flowBagaco', 'umidBagaco', 'sobraBagaco'],
//     'PERFORMANCE': ['efIndustrial', 'ritSTAB', 'ritART', 'relProdAcucarArtEnt', 'artProdutos', 'consVaporTC', 'consAguaTC'],
//     'EVAPORAÇÃO / FÁBRICA': ['taxaEvapPreEvapAcucar', 'taxaEvap2EfEvapAcucar', 'taxaMediaEvapUltimosEf', 'ciclosMassaA', 'ciclosMassaB'],
//     'RECEITA BRUTA': ['receitaAcucar', 'receitaHidratado', 'receitaNeutro', 'receitaEnergia', 'receitaBagaco', 'receitaTotal']
//     }

// module.exports = {
//     stdAnaliseCenarios
// }

 