import React, {useContext, useState } from 'react';
import PSContext from '../opoio/PSContext';
import { LuFilePlus2 } from "react-icons/lu";
import MenuBtDivider from '../../components/ui/menuBtDivider';

export default function BtNewPS() {
  const {setIsNew, isNew, setIsSaveAs, isOpen, setIsEdit} = useContext(PSContext)

    const btClicked = () => {
      // setIsEdit(false)
      setIsSaveAs(false)
      setIsNew(!isNew)
    }

  if (isOpen){
    return (
      <div style={{display: 'flex', flexDirection: 'row'}}>
        <MenuBtDivider/>
          <button className='psButtom' onClick={btClicked}>
          <div className='psMenuButtom' >
      <LuFilePlus2  className='psMenuIcon'/>
      <div className='psMenuText'>NOVO</div></div>
          </button>
  </div>
    )
  }
}