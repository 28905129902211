import React, { useContext, useState, useEffect} from 'react'
import '../../css/planoSafra.css';
import GlobalStateContext from '../../components/GlobalStateContext'
import PSContext from '../opoio/PSContext';
import { UseColors } from '../../components/ui/colors';
import { getStdPlanoSafra } from '../../BME/Unidades/Geral/getStdPlanoSafra';
import Menu10YearPlan from './menuTenYearPlan';
import BodyTenYearPlan from './bodyTenYearPlan';
import ChartsTenYearPlan from './chartsTenYearPlan';
import psHooks from '../../Hooks/psHooks';

function TenYearPlan() {
  const {setMenu, setArea, site} = useContext(GlobalStateContext)
  const {showChart10year, setListSafras, setAllPsData, firstCrop, selectedValues} = useContext(PSContext)
  const {resumeAllPS, openPS} = psHooks()
  const [seqSafras, setSeqSafras] = useState(null)
  const [listRevs, setListRevs] = useState(null)
  const [stdList, setStdList] = useState(null)
  const stdPlanoVars = getStdPlanoSafra(site)

  const COLORS = UseColors()

  useEffect(() => {
    async function fetchData() {
      let grouped = await agruparData()
      console.log('Grpuped Data', grouped)
      setStdList(grouped)
    }
    fetchData()
  }, [selectedValues]);
  
  useEffect(() => {
    document.documentElement.style.setProperty('--primary-color', COLORS.primaryColor);
    setMenu('ps')
    setArea('plano10Anos')
  }, []);

  useEffect( () => {
    async function fetchData() {
      let allPs = await resumeAllPS(site)
      
      let tempListSafra = Object.keys(allPs)
      
      const listaOrdenada = tempListSafra.sort((a, b) => {
        const numA = parseInt(a.split('/')[0], 10);
        const numB = parseInt(b.split('/')[0], 10);
        return numA - numB;
      })
    
      setListSafras(listaOrdenada)
      setAllPsData(allPs)
      let tempList = {}
      Object.keys(allPs).forEach(safra => {
          tempList[safra] = []
          Object.keys(allPs[safra]).forEach(rev => {
              tempList[safra].push(rev)
          })
          tempList[safra] = orderRevs(tempList[safra])
      })
      setListRevs(tempList)
      setAllPsData(allPs)
    }
      fetchData();
  }, []);

  useEffect(() => {
    let seq = gerarSequencia(firstCrop, 10)
    setSeqSafras(seq)
  }, [firstCrop]);

  // useEffect(() => {
  //   if (allPsData){
  //       let temp = agruparData()
  //       setGroupData(temp)
  //       console.log('GroupData',temp)
  //   }
  // }, [allPsData]);

  function orderRevs(revs){
    const listaOrdenada = revs.sort((a, b) => {
      const numA = parseInt(a.split('_')[0], 10)
      const numB = parseInt(b.split('_')[0], 10)
      return numA - numB})
      return listaOrdenada
  }

  const gerarSequencia = (inicio, quantidade) => {
    const resultado = []
    let [ano1, ano2] = inicio.split('/').map(Number)
    for (let i = 0; i < quantidade; i++) {
      resultado.push(`${ano1}/${ano2}`)
      ano1++
      ano2++
    }
    return resultado
  }


  async function agruparData(){
        let tempG = []
        for (const safra of Object.keys(selectedValues)) {
          tempG[safra] = []
          let newItem = await getGroupedData(safra, selectedValues[safra])
          tempG[safra][selectedValues[safra]] = newItem
    }
    return tempG
  }

  async function getGroupedData(safra, revisao) {
    if (revisao === ''){      
      return null
    }else{
      let ssr = `${site}-${safra}-${revisao}`
      let ps = await openPS(ssr)
      
      const groupedItems = ps.reduce((acc, item) => {
        if (stdPlanoVars.filter(row => row.cod === item.codigo)[0]?.show !== false){
            if (!acc[item.secao]){
                acc[item.secao] = {}
            }
             acc[item.secao][item.codigo] = item.acu
        }
        return acc;
      }, {});
      return groupedItems
    }
  }

  return (
    <>
    <div>
        <Menu10YearPlan seqSafras={seqSafras} listRevs={listRevs} stdList={stdList}/>
        {!showChart10year ? 
        <BodyTenYearPlan seqSafras={seqSafras} listRevs={listRevs} stdList={stdList}/> :
        <ChartsTenYearPlan seqSafras={seqSafras} stdList={stdList}/>}
        </div>
    
    </>
  )
}

export default TenYearPlan



