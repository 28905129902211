import React, {useContext, useState, useEffect } from 'react';
import PSContext from '../opoio/PSContext';
import PsLineChart from '../charts/lineChart';
import ChartsSegControl from '../charts/chartSegment';
import GlobalStateContext from '../../components/GlobalStateContext';
import { UseColors } from '../../components/ui/colors';
import { getPsChartsData } from '../../BME/Unidades/Geral/getPsChartsData';

export default function SubMenuCharts({page = '', h = '30vh'}) {
const {site} = useContext(GlobalStateContext)
const [chartsData, setChartsData] = useState(null);
const {psData, selectedCharts, psIsPrinting} = useContext(PSContext)
  

  useEffect(() => {
    const fetchData = async () => {
        const result = await getPsChartsData(site);
        setChartsData(result);
    };

    fetchData();
}, []);

  const COLORS = UseColors()

  function getColor(n){
    switch(n){
        case '0-0': return COLORS.primaryColor
        case '0-1': return COLORS.secondaryColor
        case '1-0': return COLORS.terciaryColor
        case '1-1': return COLORS.fourColor
        case '2-0': return COLORS.fiveColor
        case '2-1': return COLORS.sixColor
        case '3-0': return COLORS.sevenColor
        case '3-1': return COLORS.eightColor
    }

  }

  return (
    <>
    {!psIsPrinting &&
    <ChartsSegControl/>
    }
     
     {psData && chartsData &&
    Object.keys(chartsData).map((item, index) => {
        if ((page === '' && selectedCharts === index) || (page !== '' && item === page)) {
            return(
                <div className='psBody' style={psIsPrinting ? {maxHeight: '150vh'} : {maxHeight: '80vh'}}>
                    {chartsData[item].map((item, index) => (
                        <div style={{display: 'flex', direction:'row', justifyContent: 'space-between', marginLeft: '1vh', marginRight: '1vh', marginBottom: '0.5vh'}}>
                           {Object.entries(item).map(cod => {
                            if (cod[1] !== ''){
                                return (<PsLineChart psData={psData} cod={cod[1]} color={getColor(`${index}-${cod[0]}`)} h={h}/>)
                                // return (<div>{`${index}-${cod[0]}`}</div>)
                            }else{
                                return (<div style={{height: '30vh', width: '100%', borderRadius: '2%',  margin: '1vh'}}></div>)
                            }      
        })}
                        </div>
                    ))
                    }
                </div>
            )
        }
    })}
    </>
  )
  
}