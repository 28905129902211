import React, {useContext, useEffect, useState, useRef } from 'react';
import PSContext from '../opoio/PSContext';
import { FaChartColumn, FaTableCells } from "react-icons/fa6";


export default function BtChartsTenYearPlan({disable}) {

    const {showChart10year, setShowChart10year} = useContext(PSContext)

    function handleBtCharts(){
        setShowChart10year(!showChart10year)
    }

    return (
        <div className='psMenuButtom'>
            <button className='psButtom' onClick={handleBtCharts} disabled={disable}>
                {showChart10year ? <FaTableCells  className='psMenuIcon' style={{scale: '0.95'}}/> :
                <FaChartColumn  className='psMenuIcon' style={{scale: '0.95'}}/>}
                <div className='psMenuText'>{showChart10year ? ' TABELA ' : 'GRÁFICOS'}</div>
            </button>
        </div>
    )
}