import React, {useContext} from 'react';
import PSContext from '../opoio/PSContext';
import { LuFileSpreadsheet } from "react-icons/lu";

export default function BtOpenPS() {
  const {setIsOpen, isOpen, isEdit, setIsSaveAs, setIsNew} = useContext(PSContext)

  const btClicked = () => {
    setIsNew(false)
    setIsSaveAs(false)
    setIsOpen(!isOpen)
  }

  if (!isEdit){
    return (
      <div style={{display: 'flex', flexDirection: 'row'}}>
          <button className='psButtom' onClick={btClicked}>
          <div className='psMenuButtom' >
      <LuFileSpreadsheet  className='psMenuIcon' />
      <div className='psMenuText'>ARQUIVOS</div></div>
          </button>
  </div>
    )
  }
}