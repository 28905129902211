import GlobalStateContext from '../../components/GlobalStateContext';
import { useContext } from 'react';
import { FaFileArrowDown } from "react-icons/fa6"
import { LuFileSearch } from "react-icons/lu";
import { UseColors } from '../../components/ui/colors';
import CryptoJS from 'crypto-js';
import { error } from 'jquery';
import AnalyticsHooks from '../../Hooks/analyticsHooks';

const FileUploader = () => {
  const {setData, data, user, site, setBmeFileName} = useContext(GlobalStateContext); 

  const COLORS = UseColors()
  const {newAction} = AnalyticsHooks()

    const handleFileChange = (event) => {
      newAction('abrirBME', site, user.email || '')
      const fileInput = document.getElementById('fileInput');
      console.log('Start importing');
      const file = event.target.files[0];
  
      if (file) {
          // Verifica a extensão do arquivo
          const fileExtension = file.name.split('.').pop();
          
          if (fileExtension === 'pms'+user.sites[site].sigla) {
              const reader = new FileReader();
              reader.onload = async (e) => {
                  // const jsonContent = e.target.result;
                  // const jsonData = JSON.parse(jsonContent);

                  const encryptedContent = e.target.result; // Conteúdo do arquivo criptografado
                  const jsonData = decryptData(encryptedContent); 
  
                  if (jsonData) {
                    // console.log('Dados descriptografados:', jsonData);

                    // Atualize seu estado ou banco de dados com os dados descriptografados
                    // await updateDB(jsonData);
                    updateData(jsonData)
                    global.lastFile = fileInput.value
                    const fileNameWithExtension = fileInput.value.split('\\').pop()
                    const fileName = fileNameWithExtension.split('.').slice(0, -1).join('.')
                    // console.log('File Name:', fileName)
                    setBmeFileName(fileName)
                    fileInput.value = '';
                  } else {
                      alert('Arquivo foi alterado ou não pertence à unidade atual e não pode ser aberto.');
                      fileInput.value = ''
                  }
              };
              reader.readAsText(file);
          } else {
              alert('Por favor, selecione um arquivo com a extensão .pms');
          }
      }
  };

  function updateData(importedData) {
    let updatedData = { ...data };
    for (const key in updatedData) {
      if (updatedData.hasOwnProperty(key) && importedData.hasOwnProperty(key)) {
        updatedData[key] = {
          ...updatedData[key],
          valor: importedData[key].valor,
          limiteMin: importedData[key].limiteMin ?? data[key].limiteMin,
          limiteMax: importedData[key].limiteMax ?? data[key].limiteMax,
          alerta: importedData[key].alerta ?? data[key].alerta,
          op1: importedData[key].op1 ?? data[key].op1,
          op2: importedData[key].op2 ?? data[key].op2,
          hide: importedData[key].hide ?? data[key].hide,
        };
      }
    }
    console.log('importData', data, updatedData, importedData)
    setData(updatedData);
  }
  
  
    const updateDB = async (db) => {
      try{
        const response  = await fetch(''+global.endPoint+'/'+user?.uid+'/updateDbFromFile', {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({data: db})
        })
        if (!response.ok) {
          throw new Error('Erro ao buscar dados da API');
        }
        const jsonData = await response.json();
        setData(jsonData);
      }catch (error){
        console.error('Erro ao Importar Arquivo:', error);
      }
  }

  function decryptData(encryptedData) {
    const secretKey = 'my-secret-key'+site; // A mesma chave secreta usada na criptografia
    try {
        const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
        const decryptedData = bytes.toString(CryptoJS.enc.Utf8);

        // Verifica se a descriptografia resultou em uma string válida JSON
        if (decryptedData) {
            return JSON.parse(decryptedData);
        } else {
            // alert('Arquivo não compatível com planta atual!')
            return null //throw new Error('Descriptografado: o conteúdo está vazio ou inválido.');
        }
    } catch (error) {
        console.error('Erro na descriptografia:', error.message);
        // alert('Erro ao abrir o arquivo: ' + error.message);
        // Aqui você pode decidir como lidar com o erro: retornar um valor padrão, lançar o erro novamente, etc.
        return null; // ou outro valor padrão que você queira usar
    }
}

  const styles = {
    menuButton: {
      position: 'relative',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '1.5vh',
      color: COLORS.primaryColor,
      height: '80%',
      alignSelf: 'center',
      width: '6vh',
      margin: '1.5vh',
      // border: '1px solid red'
    }
  };

    return (
      <div style={{display: 'flex', flexDirection: 'row', marginTop: '0.2vh'}} >
        <button className='psButtom'>
          <div className='psMenuButtom' >
              <label htmlFor="fileInput">
              <LuFileSearch  className='psMenuIcon'/>
              <div className='psMenuText'>ABRIR BME</div>
              </label>
          </div>
        </button>
      <input id="fileInput" type="file" accept={`.pms${user.sites[site].sigla}`} onChange={handleFileChange} style={{ display: 'none'}} />
    </div>
    );
  };
  
  export default FileUploader;

  


