import styles from './BarraSuperior.module.css'
import { getTitleFromArea } from '../../Funcoes/TextFuncs'
import React, { useContext } from 'react'
import GlobalStateContext from '../GlobalStateContext'
import MenuBME from '../../BME/menuResProd/MenuBME';
import { UseColors } from '../ui/colors';

function BarraSuperior() {
  const { area, user, menu, site } = useContext(GlobalStateContext);
  const COLORS = UseColors()

  return (
    <div className={styles.barra_Superior}>
      <div className={styles.menu_BarraSup}>
    <div style={{marginTop: '0.5vh'}}>
    {area !== 'resumoProd' && menu === 'bme' &&
    <MenuBME/>}
    </div>
      </div>
      <h2 className={styles.title_BarraSup}>{getTitleFromArea(area)}</h2>
      
      <div className={styles.logos_BarraSup}>
        <div style={{padding: '2vh', fontSize: '1.7vh', color: COLORS.primaryColor, fontWeight: 'bold'}}>
          <div>{user.userName}</div>
          <div>{user.sites[site].name}</div>
        </div>
        <img className={styles.logo_BarraSup} src="/logo512.png" alt="logo pms"/>
      </div>
    </div> 
  )
}

export default BarraSuperior

