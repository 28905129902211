import React, { useContext, useState } from 'react';
import GlobalStateContext from '../../components/GlobalStateContext';
import Modal from '../../components/BarraSuperior/Modal';
import { MdFileCopy } from "react-icons/md";
import { LuFiles } from "react-icons/lu";
import CryptoJS from 'crypto-js';
import AnalyticsHooks from '../../Hooks/analyticsHooks';

function FileSaveAs() {
    const { data, site, user, bmeFileName, setBmeFileName } = useContext(GlobalStateContext);
    const [isLoading, setIsLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [modalText, setModalText] = useState('...')

    const {newAction} = AnalyticsHooks()

    function encryptData(data) {
        const secretKey = 'my-secret-key'+site; // Chave secreta para criptografia (mantenha-a segura)
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
        return encryptedData;
    }
    
    async function exportToJSON(db) {
        newAction('salvarComoBME', site, user.email || '')
        const encryptedData = encryptData(db);
        // console.log('Data', data)
        const blob = new Blob([encryptedData], { type: 'application/plain' });
        // const jsonData = JSON.stringify(db);
        // const blob = new Blob([db], { type: 'application/json' });
    
        try {
            const fileHandle = await window.showSaveFilePicker({
                suggestedName:  `${bmeFileName === '' ? 'bmeName' : bmeFileName}.pms${user.sites[site].sigla}`,
                types: [
                    {
                        description: 'PMS Files',
                        accept: { 'application/json': ['.pms'+user.sites[site].sigla] },
                    },
                ],
            });

            
            setBmeFileName(fileHandle.name.split('.').slice(0, -1).join('.'))
            const writableStream = await fileHandle.createWritable();
            await writableStream.write(blob);
            await writableStream.close();
        } catch (error) {
            console.error('Erro ao salvar o arquivo:', error);
        }
    }

    const handleExport = () => {
    //   let temp = {}
    //   Object.keys(data).forEach(key => {
    //     temp[key] = {
    //         valor: data[key].valor,
    //         limiteMin: data[key].limiteMin,
    //         limiteMax: data[key].limiteMax,
    //         valorDefault: data[key].valorDefault
    //     }
    //   })
    //   console.log('Temp to save', temp, data)
      exportToJSON(data)
    }

    return (

        <div style={{display: 'flex', flexDirection: 'row', marginTop: '0.25vh'}}>
          <button className='psButtom' onClick={handleExport}>
          <div className='psMenuButtom' >
          <LuFiles  className='psMenuIcon'/>
          <div className='psMenuText'>SALVAR COMO</div></div>
          </button>
          <Modal show={isLoading} progress={progress} texto={modalText}/>
        </div>

        // <div>
        //     <button onClick={handleExport}>Exportar para JSON</button>
        // </div>
    );
}

export default FileSaveAs;
