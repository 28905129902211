import { useContext, useState } from "react"
import GlobalStateContext from "../../GlobalStateContext"
import { stdValue } from "../../../Resources/setNumber"

function LimitsLine({item}) {
    const {data, setData, user, site} = useContext(GlobalStateContext)
    const [desc, setDesc] = useState(item?.descricao)
    const [def, setDef] = useState(item?.valorDefault)
    const [valor, setValor] = useState(item?.valor)
    const [max, setMax] = useState(item?.limiteMax)
    const [min, setMin] = useState(item?.limiteMin)
    const [alerta, setAlerta] = useState(item?.alerta)

    const handleDesc = (e) =>{setDesc(e.target.value)}
    const handleValue = (e) =>{setValor(e.target.value)}
    const handleDef = (e) =>{setDef(e.target.value)}
    const handleMin = (e) =>{setMin(e.target.value)}
    const handleMax = (e) =>{setMax(e.target.value)}
    const handleAlert = (e) =>{setAlerta(e.target.value)}

    const changedDesc = async(e) =>{
      let cod = e.target.getAttribute('cod')
      setData(prevData => ({
        ...prevData,
        [cod]: {
          ...prevData[cod],
          descricao: e.target.value,
        },
      }))
      let newValues = {"codigo": cod, "descricao": e.target.value}
      await updateParam(newValues)
    }

    const changedDef = async(e) =>{
      let cod = e.target.getAttribute('cod')
      setData(prevData => ({
        ...prevData,
        [cod]: {
          ...prevData[cod],
          valorDefault: parseFloat(e.target.value),
        },
      }))
      let newValues = {"codigo": cod, "valorDefault": parseFloat(e.target.value)}
      await updateParam(newValues)
    }

    const changedValue = async(e) =>{
      await updateValor(e.target.getAttribute('cod'), e.target.value)
    }

    const changedMin = async(e) =>{
      let cod = e.target.getAttribute('cod')
        let newValues = {"codigo": cod, "limiteMin": e.target.value === '' ? null : parseFloat(e.target.value)}
        setData(prevData => ({
          ...prevData,
          [cod]: {
            ...prevData[cod],
            limiteMin: parseFloat(e.target.value),
          },
        }))
        await updateParam(newValues)
    }

    const changedMax = async(e) =>{
      let cod = e.target.getAttribute('cod')
      setData(prevData => ({
        ...prevData,
        [cod]: {
          ...prevData[cod],
          limiteMax: parseFloat(e.target.value),
        },
      }))
        let newValues = {"codigo": cod, "limiteMax": e.target.value === '' ? null : parseFloat(e.target.value)}
        await updateParam(newValues)
     }

     const changedAlert = async(e) =>{
      let cod = e.target.getAttribute('cod')
      setData(prevData => ({
        ...prevData,
        [cod]: {
          ...prevData[cod],
          alerta: e.target.value,
        },
      }))
        let newValues = {"codigo": cod, "alerta": e.target.value === ''? null : e.target.value}
        await updateParam(newValues)
     }

     const updateValor = async (cod, value) => {
      // console.log('UpdateValue', cod, value)
      //   await fetch(''+global.endPoint+'/'+user?.uid+'/setValue', {
      //   method: 'PUT',
      //   headers: {
      //     'Content-Type': 'application/json'
      //   },
      //   body: JSON.stringify({codigo: cod, newValue: parseFloat(value)})
      // })
      // .then(response => {
      //   if (!response.ok) {
      //     throw new Error('Erro de requisição.');
      //   }else{
      //     data[cod].valor = parseFloat(value)
      //   }
      // })
      // .catch(error => {
      //   // Lida com o erro da requisição
      // })
    }

    const updateParam = async (newValues) => {
    //   console.log('NewValues', newValues)
    //   await fetch(''+global.endPoint+'/'+site+'/setDbParams', {
    //   method: 'PUT',
    //   headers: {
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({newValue: newValues})
    // })
    // .then(response => {
    //   if (!response.ok) {
    //     throw new Error('Erro de requisição.');
    //   }else{
    //     let cod = newValues.codigo
    //     newValues.limiteMin = data[cod].limiteMin //?? newValues.limiteMin
    //     newValues.limiteMax = data[cod].limiteMax //?? newValues.limiteMax
    //     newValues.valorDefault = data[cod].valorDefault ?? newValues.valorDefault
    //     newValues.descricao = data[cod].descricao ?? newValues.descricao
    //     newValues.alerta = data[cod].alerta //?? newValues.alerta
    //     newValues.unidade = data[cod].unidade ?? newValues.unidade
    //   }
    // })
    // .catch(error => {
    //   // Lida com o erro da requisição
    // })
  }

    return (
        <div style={{marginLeft: '2vh', marginRight: '2vh', backgroundColor: 'white', borderBottom: '1px solid #f2f2f7'}}>
            <div className='fullLine'>
              {user?.profile === 'developer' ?
              <div className='fullLineRow' style={{width: '30%'}}>
              <input style={{width: '90%', fontSize: '1.6vh', padding: '0.3vh'}} 
              id={`desc-${item?.codigo}`} cod={item?.codigo} type="text" value={desc}
              onChange={handleDesc} onBlur={changedDesc}/> 
          </div> : 
          <div className='fullLineRow' style={{width: '30%'}}>{desc}</div>}
                {user?.profile === 'developer' &&
                <div className='fullLineRow' style={{width: '20%'}}>{item?.codigo}</div>}
                <div className='fullLineRow' style={{width: '15%'}}>
                    {item?.input ? 
                    <input style={{width: '90%', fontSize: '1.6vh', padding: '0.3vh'}} 
                    id={`value-${item?.codigo}`} cod={item?.codigo} type="text" value={valor}
                    onChange={handleValue} onBlur={changedValue} /> :
                    stdValue(item?.valor)
                    }
                </div>
                {user?.profile === 'developer' &&
                <div className='fullLineRow' style={{width: '14%'}}>
                    <input style={{width: '90%', fontSize: '1.6vh', padding: '0.3vh'}} 
                    id={`def-${item?.codigo}`} cod={item?.codigo} type="text" value={def !== null ? def : ''}
                    onChange={handleDef} onBlur={changedDef}/>
                </div>}
                <div className='fullLineRow' style={{width: '14%'}}>
                    <input style={{width: '90%', fontSize: '1.6vh', padding: '0.3vh'}} 
                    id={`min-${item?.codigo}`} cod={item?.codigo} type="text" value={min !== null ? min : ''}
                    onChange={handleMin} onBlur={changedMin}/>
                </div>
                <div className='fullLineRow' style={{width: '14%'}}>
                    <input style={{width: '90%', fontSize: '1.6vh', padding: '0.3vh'}} 
                    id={`max-${item?.codigo}`} cod={item?.codigo} type="text" value={max !== null ? max : ''}
                    onChange={handleMax} onBlur={changedMax}/> 
                </div>
                <div className='fullLineRow' style={{width: '14%'}}>
                    <input style={{width: '90%', fontSize: '1.6vh', padding: '0.3vh'}} 
                    id={`alert-${item?.codigo}`} cod={item?.codigo} type="text" value={alerta !== null ? alerta : ''}
                    onChange={handleAlert} onBlur={changedAlert}/> 
                </div>
                {user?.profile === 'developer' ?
                <div className='fullLineRow' style={{width: '10%', textAlign: 'right'}}>
                    <input style={{width: '90%', fontSize: '1.6vh', padding: '0.3vh', textAlign: 'right'}} id={item?.codigo} type="text" value={item?.unidade}/> 
                </div> : 
                <div className='fullLineRow' style={{width: '10%', textAlign: 'right'}}>
                <input style={{width: '90%', fontSize: '1.6vh', padding: '0.3vh', textAlign: 'right', border: '0', backgroundColor: 'white'}} id={item?.codigo} type="text" value={item?.unidade} disabled={true}/> 
            </div>
}
            </div>
        </div> 
        )  
}

export default LimitsLine