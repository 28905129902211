import React, { useContext, useState, useRef, useEffect} from 'react'

import '../../css/planoSafra.css';
import { ACContext } from './AnaliseCenariosContext';
import { UseColors } from '../../components/ui/colors';
import { getStdAnalisesCenarios } from './stdAnaliseCenarios';
import { stdValue } from '../../Resources/setNumber';
import { MdDeleteForever } from "react-icons/md";
import { FaArrowLeft } from "react-icons/fa6";
import { FaArrowRight } from "react-icons/fa6";
import GlobalStateContext from '../../components/GlobalStateContext';
import Headlandscape from '../../components/ui/HeadLandscape';


function BodyAnaliseCenarios({
    secs = {'MOAGEM':[0,100], 'PRODUÇÃO AÇÚCAR':[0,100], 'PRODUÇÃO ETANOL':[0,100], 'ENERGIA':[0,100], 
        'BAGAÇO':[0,100], 'PERFORMANCE':[0,100], 'EVAPORAÇÃO / FÁBRICA':[0,100], 'RECEITA BRUTA':[0,100]}
}) {
    const {AcData, setAcData, isEditing, isPrinting} = useContext(ACContext)
    const [stdAnaliseCenarios, setStdAnaliseCenarios] = useState(null)
    const {site} = useContext(GlobalStateContext)

  useEffect(() => {
    document.documentElement.style.setProperty('--primary-color', COLORS.primaryColor);
    const stdData = async () =>{
        let std = await getStdAnalisesCenarios(site)
        setStdAnaliseCenarios(std)
    }
    stdData()
  }, []);

  const COLORS = UseColors()

  // Função para remover um item da lista
  const removeItem = (index) => {
    const newItems = [...AcData];
    newItems.splice(index, 1);
    setAcData(newItems);
  };

  // Função para reordenar itens
  const moveItem = (fromIndex, toIndex) => {
    const newItems = [...AcData];
    const [movedItem] = newItems.splice(fromIndex, 1);
    newItems.splice(toIndex, 0, movedItem);
    setAcData(newItems);
  };

//   const pages = [
//     {'MOAGEM':[0,100], 'PRODUÇÃO AÇÚCAR':[0,100], 'PRODUÇÃO ETANOL':[0,100]},
//     {'ENERGIA':[0,100], 'BAGAÇO':[0,100], 'PERFORMANCE':[0,100]},
//     {'EVAPORAÇÃO / FÁBRICA':[0,100], 'RECEITA BRUTA':[0,100]},
// ]

  return (
    <>
    {/* <Headlandscape title='ANÁLISE DE CENÁRIOS' 
                            subtitle='' 
                            fileName={'fileanem and'}
                            nomeField='' page={`${1}`} pages={`${pages.length}`}/> */}
     <div style={{marginTop: '1.5vh'}}>
    <div>
        {AcData &&  <div className='psTableHead' style={isEditing ? {paddingRight: '2vh', height: '6.5vh', paddingTop: '0'} : {paddingTop: '1.5vh', paddingBottom: '1.5vh'}}>
            <div style={{width: '20%', textAlign: 'left'}}>Descrição</div>
            <div style={{width: '5%', textAlign: 'left'}}>Un.</div>
            {Array.from({ length: 9 }, (_, index) => index + 1).map((_, index) => {
                return(
                    <div className='psHeadElement'  style={{width: '8.33%', paddingLeft: '0.8vh', paddingRight: '0.8vh'}} key={index}>
                        {isEditing ?
                        <div style={{display: 'flex', flexDirection: 'column', height: '6vh'}}>
                            
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                        {AcData[index] && <button onClick={() => moveItem(index, index - 1)} disabled={index === 0} style={{backgroundColor: 'rgb(0,0,0,0)', border: '0', padding: '0', margin: '0'}}>
                                <FaArrowLeft style={{color: 'white', width: '2vh', height: '2vh', opacity: `${index === 0 ? '0.5' : '1.0'}`}}/>
                                </button>}
                            {AcData[index] && <button onClick={() => removeItem(index)} style={{backgroundColor: 'rgb(0,0,0,0)', border: '0', padding: '0'}}>
                            <MdDeleteForever style={{color: 'white',width: '2.5vh', height: '2.5vh'}}/>
                            </button>}
                            {AcData[index] &&  <button onClick={() => moveItem(index, index + 1)} disabled={index === AcData.length - 1} style={{backgroundColor: 'rgb(0,0,0,0)', border: '0', padding: '0', margin: '0'}}>
                                <FaArrowRight style={{color: 'white', width: '2vh', height: '2vh', opacity: `${index === AcData.length - 1 ? '0.5' : '1.0'}`}}/>
                                </button>}
                        </div>
                    <div style={{alignContent: 'center', height: '4vh'}}>{AcData[index] ? Object.keys(AcData[index]) : ''}</div></div> :
                    <div>{AcData[index] ? Object.keys(AcData[index]) : ''}</div>}
                        
                    </div>
                )
            })}
        </div>}
        </div>
       {AcData && AcData.length > 0 && 
        <div className='psBody' style={{maxHeight: '80vh'}}>
        {stdAnaliseCenarios &&
        Object.keys(stdAnaliseCenarios)
        .filter(key => Object.keys(secs).includes(key))
        .map(item => {
            return(
                <>
                <div className='psSectionHead' key={item}>{item}</div>
                {stdAnaliseCenarios[item]
                
                .map((cod, index) => {
                    if (secs[item][0] <= index  && secs[item][1] >= index && AcData[0][Object.keys(AcData[0])][cod]){
                        return(
                            <div className={`psLine ${index%2 === 0 && "psLineImp"}`}
                            key={`${item}-${cod}-${index}`}>
                                <div style={{width: '20%', textAlign: 'left'}} key={`desc-${cod}`}>{AcData[0][Object.keys(AcData[0])][cod].descricao}</div>
                                <div style={{width: '5%', textAlign: 'left'}} key={`unit-${cod}`}>{AcData[0][Object.keys(AcData[0])][cod].unidade}</div>
                                {Array.from({ length: 9 }, (_, index) => index + 1).map((_, index) => {
                                return(
                                    <div  style={{width: '8.33%', maxWidth: '8.33%'}} key={`${AcData[index] && Object.keys(AcData[index])}-value-${cod}-${index}`}>
                                        {AcData[index] ? stdValue(AcData[index][Object.keys(AcData[index])][cod].valor) : ''}
                                    </div>
                                )
                            })}
                            </div>
                        )
                    }
                   
                })}
                </>
            )
        })
        }
         <div style={{height: '30px'}}></div>
       
    </div>}
   
    
    </div>
    </>
   
  )
}

export default BodyAnaliseCenarios
