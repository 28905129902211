import React, { useEffect, useState } from 'react'
import './../../css/Listas.css'
import ListLine from './ListLine'
import { getScreenData } from '../../BME/Unidades/Geral/getScreenData'
import GlobalStateContext from '../GlobalStateContext'
import { useContext } from 'react'

function Listas() {
  const {data, area, screen, isPrinting, site } = useContext(GlobalStateContext)
  const [cod, setChangedCod] = useState('');
  const [screenData, setScreenData] = useState(null)

  useEffect( () => {
    // console.log('getScreenData', area, screen, data, site)
    let temp =  getScreenData(area, screen, data, site)
    if (area === 'resumoProd'){
      // console.log('getScreenData', temp)
      temp = temp.filter(item => item.sectionName !== 'RECEITA BRUTA')
    }
    setScreenData(temp)
  }, [data, screen, area])

  const handleCountChange = (c) => {
    console.log('Count mudou:', c);
    if (c === cod){
      setChangedCod(''+c+'1')
    }else{
      setChangedCod(c)
    }
  }

  const vh = window.innerHeight
  const ideal = 727
  let nVH = isPrinting ? ideal/vh : 1

  const styles = {
    lista: {
      marginTop: `${1*nVH}vh`,
    },
    coluna: {
      minWidth: `${45*nVH}vh`,
      maxWidth: `${85*nVH}vh`,
    },
    sectionBox: {
      marginBottom: `${3*nVH}vh`},
    subSection: {
        fontSize: `${1.6*nVH}vh`,
        marginLeft: `${1.5*nVH}vh`,
        marginBottom: `${0.3*nVH}vh`,
        marginTop: `${-2*nVH}vh`,
    },
    section: {
      fontSize: `${1.6*nVH}vh`,
      marginBottom: `${1*nVH}vh`,
      marginTop: `${2*nVH}vh`,
  }
  }

  return (
    <div className="lista" style={styles.lista}>
      <div className="colunas">
        <div className="coluna" style={styles.coluna}>
          {screenData &&
            screenData.map((item, idx) => {
              if (item.col === 1) {
                return (
                  <div key={`col1-item-${idx}`}>
                    {item.subSection ? (
                      <section key={`col1-sec-${idx}`} style={{ marginBottom: `${3 * nVH}vh` }}>
                        {item.sectionName}
                      </section>
                    ) : (
                      <section key={`col1-sec-${idx}`} style={styles.section}>
                        {item.sectionName}
                      </section>
                    )}
                    {item.subSection && (
                      <div className="subSection" key={`col1-subSec-${idx}`} style={styles.subSection}>
                        {item.subSection}
                      </div>
                    )}
                    <div className="sectionBox" key={`col1-box-${idx}`} style={styles.sectionBox}>
                      {item.props.map((cod, index) => {
                        if (cod !== "") {
                          return (
                            <ListLine
                              key={`col1-listline-${idx}-${index}-${cod}`} 
                              ipt={item.ipt[index]}
                              cod={cod}
                              desc={item.desc ? item.desc[index] : ''}
                              handleCountChange={handleCountChange}
                            />
                          );
                        } else {
                          return null; // Retorna null ao invés de um fragmento vazio
                        }
                      })}
                    </div>
                  </div>
                );
              } else {
                return null;
              }
            })}
          <div style={{ height: "300px" }}></div>
        </div>
  
        <div className="coluna" style={styles.coluna}>
          {screenData &&
            screenData.map((item, idx) => {
              if (item.col === 2) {
                return (
                  <div key={`col2-item-${idx}`}>
                    {item.subSection ? (
                      <section key={`col2-sec-${idx}`} style={{ marginBottom: `${3 * nVH}vh` }}>
                        {item.sectionName}
                      </section>
                    ) : (
                      <section key={`col2-sec-${idx}`} style={styles.section}>
                        {item.sectionName}
                      </section>
                    )}
                    {item.subSection && (
                      <div className="subSection" key={`col2-subSec-${idx}`} style={styles.subSection}>
                        {item.subSection}
                      </div>
                    )}
                    <div className="sectionBox" key={`col2-box-${idx}`} style={styles.sectionBox}>
                      {item.props.map((cod, index) => {
                        if (cod !== "") {
                          return (
                            <ListLine
                              key={`col2-listline-${idx}-${index}-${cod}`} 
                              ipt={item.ipt[index]}
                              cod={cod}
                              desc={item.desc ? item.desc[index] : ''}
                              handleCountChange={handleCountChange}
                            />
                          );
                        } else {
                          return null; // Retorna null ao invés de um fragmento vazio
                        }
                      })}
                    </div>
                  </div>
                );
              } else {
                return null;
              }
            })}
        </div>
      </div>
    </div>
  );
  
}

export default Listas
  