import React, { createContext, useState, useEffect, useContext } from 'react';
import GlobalStateContext from '../../components/GlobalStateContext';
import LZString from 'lz-string'

const ACContext = createContext();

const ACProvider = ({ children }) => {
  const {site} = useContext(GlobalStateContext)

  const [AcData, setAcData] = useState(() => {
    const savedAcData = LZString.decompress(localStorage.getItem(`AcData-${site}`))
    // const savedAcData = localStorage.getItem(`AcData-${site}`);
    // console.log('savedAcData', JSON.parse(savedAcData), site)
    return savedAcData ? JSON.parse(savedAcData) : [];
  });
  const [isEditing, setIsEditing] = useState(false)
  const [isPrinting, setIsPrinting] = useState(false)
  const [fileName, setFileName] = useState('fileName')

  useEffect(() => {
    // localStorage.removeItem(`AcData-null`)
    // const currentSize = new Blob(Object.values(localStorage)).size
    // console.log('currentSize', currentSize, getLocalStorageSize())
      const compressedData = LZString.compress(JSON.stringify(AcData));
      localStorage.setItem(`AcData-${site}`, compressedData);
  }, [AcData]);

  function getLocalStorageSize() {
    let total = 0;
  
    // Itera sobre todas as chaves do localStorage
    for (let key in localStorage) {
      if (localStorage.hasOwnProperty(key)) {
        // Soma o tamanho da chave e do valor
        let itemSize = ((key.length + localStorage.getItem(key).length) * 2); // Multiplica por 2 porque cada caractere é 2 bytes
        console.log('Item', key, itemSize)
        total += itemSize;
      }
    }
  
    return total; // Retorna o total em bytes
  }

  useEffect(() => {
    const savedAcData = LZString.decompress(localStorage.getItem(`AcData-${site}`))
    // console.log('savedAcData use effect', JSON.parse(savedAcData, site)
    setAcData(savedAcData ? JSON.parse(savedAcData) : [])
    
  }, [site]);

  return (
    <ACContext.Provider value={{ AcData, setAcData, isEditing, setIsEditing, isPrinting, setIsPrinting,
      fileName, setFileName
    }}>
      {children}
    </ACContext.Provider>
  );
};

export { ACContext, ACProvider };


