// GlobalStateContext.js
import React, { createContext, useState, useEffect } from 'react';
import { getDefData } from '../BME/Unidades/Geral/getDefData';
import LZString from 'lz-string'

// Criando o contexto
export const GlobalStateContext = createContext();

// Provedor de contexto
export const GlobalStateProvider = ({ children }) => {
  const [isAlertOn, setAlert] = useState(true);
  const [site, setSite] = useState(null);
  const [data, setData] = useState(null);

  const [area, setArea] = useState('resumoProd');
  const [menu, setMenu] = useState('bme');
  const [screen, setScreen] = useState('fluxos');
  const [listAlerts, setlistAlerts] = useState(null);
  const [user, setUser] = useState(null);
  
  const [isPrinting, setPrinting] = useState(false);
  const [bmeFileName, setBmeFileName] = useState('');
  const [showUpdating, setShowUpdating] = useState(false);

  useEffect(() => {
    // console.log(`set BmeData${site && site}`)
    const compressedData = LZString.compress(JSON.stringify(data));
    localStorage.setItem(`BmeData${site && site}`, compressedData);
    // console.log('Local Data Change', data, site)
  }, [data]);

  // useEffect(() => {
  //   const savedBmeData = localStorage.getItem(`BmeData${site}`);
  //   let result = savedBmeData ? JSON.parse(savedBmeData) : getDefData(site);
  //   console.log('BME DAta', result, site)
  // }, [site]);

  return (
    <GlobalStateContext.Provider value={{ 
      isAlertOn, setAlert, 
      data, setData,
      area, setArea,
      menu, setMenu,
      screen, setScreen, 
      listAlerts, setlistAlerts, 
      user, setUser, site, setSite, bmeFileName, setBmeFileName,
      isPrinting, setPrinting, showUpdating, setShowUpdating}}>
      {children}
    </GlobalStateContext.Provider>
  )
}

export default GlobalStateContext;