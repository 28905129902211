import React, { useState, useContext } from 'react';
import BtAdicionarCenario from './BtAdicionarCenario';
import MenuBtDivider from '../../components/ui/menuBtDivider';
import BtImportCenario from './BtImportCenario';
import BtEditCenario from './BtEditCenario';
import BtCleanCenario from './BtCleanCenarios';
import BtExportCenariosToPDF from './BtExportCenariosToPDF';
import { ACContext } from './AnaliseCenariosContext';
// import ImportBulletin from './Botoes/ImportBulletin'
// import MenuBtDivider from '../../components/ui/menuBtDivider';
// import BtDayAnalyzer from './Botoes/btDayAnalyzer';

const MenuAnaliseCenarios = ({value}) => {
    const {fileName, setFileName} = useContext(ACContext)

    const handleIpt = (e) => {
        setFileName(e.target.value)
      }
      const updateValue = async (e) => {
        setFileName(e.target.value)
      }

  return (
    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginLeft: '2vh', marginBottom: '-0.6vh', marginTop: '-0.4vh'}}>
            <div className="psMainMenu">
    <BtAdicionarCenario value={value}/>
    <MenuBtDivider/>
    <BtImportCenario/>
    <MenuBtDivider/>
    <BtEditCenario/>
    <MenuBtDivider/>
    <BtCleanCenario/>
    <MenuBtDivider/>
    <BtExportCenariosToPDF/>
    
  
    </div>
   <div style={{alignContent: 'center', marginRight: '3vh'}}>
   <input type="text" value={fileName} onChange={handleIpt} onBlur={updateValue}/>
   </div>
    </div>
  )
}

export default MenuAnaliseCenarios