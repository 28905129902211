import React, { useRef, useState, useContext } from 'react';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import Modal from '../../components/BarraSuperior/Modal';
import Listas from '../../components/Conteudo/Listas'
import GlobalStateContext from '../../components/GlobalStateContext';
import { FaRegFilePdf } from "react-icons/fa6";
import Fluxograma from '../../components/Conteudo/Fluxograma';
import Headlandscape from '../../components/ui/HeadLandscape';
import AnalyticsHooks from '../../Hooks/analyticsHooks';

const ExportPDF = ({fileName = 'fileName'}) => {
  const divRefs = useRef([]);
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [modalText, setModalText] = useState('...');
  const {site, user, setPrinting, bmeFileName } = useContext(GlobalStateContext)
  const {newAction} = AnalyticsHooks()

  const addToRefs = (el) => {

    if (el && !divRefs.current.includes(el)) {
      divRefs.current.push(el);
    }
  };

  async function exportPDF(){
    newAction('expPDFBME', site, user.email || '')
    setPrinting(true)
    setIsLoading(true)
    setProgress(0)
    setModalText('Gerando PDF...')
    let progressValue = 0;
    const interval = setInterval(() => {
      progressValue += 1;
      if (progressValue <= 100) {
        setProgress(progressValue)
      } else {
        clearInterval(interval)
        setIsLoading(false)
        setPrinting(false)
      }
    }, 20);
   
    const pdf = new jsPDF('p', 'mm', 'a4', true);
    
    let promiseChain = Promise.resolve();

    divRefs.current.forEach((div, index) => {
      setProgress(divRefs.current.length/index)
      promiseChain = promiseChain.then(async () => {
        const canvas = await html2canvas(div);
        const imgData = canvas.toDataURL('image/png');
        const imgWidth = 277; // largura em mm para A4
        const imgHeight = 190; //(canvas.height * imgWidth) / canvas.width;
        if (index > 0) {
          pdf.addPage("a4", "l");
          // imgWidth = 190;
          // imgHeight = 277;
          pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight);
        }else{
          pdf.addImage(imgData, 'PNG', 10, 10, 190, 277);
        }
        
      });
    });

    promiseChain.then(() => {
      pdf.save(`${bmeFileName || `bme-${site}-${new Date().toLocaleString()}`}.pdf`);
    }).catch(err => {
      console.error('Erro ao gerar PDF:', err);
    });
  
  };

  const vh = window.innerHeight
  const ideal = 727
  let nVH = 1//ideal/vh

  return (
    <div>

<div style={{ position: 'absolute', left: '-9999px' }}>
        <div ref={addToRefs} style={{margin: '10px', backgroundColor: '#ffffff', position: 'relative', border: '1px solid black'}}>
            <Headlandscape title='RESUMO PRODUÇÃO'  fileName={bmeFileName} page='1' pages='10'/>
            <div style={{ width: '100%',  marginTop: '5px', marginBottom: '300px', position: 'relative', lineHeight: '1.0'}}>
                <Listas/>
             </div>
      </div>
    
      <div ref={addToRefs} style={{margin: '10px', backgroundColor: '#ffffff', height: '794px', position: 'relative', border: '1px solid black'}}>
            <Headlandscape title='EXTRAÇÃO' subtitle='Fluxograma' fileName={bmeFileName} page='2' pages='10'/>
            <div style={{height: '630px', width: '1000px', marginLeft: '30px', marginRight: '40px', marginTop: '20px', position: 'relative', scale: `${1.05 * nVH}`,  lineHeight: `${1 / nVH}`}}>
                <Fluxograma f={nVH}/>
             </div>
      </div>

      <div ref={addToRefs} style={{margin: '10px', backgroundColor: '#ffffff', height: '794px', position: 'relative', border: '1px solid black'}}>
            <Headlandscape title='TRATAMENTO FÁBRICA' subtitle='Fluxograma' fileName={bmeFileName} page='3' pages='10'/>
            <div style={{height: '630px', width: '1000px', marginLeft: '30px', marginRight: '40px', marginTop: '20px', position: 'relative', scale: `${1.05 * nVH}`,  lineHeight: `${1 * nVH > 1.5 ? 1.5 : 1 * nVH}` }}>
            <Fluxograma a={'tratAcucar'} f={nVH}/>
             </div>
      </div>

      <div ref={addToRefs} style={{margin: '10px', backgroundColor: '#ffffff', height: '794px', position: 'relative', border: '1px solid black'}}>
            <Headlandscape title='TRATAMENTO DESTILARIA' subtitle='Fluxograma' fileName={bmeFileName} page='4' pages='10'/>
            <div style={{height: '630px', width: '1000px', marginLeft: '30px', marginRight: '40px', marginTop: '20px', position: 'relative', scale: `${1.05 * nVH}`,  lineHeight: `${1 * nVH}` }}>
              <Fluxograma a={'tratEtanol'} f={nVH}/>
             </div>
      </div>

      <div ref={addToRefs} style={{margin: '10px', backgroundColor: '#ffffff', height: '794px', position: 'relative', border: '1px solid black'}}>
            <Headlandscape title='FILTRAÇÃO LODO' subtitle='Fluxograma' fileName={bmeFileName} page='5' pages='10'/>
            <div style={{height: '630px', width: '1000px', marginLeft: '30px', marginRight: '40px', marginTop: '20px', position: 'relative', scale: `${1.05 * nVH}`,  lineHeight: `${1 * nVH}` }}>
              <Fluxograma a={'filtLodo'} f={nVH}/>
             </div>
      </div>

      <div ref={addToRefs} style={{margin: '10px', backgroundColor: '#ffffff', height: '794px', position: 'relative', border: '1px solid black'}}>
            <Headlandscape title='EVAPORAÇÃO' subtitle='Fluxograma' fileName={bmeFileName} page='6' pages='10'/>
            <div style={{height: '630px', width: '1000px', marginLeft: '30px', marginRight: '40px', marginTop: '20px', position: 'relative', scale: `${1.05 * nVH}`,  lineHeight: `${1 * nVH}` }}>
              <Fluxograma a={'evaporacao'} f={nVH}/>
             </div>
      </div>

      <div ref={addToRefs} style={{margin: '10px', backgroundColor: '#ffffff', height: '794px', position: 'relative', border: '1px solid black'}}>
            <Headlandscape title='FERMENTAÇÃO' subtitle='Fluxograma' fileName={bmeFileName} page='7' pages='10'/>
            <div style={{height: '630px', width: '1000px', marginLeft: '30px', marginRight: '40px', marginTop: '20px', position: 'relative', scale: `${1.05 * nVH}`,  lineHeight: `${1 * nVH}` }}>
              <Fluxograma a={'fermentacao'} f={nVH}/>
             </div>
      </div>

      <div ref={addToRefs} style={{margin: '10px', backgroundColor: '#ffffff', height: '794px', position: 'relative', border: '1px solid black'}}>
            <Headlandscape title='DESTILARIA' subtitle='Fluxograma' fileName={bmeFileName} page='8' pages='10'/>
            <div style={{height: '630px', width: '1000px', marginLeft: '30px', marginRight: '40px', marginTop: '20px', position: 'relative', scale: `${1.05 * nVH}`,  lineHeight: `${1 * nVH}` }}>
              <Fluxograma a={'destilaria'} f={nVH}/>
             </div>
      </div>

      <div ref={addToRefs} style={{margin: '10px', backgroundColor: '#ffffff', height: '794px', position: 'relative', border: '1px solid black'}}>
            <Headlandscape title='FÁBRICA DE AÇÚCAR' subtitle='Fluxograma' fileName={bmeFileName} page='9' pages='10'/>
            <div style={{height: '630px', width: '1000px', marginLeft: '30px', marginRight: '40px', marginTop: '20px', position: 'relative', scale: `${1.05 * nVH}`,  lineHeight: `${1 * nVH}` }}>
              <Fluxograma a={'fabrica'} f={nVH}/>
             </div>
      </div>

      <div ref={addToRefs} style={{margin: '10px', backgroundColor: '#ffffff', height: '794px', position: 'relative', border: '1px solid black'}}>
            <Headlandscape title='VAPOR E ENERGIA' subtitle='Fluxograma' fileName={bmeFileName} page='10' pages='10'/>
            <div style={{height: '630px', width: '1000px', marginLeft: '30px', marginRight: '40px', marginTop: '20px', position: 'relative', scale: `${1.05 * nVH}`,  lineHeight: `${1 * nVH}` }}>
              <Fluxograma a={'vapor'} f={nVH}/>
             </div>
      </div>
      <div ref={addToRefs} style={{margin: '10px', backgroundColor: '#ffffff', height: '794px', position: 'relative', border: '1px solid black'}}>
            <Headlandscape title='BALANÇO HÍDRICO' subtitle='Fluxograma' fileName={bmeFileName} page='10' pages='10'/>
            <div style={{height: '630px', width: '1000px', marginLeft: '30px', marginRight: '40px', marginTop: '20px', position: 'relative', scale: `${1.05 * nVH}`,  lineHeight: `${1 * nVH}` }}>
              <Fluxograma a={'balHidrico'} f={nVH}/>
             </div>
      </div>
       </div>
       <div style={{display: 'flex', flexDirection: 'row', marginTop: '0.35vh'}}>
          <button className='psButtom' onClick={exportPDF}>
          <div className='psMenuButtom' >
          <FaRegFilePdf  className='psMenuIcon'/>
          <div className='psMenuText'>EXPORTAR</div></div>
          </button>
          <Modal show={isLoading} progress={progress} texto={modalText}/>
        </div>

    </div>
  );
};

export default ExportPDF;


