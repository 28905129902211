import React, { useState, useContext, useEffect, useRef } from 'react'
import '../../css/InputLine.css'
import GlobalStateContext from '../GlobalStateContext'
import SwitchControl from "../../components/ui/Switch";
import { getAlertList } from '../../Funcoes/GetAletrsList'
import { stdValue } from '../../Resources/setNumber';
import ModalUpdateValue from '../ui/ModalUpdateValue';


function CapLine({cod, handleCountChange}) {
  const { setAlert, data, setlistAlerts, user, setData } = useContext(GlobalStateContext);
 
  const [selectedValue1, setSelectedValue1] = useState(stdValue(data[cod[0]].valor));
  const [selectedValue2, setSelectedValue2] = useState(parseInt(data[cod[1]].valor));
  const [v, setV] = useState(stdValue(data[cod[0]]?.valor))
  const [prevV, setPrevV] = useState('')
  const [showModal, setShowModal] = useState(false)

  // useEffect(() => {
  //   setSelectedValue1(`${stdValue(data[cod[0]]?.valor)}`) 
  //   setSelectedValue2(`${parseInt(data[cod[1]]?.valor)}`) 
  // }, [ cod, data]);

  const handleAlert = (v) =>{
    setAlert(true)
}

  const handleIpt = (e) => {
    setV(e.target.value)
  }


  const updateValue = async (e) => {
    let nv = e.target.value.replace(/\./g, '')
    nv = nv.replace(/\,/g, '.')
    if (nv === '' || !contemNumero(nv)){
      setV(prevV)
    }else{
      if (nv !== prevV){
        setShowModal(true)
        setV(stdValue(nv))
        handleAlert(true)
  
        setData(prevData => ({
          ...prevData,
          [cod[0]]: {
            ...prevData[cod[0]],
            valor: parseFloat(nv),
          },
        }))

        setShowModal(false)
        setlistAlerts(getAlertList(data))
     
      }
    }
  }

  const handlePrevV = (e) => {
    setPrevV(e.target.value)
  }

  const updateValue2 = async (val) => {
    setSelectedValue2(val)
    setShowModal(true)
      setData(prevData => ({
        ...prevData,
        [cod[1]]: {
          ...prevData[cod[1]],
          valor: val,
        },
      }));
      setAlert(true)
      handleCountChange(cod[1])
      setShowModal(false)
}

  return (
    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
         <div className='inputLineCont'>
            <div className='line'>
                <div className='desc'>{data[cod[0]]?.descricao}</div>
                {/* <div className='iptCont'> */}
                    <input className={`ipt ${data[cod[0]]?.limiteMax !== null && data[cod[0]]?.valor > data[cod[0]]?.limiteMax ? 'outOfLimit' : ''}`} value={v} id={cod[0]} onChange={handleIpt} onBlur={updateValue} onFocus={handlePrevV} type="text" />
                {/* </div> */}
                <div className='unit'>{data[cod[0]]?.unidade}</div>
            </div>
    </div>

        {/* <div style={{width: '90%', border: '1px solid red'}}>Teste</div> */}
            
        <SwitchControl
                descricao={""} name="group-1" callback={(val) => updateValue2(val)} controlRef={useRef()} 
                defaultIndex={selectedValue2} 
                segments={[
                    {
                    label: "0",
                    value: 0,
                    ref: useRef()
                    },
                    {
                    label: "1",
                    value: 1,
                    ref: useRef()
                    }
                ]}
                w={0}
      />
      <ModalUpdateValue show={showModal}><p>Atualizando...</p></ModalUpdateValue>
    </div>
    
  )
}

export default CapLine

function contemNumero(string) {
    return !isNaN(parseFloat(string)) && isFinite(string);
  }