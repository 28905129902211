import React, {useContext } from 'react';
import { LuFileSearch } from "react-icons/lu";
import AnalisesContext from '../../../../../ANALISES/AnalisesContext';

export default function ImportBulletin() {
    const {setShowImportBoletim, setShowAnaliseDia} = useContext(AnalisesContext)

  const handleMenuChanged = () => {
    setShowImportBoletim(true)
    setShowAnaliseDia(false)
    };

  return (

    <div style={{display: 'flex', flexDirection: 'row', marginTop: '0.35vh'}}>
          <button className='psButtom' onClick={handleMenuChanged}>
          <div className='psMenuButtom' >
          <LuFileSearch  className='psMenuIcon'/>
          <div className='psMenuText'>IMP. BOLETIM</div></div>
          </button>
        </div>
  )
}
