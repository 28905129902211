import React, { useState, useEffect, useContext } from 'react';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { auth, firestore } from '../firebase/config';
import GlobalStateContext from './GlobalStateContext';

function Register() {
    const {setArea, user} = useContext(GlobalStateContext)
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    setArea('settings')
}, [])

  const handleRegister = async () => {
    try {
      // Criar um novo usuário com email e senha
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const newUser = userCredential.user;

      // Dados personalizados para um novo usuário
      const customData = {
        uid: newUser.uid,
        name: '',
        email: newUser.email,
        sites: {},
        createdAt: new Date(),
        // Adicione outras informações que você deseja salvar
      };
      console.log('user', user.email || '')
      console.log('newUser', newUser.uid)
      // Salvar informações personalizadas no Firestore
      await setDoc(doc(firestore, 'users', newUser.email), customData);

      alert('Cadastro bem-sucedido e informações salvas!');
    } catch (error) {
      console.error('Erro ao cadastrar:', error.message);
    }
  };

  return (
    <div>
      <h1>Cadastro</h1>
      <input
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <input
        type="password"
        placeholder="Senha"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <button onClick={handleRegister}>Cadastrar</button>
    </div>
  );
}

export default Register;
