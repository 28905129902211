import GlobalStateContext from '../../components/GlobalStateContext'
import { useContext, useEffect, useState } from 'react';
import Flow from './Flow'
import { getFlxData } from '../../BME/Unidades/Geral/getFlxData';
import { getImageAsync, saveImageAsync, getAllImagesAsync } from '../../Resources/imgsIndex'; 

function Fluxograma({a = '', f = 1}) {
  const {area, data, screen, site, isPrinting} = useContext(GlobalStateContext)
  const [flxData, setFlxData] = useState(null)
  const [image, setImage] = useState(null);

  // useEffect(() => {
  //   async function fetchImageifExist(imageId) {
  //     try {
  //       const existingImage = await getImageAsync(imageId);
  //       if (existingImage) {
  //         // Se a imagem já existir no IndexedDB, atualiza o estado com a imagem carregada
  //         setImage(existingImage);
  //       } 
  //     } catch (error) {
  //       console.error("Erro ao buscar ou salvar a imagem:", error);
  //       // setError(error);
  //     } 
  //   }
  //   setImage(null)
  //   fetchImageifExist(`${site}-${area}`)
  // }, [area])

  useEffect(() => {
    setImage(null)
    setFlxData(null)
    async function fetchOrSaveImage(imageId, image) {
      try {
        const existingImage = await getImageAsync(imageId);

        if (existingImage) {
        //   Se a imagem já existir no IndexedDB, atualiza o estado com a imagem carregada
          setImage(existingImage);
        } else if (image) {
        //   Se a imagem não existir e "imageDataToSave" for fornecida, salva-a no IndexedDB
          setImage(image);
          await saveImage(imageId, image);
          
        } 
      } catch (error) {
        console.error("Erro ao buscar ou salvar a imagem:", error);
        // setError(error);
      } finally {
        // setLoading(false); // O processo de busca ou salvamento terminou
      }
    }

    async function fetchAllImages() {
      try {
        const imageList = await getAllImagesAsync();
        // console.log('imageList', imageList)
      } catch (err) {
        console.error("Erro ao listar as imagens:", err);
        // setError(err);
        // console.log('imageList', null)
      } finally {
        // setLoading(false);
      }
    }
    fetchAllImages()
    if (data && screen && site){
      let ar = a !== '' ? a : area
      let imgAr = ar
      if (ar === 'evaporacao'){
        let nEf = data['qtdeEfeitos'].valor
        imgAr = `${ar}-${nEf === 0 ? 4 : 5}`
      }

      if (ar === 'tratEtanol' && site === 'usSonora'){
        let nEf = data['opRegCaldoEtanol'].valor
        imgAr = `${ar}-${nEf}`
      }

      let temp = getFlxData(ar, screen, data, site)
      fetchOrSaveImage(`${site}-${imgAr}`, temp?.imagem)
      setFlxData(temp)
    }
    
  }, [area, data, screen, site])

  const vh = window.innerHeight
  const ideal = 727
  let nVH = ideal/vh

    // Função para salvar a imagem no IndexedDB
    const saveImage = async (imageId, data) => {
      try {
        await saveImageAsync(imageId, data);
        console.log("Imagem salva com sucesso");
      } catch (error) {
        console.error("Erro ao salvar a imagem:", error);
        // setError(error);
      }
    };

 
  return (
    <div style={isPrinting ? {position: 'relative', height: '100%', width: '100%'} :
    {position: 'relative', height: '100%', width: '100%', backgroundColor: 'white'}}>
      <div style={isPrinting ?
        { position: 'absolute', width: '100%', height: '100%', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'} :
        { position: 'absolute', width: '115vh', height: '100vh', top: '56%', left: '42.7%', transform: 'translate(-50%, -50%)'}
      }>
        {image &&
        <img style={isPrinting ?
          {position: 'absolute', height: `${90 * nVH}vh`, width: `${135 * nVH}vh`, left: '0vh', top: '0vh'} :
          {position: 'absolute', height: '90vh', width: '135vh', left: '0vh', top: '0vh'}
        } src={image} alt="" onContextMenu={(e) => e.preventDefault()} />}
      {image && flxData && flxData.flows.map((item, index) => {
          return (
            <Flow flow={item.flow} x={item.x} y={item.y} align={item.align} valign={item.valign} cods={item.props} key={index} a={a === 'balHidrico' ? 'balHidrico' : ''}/>
          )
          })}
      </div>
    </div>
  )
}

export default Fluxograma