import React, { useContext } from 'react';
import GlobalStateContext from '../../../components/GlobalStateContext';
import MenuAnalises from '../usSantaElisa/AnaliseProducao/menuAnalises';
import AnalisesContext from '../../../ANALISES/AnalisesContext';
import ExcelReader from '../usSantaElisa/AnaliseProducao/ExcelReader';
import DayAnalyzer from '../usSantaElisa/AnaliseProducao/DayAnalizer';

//Batatais
import MenuAnalisesUBT from '../usBatatais/AnaliseProducao/menuAnalises'
import ExcelReaderUBT from '../usBatatais/AnaliseProducao/ExcelReader'
import DayAnalyzerUBT from '../usBatatais/AnaliseProducao/DayAnalizer'
import DayAnalyzerBltXPlano from '../usBatatais/AnaliseProducao/DayAnalizerBltxPlano';

//Lins
import MenuAnalisesUSL from '../usLins/AnaliseProducao/menuAnalises'
import ExcelReaderUSL from '../usLins/AnaliseProducao/ExcelReader'
import DayAnalyzerUSL from '../usLins/AnaliseProducao/DayAnalizer'
import DayAnalyzerBltXPlanoUSL from '../usLins/AnaliseProducao/DayAnalizerBltxPlano';

//Sonora

//Teste1
import MenuAnalisesTST1 from '../usTeste1/AnaliseProducao/menuAnalises'
import ExcelReaderTST1 from '../usTeste1/AnaliseProducao/ExcelReader'
import DayAnalyzerTST1 from '../usTeste1/AnaliseProducao/DayAnalizer'
import DayAnalyzerBltXPlanoTST1 from '../usTeste1/AnaliseProducao/DayAnalizerBltxPlano';

const AnaliseProducao = () => {
    const {site} = useContext(GlobalStateContext)
    const { showImportBoletim, showAnaliseDia, showBltxPlano } = useContext(AnalisesContext)

    switch(site){
        case 'usSantaElisa':
            return (
                <div>
                    <MenuAnalises/>
                    {showImportBoletim &&
                    <ExcelReader/>}
                    {showAnaliseDia &&
                    <DayAnalyzer/>}
                </div>
            )
        case 'usBatatais':
            return (
                <div>
                    <MenuAnalisesUBT/>
                    {showImportBoletim &&
                    <ExcelReaderUBT/>}
                    {showAnaliseDia &&
                    <DayAnalyzerUBT/>}
                    {showBltxPlano &&
                    <DayAnalyzerBltXPlano/>}
                </div>
            )

        case 'usLins':
            return (
                <div>
                    <MenuAnalisesUSL/>
                    {showImportBoletim &&
                    <ExcelReaderUSL/>}
                    {showAnaliseDia &&
                    <DayAnalyzerUSL/>}
                    {showBltxPlano &&
                    <DayAnalyzerBltXPlanoUSL/>}
                </div>
            )
        case 'usTeste1':
            return (
                <div>
                    <MenuAnalisesTST1/>
                    {showImportBoletim &&
                    <ExcelReaderTST1/>}
                    {showAnaliseDia &&
                    <DayAnalyzerTST1/>}
                    {showBltxPlano &&
                    <DayAnalyzerBltXPlanoTST1/>}
                </div>
            )
        default: return (<></>)
    }

    
}

export default AnaliseProducao