import React, { useState, useContext, useEffect } from 'react';
import '../../css/InputLine.css';
import GlobalStateContext from '../GlobalStateContext';
import { getAlertList } from '../../Funcoes/GetAletrsList';
import ModalUpdateValue from '../ui/ModalUpdateValue';
import { stdValue } from '../../Resources/setNumber';

function InputLine({ cod }) {
  const [prevV, setPrevV] = useState('');
  const { setAlert, data, setlistAlerts, user, screen, setData } = useContext(GlobalStateContext);
  const [v, setV] = useState('');

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setV(stdValue(data[cod]?.valor) ?? 0);
}, [data, cod]);

  const handleAlert = () => {
    setAlert(true);
  };

  const handleIpt = (e) => {
    setV(e.target.value);
  };

  const updateValue = async (e) => {
    let nv = e.target.value.replace(/\./g, '')
    nv = nv.replace(/\,/g, '.')
    console.log('nv', nv, contemNumero(nv))
    if (nv === '' || !contemNumero(nv)) {
      setV(prevV);
    } else {
      if (nv !== prevV) {
        setShowModal(true);
        setV(stdValue(nv));
        handleAlert(true);
      
        setData((prevData) => ({
          ...prevData,
          [cod]: {
            ...prevData[cod],
            valor: parseFloat(nv),
          },
        }));
        setShowModal(false);
        setlistAlerts(getAlertList(data));
      }
    }
  };

  const handlePrevV = (e) => {
    setPrevV(e.target.value);
  };

 if (data){
  return (
    <div
      className={`inputLineCont`}
    >
      <div className='line'>
      <div className='desc'>{data[cod]?.descricao}</div>
      <input
        className={`ipt ${
          data[cod]?.limiteMax !== null && data[cod]?.valor > data[cod]?.limiteMax || data[cod]?.limiteMin !== null && data[cod]?.valor < data[cod]?.limiteMin
            ? 'outOfLimit'
            : ''
        }`}
        value={v ?? ''}
        id={`${screen}-${cod}`}
        onChange={handleIpt}
        onBlur={updateValue}
        onFocus={handlePrevV}
        type='text'
      />
      <div className='unit'>{data[cod]?.unidade}</div>
    </div>
      <ModalUpdateValue show={showModal}>
        <p>Atualizando...</p>
      </ModalUpdateValue>
    </div>
  );
 }else{
  return (
    <></>
  )
 }
}

export default InputLine;

function contemNumero(string) {
  return !isNaN(parseFloat(string)) && isFinite(string);
}
