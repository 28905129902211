import {useContext } from 'react';
import AnalyticsHooks from './analyticsHooks';
import GlobalStateContext from '../components/GlobalStateContext';

const BmeHooks = () => {
    const {newAction} = AnalyticsHooks()
    const { setData, data, user, site, setAlert } = useContext(GlobalStateContext);

    const calcBME = async () => {
        newAction('calcularBME', site, user.email)
        
        try {
          const response = await fetch(''+global.endPoint+'/'+site+'/'+user?.uid+'/calcAllLoc', {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({data: data})
          });
          if (!response.ok) {
            throw new Error('Erro ao buscar dados da API');
          }
          const jsonData = await response.json()
          
          setData(jsonData)
          setAlert(false)
         
        } catch (error) {
          console.error('Erro ao buscar dados da API:', error);
          
        }
      }
  
    return {calcBME};
  };
  
  export default BmeHooks;