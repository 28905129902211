import { useContext } from 'react';
import { GlobalStateContext } from '../GlobalStateContext';

export const UseColors =  () => {
  const { site } = useContext(GlobalStateContext);

  switch (site){
    case 'usSantaElisaxxx': 
    return {
      primaryColor: '#6f0070',
      secondaryColor: '#e83e8c',
      terciaryColor: '#fd7e14',
      fourColor: '#17a2b8',
      fiveColor: '#28a745',
      sixColor: '#ffc107',
      sevenColor: '#343a40',
      eightColor: '#6c757d',
    }
    case 'usTeste1':
    case 'usLins':
    case 'usBatatais':
      return {
        primaryColor: 'rgb(14, 68, 132)',
        secondaryColor: '#4FAD5B',
        terciaryColor: '#DF8345',
        fourColor: 'grey',
        fiveColor: 'rgb(0, 122, 254)',
        sixColor: '#4FAD5B',
        sevenColor: '#DF8345',
        eightColor: 'grey',
      }
      case 'usSonora':
      return {
        primaryColor: 'rgb(39, 88, 161)',
        secondaryColor: '#4FAD5B',
        terciaryColor: '#DF8345',
        fourColor: 'grey',
        fiveColor: 'rgb(0, 122, 254)',
        sixColor: '#4FAD5B',
        sevenColor: '#DF8345',
        eightColor: 'grey',
      }
      case 'usMonteAlegre':
      case 'usSantaElisa': 
      return {
        primaryColor: '#004729',
        secondaryColor: '#FFB23C',
        terciaryColor: '#754210',
        fourColor: '#FF582E',
        fiveColor: '#5E73A7',
        sixColor: '#95B9C6',
        sevenColor: '#C5EF76',
        eightColor: 'grey',
      }
      case 'usCevasa':
      
      return {
        primaryColor: '#008B5A',
        secondaryColor: '#00988E',
        terciaryColor: '#DF8345',
        fourColor: 'grey',
        fiveColor: 'rgb(0, 122, 254)',
        sixColor: '#4FAD5B',
        sevenColor: '#DF8345',
        eightColor: 'grey',
      }
    default: 
    return {
      primaryColor: 'rgb(0, 122, 254)',
        secondaryColor: '#4FAD5B',
        terciaryColor: '#DF8345',
        fourColor: 'grey',
        fiveColor: 'rgb(0, 122, 254)',
        sixColor: '#4FAD5B',
        sevenColor: '#DF8345',
        eightColor: 'grey',
      }

  }
  };