import React, {useContext } from 'react';
import { LuFileX2 } from "react-icons/lu";
import { ACContext } from './AnaliseCenariosContext';

export default function BtCleanCenario() {
    const {setAcData, isEditing} = useContext(ACContext)

  const handleMenuChanged = () => {
    setAcData([])
    };

  return (

    <div style={{display: 'flex', flexDirection: 'row', marginTop: '0.35vh'}}>
          <button className='psButtom' onClick={handleMenuChanged} disabled={isEditing}>
          <div className='psMenuButtom' >

          <LuFileX2  className='psMenuIcon'/>
          <div className='psMenuText'>LIMPAR</div></div>
          </button>
        </div>
  )
}