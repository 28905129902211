import React, { PureComponent, useContext, useEffect, useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label } from 'recharts';
import { stdValue } from '../../Resources/setNumber';
import PSContext from '../opoio/PSContext';
import { getStdPlanoSafra } from '../../BME/Unidades/Geral/getStdPlanoSafra';
// import { stdPlanoVars } from '../stdPlanoSafra'


class CustomizedLabel extends PureComponent {
  render() {
    const { x, y, stroke, value, isPrint } = this.props;

    return (
      <text x={x} y={y} dy={-8} fill={'gray'} textAnchor="middle" fontSize={`${isPrint ? '12px' : '1.4vh'}`} fontWeight={`${isPrint ? 'normal' : 'bold'}`}>
        {stdValue(value)}
      </text>
    );
  }
}

  class CustomizedAxisTick extends PureComponent {
    render() {
      const { x, y, stroke, payload, isPrint } = this.props;
  
      return (
        <g transform={`translate(${x},${y})`}>
          <text x={0} y={0} dy={isPrint ? '16px' : '1.5vh'} textAnchor="middle" fill="gray" transform="rotate(0)" >
            {payload.value}
          </text>
        </g>
      );
    }
  }

  const CustomDot = (props) => {
    const { cx, cy, value, payload, stroke } = props;
    
    // // Alterar a cor ou ícone com base no valor
    // let fill = '#8884d8';
    // if (payload.isReal) {
    //   fill = '#82ca9d'; // Verde para valores acima de 300
    // } 

    
    
    return (
        <circle
        cx={cx}
        cy={cy}
        r={4}
        stroke={stroke}
        strokeWidth={0.5}
        fill={payload.isReal ? stroke : 'white'}
      />
    );
  };

export default function LineChart10YearPlan({seqSafras, chart, stdList, selectedValues, h = '30vh'}) {
const [chartData, setChartData] = useState(null)
const [varDesc, setVarDesc] = useState('')
const [chartTitle, setChartTitle] = useState('')
const {psIsPrinting, allPsData} = useContext(PSContext)
const stdPlanoVars = getStdPlanoSafra()


function getMax(data){
    if (allPsData && data){
        const maxItem = data.reduce((max, item) => item.valor > max.valor ? item : max, data[0]);
        return maxItem?.valor
    }
    return 0
}

async function setK(data){
    const updatedData = data.map(item => ({
        ...item, 
        valor: (item.valor / 1000 ).toFixed(0)
      }));
      return updatedData
}

function getDescAndUnit(cod){
  let item = stdPlanoVars.filter(item => item.cod === cod)
  let desc = item[0].desc
  let unit = item[0].unid
  return [desc, unit]
}

useEffect(() => {
  
    async function fetchData() {
        if (allPsData){
            let tempData = []
        for (const safra in seqSafras){
            let strValue = stdList[seqSafras[safra]] && stdList[seqSafras[safra]][selectedValues[seqSafras[safra]]] &&
            stdList[seqSafras[safra]][selectedValues[seqSafras[safra]]][chart.secao][chart.cod] ? stdValue(stdList[seqSafras[safra]][selectedValues[seqSafras[safra]]][chart.secao][chart.cod]).replace(/\./g, '') : ''//getValue(seqSafras[safra], chart.secao, chart.cod).replace(/\./g, '')
            // console.log('StrValue', strValue)
            let dbValue = !strValue || strValue === '' ? 0.0 : parseFloat(strValue.replace(',', '.'))
            let newItem = {safra: seqSafras[safra], valor: dbValue}
            tempData.push(newItem)
        }
        // let max = getMax(tempData)
        

        // if (max > 99999){
        //     // console.log('Max  ', tempData, max)
        //     // tempData = await setK(tempData)
        // }
        let descAndUnit = getDescAndUnit(chart.cod)
        let desc = descAndUnit[0]
        let unit = descAndUnit[1]
        setChartTitle(`${desc} [${unit}]`)
        setChartData(tempData)
        console.log('ChartData', tempData)
        }
    }
    fetchData();
  }, []);

  const formatTooltipValue = (value, name, props) => {
    return [`${stdValue(value)}`, name]; 
  };

  const formatYAxis = (tickItem) => {
    return `${stdValue(tickItem)}`;
  };

  
    return (
        
        <div style={{height: h, width: '100%', borderRadius: '2%', backgroundColor: 'white', margin: '1vh'}}>
          <div style={{marginTop: '0.8vh', color: '#666', fontWeight: 'bold', fontSize: '1.6vh'}}>{chartTitle}</div>
        <ResponsiveContainer width="94%" height="90%">
          
        <LineChart width={500} height={200} data={chartData}
        margin={{ top: 18, right: 15, left: 20, bottom: 20 }} style={{fontSize:'1.5vh', padding: '1vh'}}>
        <CartesianGrid strokeDasharray="2 3" />
        <XAxis dataKey="safra" axisLine={false} tickLine={false}  height={psIsPrinting ? 40 : 20} tick={<CustomizedAxisTick isPrint={psIsPrinting}/>} interval={0}/>
        <YAxis axisLine={false} tickLine={false} width={40} tickFormatter={formatYAxis} />
        <Tooltip formatter={formatTooltipValue}/>
        
        {/* <Legend/> */}
        <Line type="monotone" dataKey="valor" name={varDesc}  stroke={chart.color} label={<CustomizedLabel isPrint={psIsPrinting}/>}
        animationDuration={250} dot={<CustomDot stroke={chart.color} />} strokeWidth={2}/>
      </LineChart>
     </ResponsiveContainer>
     </div>
     
    );
 
}
