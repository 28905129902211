import React, { useContext } from 'react'
import '../css/Tables.css'
import GlobalStateContext from '../components/GlobalStateContext'
import { stdValue } from './setNumber';

function FabFlowsTable() {
  const { data } = useContext(GlobalStateContext);
  
  return (
      <table className='custom-table'>
      {data && tableData.map((line) => {
        return (
              <tr className={getHeadClass(line.head)}>
                <th className='first-column'>{line.desc}</th>
                {line.props.map(item => {
                  if (line.head === 0){
                    return (
                      <th >{item}</th>
                    )
                  }else{
                    return (
                      <th style={{fontWeight: 'normal'}}>{stdValue(data[item]?.valor)}</th>
                    )
                  }
                })}
              </tr>
        )
        })}
      </table>
  )
}

export default FabFlowsTable

function getHeadClass(head){
  switch (head){
    case 0: return 'first-row'
    case 1: return 'second-row'
    default: return 'third-row'
  }
}

const tableData = [
  {
    head: 0,
    desc: 'Fluxo',
    props: ['Vazão [t/h]', 'Brix [%]', 'Pureza [%]', 'Sólidos [t/h]']
  },
  {
    head: 1,
    desc: 'Xarope p/ Fábrica',
    props: ['flowXaropeToFab', 'brixXaropeToFab', 'purezaXarope', 'brixMassaXaropeToFab']
  },
  {
    head: 2,
    desc: 'Xarope p/ Massa A',
    props: ['flowXaropeToMassaA', 'brixXaropeToMassaA', 'purezaXarope', 'brixMassaXaropeToMassaA']
  },
  {
    head: 1,
    desc: 'Xarope p/ Massa B',
    props: ['flowXaropeToMassaB', 'brixXaropeToMassaB', 'purezaXarope', 'brixMassaXaropeToMassaB']
  },
  {
    head: 2,
    desc: 'Massa A',
    props: ['flowMassaA', 'brixXaropeToMassaA', 'purezaMassaA', 'brixMassaXaropeToMassaA']
  },
  // {
  //   head: 1,
  //   desc: 'Alim. Massa A',
  //   props: ['flowAlimMassaA', 'brixAlimMassaA', 'purezaAlimMassaA', 'brixMassaAlimMassaA']
  // }
  // ,
  {
    head: 1,
    desc: 'Mel A Rico',
    props: ['flowMelARico', 'brixMelARico', 'purezaMelARico', 'brixMassaMelARico']
  }
  ,
  {
    head: 2,
    desc: 'Mel A Pobre',
    props: ['flowMelAPobre', 'brixMelAPobre', 'purezaMelAPobre', 'brixMassaMelAPobre']
  }
  ,
  {
    head: 1,
    desc: 'Mel A Pobre Diluído',
    props: ['flowMAPDiluido', 'brixMAPDiluido', 'purezaMAPDiluido', 'brixMassaMAPDiluido']
  },
  {
    head: 2,
    desc: 'Massa B',
    props: ['flowMassaB', 'brixXaropeToMassaB', 'purezaMassaB', 'brixMassaXaropeToMassaB']
  },
  // {
  //   head: 2,
  //   desc: 'Alim. Massa B',
  //   props: ['flowAlimMassaB', 'brixAlimMassaB', 'purezaAlimMassaB', 'brixMassaAlimMassaB']
  // },
  {
    head: 1,
    desc: 'Mel Final',
    props: ['flowMelFinal', 'brixMelFinal', 'purezaMelFinal', 'brixMassaMelFinal']
  },
  {
    head: 2,
    desc: 'Magma',
    props: ['flowMagma', 'brixMagma', 'purezaMagma', 'brixMassaMagma']
  },
  {
    head: 1,
    desc: 'Açúcar B',
    props: ['flowAcucarB', 'brixAcucarB', 'purezaAcucarB', 'brixMassaAcucarB']
  },
  {
    head: 2,
    desc: 'Açúcar',
    props: ['flowAcucar', 'brixAcucar', 'purezaAcucar', 'brixMassaAcucar']
  }
]