import React, {useContext, useEffect, useState }  from 'react';
import { FaChartLine, FaTableCells } from "react-icons/fa6";
import PSContext from '../opoio/PSContext';
import MenuBtDivider from '../../components/ui/menuBtDivider';

export default function BtChartsPS() {
  const {isSave, setIsChart, isNew, isSaveAs, isOpen, isExpPdf, isEdit, isChart, psData} = useContext(PSContext)
  const [isDisable, setIsDisable] = useState(false)

  useEffect(() => {
    if(isOpen || !psData){
      setIsDisable(true)
    }else{
      setIsDisable(false)
    }
  }, [isSave, isNew, isSaveAs, isOpen, isExpPdf, isEdit, isChart])

    const btClicked = () => {
      setIsChart(!isChart)
    }

  if (!isOpen && !isEdit){
    return (
      <div style={{display: 'flex', flexDirection: 'row'}}>
        <MenuBtDivider/>
          <button className='psButtom' onClick={btClicked}
          disabled={isDisable}>
          <div className='psMenuButtom' >
          {isChart ? <FaTableCells  className='psMenuIcon' style={{scale: '0.95'}}/> :
                   <FaChartLine  className='psMenuIcon'/>}
                  <div className='psMenuText'>{isChart ? 'TABELA' : 'GRÁFICOS'}</div></div>
          </button>
      </div>
      
      
    )
  }
}