import React, {useContext, useEffect, useState } from 'react';
import PSContext from '../opoio/PSContext';
import { LuFiles } from "react-icons/lu";
import MenuBtDivider from '../../components/ui/menuBtDivider';

export default function BtSaveAsPS() {
  const {setIsNew, setIsSaveAs, isSaveAs, isOpen, psData} = useContext(PSContext)

    const btClicked = () => {
      // setIsEdit(false)
      setIsNew(false)
      setIsSaveAs(!isSaveAs)
    }

  if (isOpen){
    return (
      <div style={{display: 'flex', flexDirection: 'row'}}>
        <MenuBtDivider/>
          <button className='psButtom' onClick={btClicked}
          disabled={psData ? false : true}>
          <div className='psMenuButtom' >
      <LuFiles  className='psMenuIcon'/>
      <div className='psMenuText'>SALVAR COMO</div></div>
          </button>
  </div>
    )
  }
}