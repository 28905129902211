import React from 'react';
import ImportBulletin from './Botoes/ImportBulletin'
import MenuBtDivider from '../../../../components/ui/menuBtDivider';
import BtDayAnalyzer from './Botoes/btDayAnalyzer';

const MenuAnalises = () => {

  return (
    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginLeft: '2vh', marginBottom: '-0.6vh', marginTop: '-0.4vh'}}>
            <div className="psMainMenu">
    <ImportBulletin/>
    <MenuBtDivider/>
    <BtDayAnalyzer/>
  
    </div>
    </div>
  )
}

export default MenuAnalises