import React, {useContext } from 'react';
import { LuFileBadge } from "react-icons/lu";
import AnalisesContext from '../../../../../ANALISES/AnalisesContext';
import MenuBtDivider from '../../../../../components/ui/menuBtDivider';

export default function BtBltxPlano() {
    const {setShowImportBoletim, setShowAnaliseDia, setShowBltxPlano} = useContext(AnalisesContext)

  const handleMenuChanged = () => {
    setShowImportBoletim(false)
    setShowAnaliseDia(false)
    setShowBltxPlano(true) 
    };

  return (
    <div style={{display: 'flex', flexDirection: 'row', marginTop: '0.35vh'}}>
        <MenuBtDivider/>
    <button className='psButtom' onClick={handleMenuChanged}>
    <div className='psMenuButtom' >
    <LuFileBadge  className='psMenuIcon'/>
    <div className='psMenuText'>REAL x PLANO</div></div>
    </button>
  </div>
  )
}