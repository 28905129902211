import defBmeDataSEL from '../usSantaElisa/defBmeDataSEL.json'
import defBmeDataUBT from '../usBatatais/defBmeDataUBT.json'
import defBmeDataSON from '../usSonora/defBmeDataSON.json'
import defBmeDataUMA from '../usMonteAlegre/defBmeDataUMA.json'
import defBmeDataCVS from '../usCevasa/defBmeDataCVS.json'
import defBmeDataUSL from '../usLins/defBmeDataUSL.json'
import defBmeDataTST1 from '../usTeste1/defBmeDataTST1.json'

export async function getDefData(site){
    switch (site){
        case 'usSantaElisa': return defBmeDataSEL
        case 'usBatatais': return defBmeDataUBT
        case 'usSonora': return defBmeDataSON
        case 'usMonteAlegre': return defBmeDataUMA
        case 'usCevasa': return defBmeDataCVS
        case 'usLins': return defBmeDataUSL
        case 'usTeste1': return defBmeDataTST1
        default: return null
    }
}

