
export function getVersion(site){
    switch (site){
        case 'usSantaElisa': return '1.0.1'
        case 'usBatatais': return '1.0.6'
        case 'usSonora': return '1.0.3'
        case 'usMonteAlegre': return '1.0.2'
        case 'usCevasa': return '0.0.7'
        case 'usLins': return '0.0.5'
        case 'usTeste1': return '0.0.1'
        default: return null
    }
}