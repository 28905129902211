
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, setDoc } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBRxZrx4SOfh9pO0cyOckC5x9phbt_2p7s",
  authDomain: "pmsusers-4be32.firebaseapp.com",
  projectId: "pmsusers-4be32",
  storageBucket: "pmsusers-4be32.appspot.com",
  messagingSenderId: "604084672961",
  appId: "1:604084672961:web:7247d2ee228a9e761c7740",
  measurementId: "G-CWHT0V23QP"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);

export { auth, firestore };