import React, {useContext, useEffect, useState, useRef } from 'react';
import PSContext from '../opoio/PSContext';
import { FaChartColumn, FaTableCells } from "react-icons/fa6";


export default function BtChartsCompPS({disable}) {

    const {showChartPsComp, setShowChartPsComp} = useContext(PSContext)

    function handleBtCharts(){
        setShowChartPsComp(!showChartPsComp)
    }

    return (
        <div className='psMenuButtom'>
            <button className='psButtom' onClick={handleBtCharts} disabled={disable}>
                {showChartPsComp ? <FaTableCells  className='psMenuIcon' style={{scale: '0.95'}}/> :
                <FaChartColumn  className='psMenuIcon' style={{scale: '0.95'}}/>}
                <div className='psMenuText'>{showChartPsComp ? 'TABELA' : 'GRÁFICOS'}</div>
            </button>
        </div>
    )
}



