import React, {useContext, useEffect, useState } from 'react';
import GlobalStateContext from '../../components/GlobalStateContext';
import PSContext from '../opoio/PSContext';
import { UseColors } from '../../components/ui/colors' 
import MenuCompRevs from './menuCompRevs';
import BodyCompRevs from './bodyCompRevs';
import ChartsCompRevs from './chartsCompRevs';

export default function CompRevisoes() {
    const {setMenu, setArea} = useContext(GlobalStateContext)
    const {showChartPsComp, setShowChartPsComp} = useContext(PSContext);
    
    const COLORS = UseColors()

useEffect(() => {
    document.documentElement.style.setProperty('--primary-color', COLORS.primaryColor);
    setMenu('ps')
    setArea('compRevisoes')
  }, []);

  return (
    <div>
        <MenuCompRevs/>
        {showChartPsComp ?
        <ChartsCompRevs/> :
        <BodyCompRevs />
        }
        
    </div>
  )
}