import React from 'react'
import GlobalStateContext from '../../GlobalStateContext'
import { useContext } from 'react'
import AlertsLine from './AlertsLine'
import '../../../css/planoSafra.css'

function Alertas() {
const {listAlerts} = useContext(GlobalStateContext)

const lista = [
  {key: 'cap', desc: 'CAPACIDADE'},
  {key: 'pro', desc: 'PROCESSO'},
  {key: 'efi', desc: 'EFICIÊNCIA'},
  {key: null, desc: 'OUTROS'}
]

  return (
    <div style={{overflowY: 'auto', width: '80%', margin: 'auto', marginTop: '1vh'}}>
        <div style={{paddingLeft: '0.5vh', paddingRight: '0.5vh', marginTop: '2vh', marginBottom: '0.5vh', fontWeight: 'bold',
          position: '-webkit-sticky',
          position: 'sticky',
          top: '0',
          backgroundColor: '#f2f2f7'
        }}>
            <div className='fullLine'>
                <div className='fullLineRow' style={{width: '40%'}}>Desrição</div>
                <div className='fullLineRow' style={{width: '15%'}}>Valor</div>
                <div className='fullLineRow' style={{width: '15%'}}>Mínimo</div>
                <div className='fullLineRow' style={{width: '15%'}}>Máximo</div>
                <div className='fullLineRow' style={{width: '15%', textAlign: 'right'}}>Unidade</div>
            </div>
      </div>
        {listAlerts && lista.map((secao, index) => {
          if (listAlerts.filter(item => item.alerta === secao.key).length > 0){
            return (
              <div key={`${secao.key}-${index}`}>
                <div className='psSectionHead' style={{top: '3vh', paddingLeft: '1vh', backgroundColor: '#f2f2f7', borderBottom: '0'}}>{secao.desc}</div>
                <div style={{borderRadius: '0.6vh', backgroundColor: 'white'}}>
                {listAlerts
                  .sort((a, b) => {return a.descricao.localeCompare(b.descricao)})
                  .filter(item => item.alerta === secao.key)
                  .map((item) => {
                      return (
                        <AlertsLine item={item} key={item.codigo}/>
                      )
                  })}
                </div>
            </div>)
          }
        })}
    </div>
  )
}

export default Alertas