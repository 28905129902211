import React, {useContext } from 'react';
import { LuFileCheck2 } from "react-icons/lu";
import AnalisesContext from '../../../../../ANALISES/AnalisesContext';

export default function BtDayAnalyzer() {
    const {setShowImportBoletim, setShowAnaliseDia, setShowBltxPlano} = useContext(AnalisesContext)

  const handleMenuChanged = () => {
    setShowImportBoletim(false)
    setShowAnaliseDia(true)
    setShowBltxPlano(false)
    };

  return (
    <div style={{display: 'flex', flexDirection: 'row', marginTop: '0.35vh'}}>
    <button className='psButtom' onClick={handleMenuChanged}>
    <div className='psMenuButtom' >
    <LuFileCheck2  className='psMenuIcon'/>
    <div className='psMenuText'>ANÁLISE DIA</div></div>
    </button>
  </div>
  )
}