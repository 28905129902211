import React, {useContext, useState, useEffect } from 'react';
import PSContext from '../opoio/PSContext';
import GlobalStateContext from '../../components/GlobalStateContext';
import ModalConfirm from '../../components/ui/ModalConfirm';
import AnalyticsHooks from '../../Hooks/analyticsHooks';
import PsHooks from '../../Hooks/psHooks';

export default function SubMenuFiles() {
  const {setPsData, setIsOpen, allPsData, setAllPsData} = useContext(PSContext)
  const {user, site} = useContext(GlobalStateContext)
  const [showModal, setShowModal] = useState(false);
  const [showModalOpen, setShowModalOpen] = useState(false);
  const [safraDel, setSafraDel] = useState(null)
  const [revDel, setRevDel] = useState(null)
  const [modalMessage, setModalMessage] = useState('')
  const [showModalMessage, setShowModalMessage] = useState(false)

  const {newAction} = AnalyticsHooks()
  const {resumeAllPS, openPS, deletePS} = PsHooks()

  useEffect(() => {
    if (!allPsData){
      setModalMessage('Carregando Planos...')
      setModalMessage(true)
    }
    getAllPsData()
  }, []);

  async function getAllPsData() {
    let allPs = await resumeAllPS(site)
    setAllPsData(allPs)
    setModalMessage(false)
  }

  async function abrirPs(safra, revisao){
    newAction('abrirPS', site, user.email || '')
    let ssr = `${site}-${safra}-${revisao}`
    let impData = await openPS(ssr)
    setPsData(impData)

    // let real = await getPsReal(safra)
    // setPsDataReal(real)
  }

  const confirmDelete = async () => {
    setShowModal(false);
    setModalMessage(`Deletando Plano Safra ${safraDel} revisão ${revDel}...`)
    setShowModalMessage(true);
    await deletePS(`${site}-${safraDel}-${revDel}`)
    let allPS = await resumeAllPS(site)
    setAllPsData(allPS)
    setShowModalMessage(false);
  };

  const cancelOpen = () => {
    setShowModalOpen(false);
  };

  const confirmOpen = async () => {
    setShowModalOpen(false);
    setModalMessage(`Abrindo Plano Safra ${safraDel} revisão ${revDel}...`)
    setShowModalMessage(true)
    await abrirPs(safraDel, revDel)
    setIsOpen(false)
    setShowModalMessage(false)
  };

  const cancelDelete = () => {
    setShowModal(false);
  };

  const showConfirmOpen = (safra, revisao) => {
    setSafraDel(safra)
    setRevDel(revisao)
    setShowModalOpen(true);
  };

  const showConfirm = (safra, revisao) => {
    setSafraDel(safra)
    setRevDel(revisao)
    setShowModal(true);
  };


  return (
    <>
     <div style={{marginLeft: '2vh', marginRight: '2vh', marginTop: '2vh'}}>
    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '0.5vh', borderBottom: '1px solid #f2f2f7', fontSize: '1.6vh', fontWeight: 'bold', textAlign: 'left', paddingLeft: '2vh'}}>
                        <div style={{width: '10%'}}>Safra</div>
                        <div style={{width: '15%'}}>Revisão</div>
                        <div style={{width: '25%'}}>Última Modificação</div>
                        <div style={{width: '30%'}}>Usuário</div>
                        <div style={{width: '20%'}}>Ações</div>
                        
                    </div>
                    </div>
    <div >
    
    {allPsData && 
    <div style={{overflow: 'auto', height: '78vh'}}>
      {Object.keys(allPsData)
      .sort((a, b) => b.localeCompare(a))
      .map(safra => (
        <div key={safra}>
          <div style={{marginTop: '1vh', marginBottom: '0.3vh', fontSize: '1.6vh', fontWeight: 'bold', textAlign: 'left', marginLeft: '2vh'}}>{safra}</div>
          <div style={{marginLeft: '2vh', marginRight: '2vh', marginTop: '0vh', backgroundColor: 'white', borderRadius: '1vh'}}>
            {Object.keys(allPsData[safra])
            .sort()
            .map((revisao, index) => {
                const latestItem = allPsData[safra][revisao]
                const formattedDate = new Date(latestItem.atualizadoEm).toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' }); // Formato brasileiro de data e hora
                const email = allPsData[safra][revisao].userEmail
                return (
                    <div  style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '0.7vh', borderBottom: '1px solid #f2f2f7', 
                    textAlign: 'left', paddingLeft: '2vh', fontSize: '1.6vh', alignItems: 'center', }} //backgroundColor: `${index%2 !== 0 ? '#f2f2f7' : 'white'}`
                    key={revisao}>
                        <div style={{width: '10%'}}>{safra}</div>
                        <div style={{width: '15%'}}>{revisao}</div>
                        <div style={{width: '25%'}}>{formattedDate}</div>
                        <div style={{width: '30%'}}>{email}</div>
                        <div style={{display: 'flex', flexDirection: 'row', width: '20%'}}>
                        <div ><button className="modal-open-button2" onClick={()=> showConfirmOpen(safra, revisao)}>Abrir</button></div>
                        <div style={{marginLeft: '2vh'}}><button className="modal-delete-button2" onClick={()=> showConfirm(safra, revisao)}>Deletar</button></div>
                        </div>
                    </div>
                  )
            })}
          </div>
        </div>
      ))}
    </div>}
    </div>

<ModalConfirm show={showModal} title="Alerta">
<div className='modal-title'>Tem certeza que deseja deletar o plano {safraDel} - {revDel}?</div>
<button className="modal-cancel-button" onClick={cancelDelete}>Cancela</button>
<button className="modal-destroy-button" onClick={confirmDelete}>Deletar</button>

</ModalConfirm>
<ModalConfirm show={showModalOpen} title="Alerta">
<div className='modal-title'>Deseja abrir o plano {safraDel} - {revDel}? Verifique se o plano atual foi salvo.</div>
<button className="modal-cancel-button" onClick={cancelOpen}>Cancela</button>
<button className="modal-open-button" onClick={confirmOpen}>Abrir</button>

</ModalConfirm>
<ModalConfirm show={showModalMessage} title="Alerta">
<div className='modal-title' style={{marginTop: '3vh', padding: '2vh'
}}>{modalMessage}</div>
</ModalConfirm>
</>
  )
}