import React, { useContext } from 'react';
import '../../css/SegmentedControl.css'; 
import PSContext from '../opoio/PSContext';

import { NavLink, Link } from 'react-router-dom';


function ChartsCompSegControl() {
    const {selectedCompCharts, setSelectedCompCharts} = useContext(PSContext)
  
  const handleOptionChange = (option) => {
    setSelectedCompCharts(option)
  };

  return (
    <>
      <div className={`seg-container`} >
      <div className="segmented-control" style={{width: '60%'}}>

        <div className={`segmented-control-button ${selectedCompCharts === 0 && 'selected'} first-button`}>
            <div onClick={() => handleOptionChange(0)}>MATÉRIA PRIMA</div>
        </div>
        <div className={`segmented-control-button ${selectedCompCharts === 1 && 'selected'} middle-button`}>
            <div onClick={() => handleOptionChange(1)}>MOAGEM</div>
        </div>
        <div className={`segmented-control-button ${selectedCompCharts === 2 && 'selected'} middle-button`}>
            <div onClick={() => handleOptionChange(2)}>EFICIÊNCIAS</div>
        </div>

        <div className={`segmented-control-button ${selectedCompCharts === 3 && 'selected'} middle-button`}>
            <div onClick={() => handleOptionChange(3)}>PERDAS</div>
        </div>

        <div className={`segmented-control-button ${selectedCompCharts === 4 && 'selected'} middle-button`}>
            <div onClick={() => handleOptionChange(4)}>PRODUÇÕES</div>
        </div>
       
        <div className={`segmented-control-button ${selectedCompCharts === 5 && 'selected'} last-button`}>
            <div onClick={() => handleOptionChange(5)}>ESTOQUES</div>
        </div>
    </div>
    </div>
    </>
    
  );
}

export default ChartsCompSegControl;