import React, { useContext} from 'react'
import '../../css/planoSafra.css';
import PSContext from '../opoio/PSContext';
import GlobalStateContext from '../../components/GlobalStateContext';
import { getStdPlanoSafra } from '../../BME/Unidades/Geral/getStdPlanoSafra';
import { UseColors } from '../../components/ui/colors';
import { stdValue } from '../../Resources/setNumber';
import ModalScreenMessage from '../../components/ui/ModalScreenMessage';

function BodyTenYearPlan({seqSafras, listRevs, stdList, secs = {'':[]}}) {
  const {allPsData, firstCrop, selectedValues, setSelectedValues, psIsPrinting} = useContext(PSContext)
  const {site} = useContext(GlobalStateContext)

  const COLORS = UseColors()

  const stdPlanoVars = getStdPlanoSafra(site)

  const handleSelectChange = (event, item) => {
    const { value } = event.target;
    setSelectedValues((prevValues) => ({
      ...prevValues,
      [item]: value,
    }));
  };

  const filtGrps = stdPlanoVars.filter(item => item.show !== false)
     
  const uniqueGrps = [...new Set(filtGrps.map(item => item.grp))]


  if (allPsData){
    return (
      <>
          <div >
                  {firstCrop && seqSafras &&  <div className='psTableHead' 
                  style={psIsPrinting ? {fontSize: '10px', paddingTop: '2px', paddingBottom: '0px', height: '14px'} : {}}>
                      <div style={{width: '15%', textAlign: 'left'}}>Descrição</div>
                      <div style={{width: '5%', textAlign: 'left'}}>Un.</div>
                      {seqSafras.map((item, index) => {
                          return(
                              <div className='psHeadElement' style={{width: '6%'}} key={`headTable-safras-${item}`}>
                                  {item}
                              </div>
                          )
                      })}
                  </div>}
  
                  {firstCrop && seqSafras && listRevs &&(
    <div className='psTableHead' 
    style={psIsPrinting ? 
                  {fontSize: '10px', paddingBottom: '4px', height: '20px', paddingTop: '0px'} : 
                  {height: '2vh', marginTop: '-1vh', paddingTop: '0.2vh'}}>
      <div style={{width: '15%', textAlign: 'left'}}></div>
      <div style={{width: '5%', textAlign: 'left'}}></div>
      {seqSafras.map((item, index) => (
        <div className='psHeadElement' style={{width: '6%'}} key={`headTable-revs-${item}-${listRevs[item]}`}>
          {listRevs[item] && (
            <select 
              id={`comboBox${index}`}
              value={selectedValues[item] || ""}
              onChange={(event) => handleSelectChange(event, item)}
              style={psIsPrinting ?
                {color: 'white', marginRight: '0', height: '20px', background: 'rgba(0,0,0,0)', paddingTop: '0px', paddingBottom: '2px', border: '0'} :
                {color: 'white', width: '10vh', marginRight: '0vh', height: '3vh', background: 'rgba(0,0,0,0)', border: '0'}}
            >
              <option value=""></option> {/* Para casos onde nenhum valor é selecionado */}
              {Object.keys(listRevs[item]).map((i) => (
                <option value={listRevs[item][i]} key={i}>
                  {listRevs[item][i]}
                </option>
              ))}
            </select>
          )}
        </div>
      ))}
    </div>
  )}  
          </div>
  

          <div className='psBody' 
          style={psIsPrinting ?
            {maxHeight: '150vh', color: 'black', margin: '0'} :
            {maxHeight: '76.5vh', color: 'black'}
          }>
          {allPsData &&  seqSafras && firstCrop && stdList && selectedValues &&
          uniqueGrps
          .filter(secao => secs?.hasOwnProperty(secao) || secs.hasOwnProperty(''))
          .map(secao => {
              return (
                <>
                <div className='psSectionHead' 
                style={psIsPrinting ? {fontSize: '10px', padding: '4px 0', paddingLeft: '4px', paddingTop: '6px'} : {}}
                >{secao}</div>
                {stdPlanoVars
                .filter(i => i.grp === secao && i.show !== false)
                .map((item, index) => {
                  return (
                      <>
                        <div className={`psLine ${index%2 === 0 && "psLineImp"}`} 
                          style={psIsPrinting ? {fontSize: '11px', height: '18px', padding: '4px', color: 'black'} : {}}>
                          <div style={{width: '15%', textAlign: 'left'}}>{item.desc}</div>
                          <div style={{width: '5%', textAlign: 'left'}}>{item.unid}</div>
                          {seqSafras.map(safra => {
                              return(
                                  <div className='psHeadElement' style={{width: '6%'}} >
                                      {stdList[safra] && stdList[safra][selectedValues[safra]] ? stdValue(stdList[safra][selectedValues[safra]][secao][item.cod]) : ''}
                                  </div>
                              )
                          })}
                        </div>
                      </> 
                  )
                })}
                </>
            )    
          })}
          </div>
      </>
    )
  }else{
    return (
      <ModalScreenMessage texto={'Carregandos Daods...'}/>
    )
  }
}

export default BodyTenYearPlan



                    

