import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import {dadosPCTS, dadosMoagem, dadosFabAcucar, dadosDestilaria, dadosEnergia, dadosOutros, dadosGerAcucar, dadosGerEtanol, dadosGerRend, dadosEficART} from './stdBoletim'


const ExcelReader = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (!file) {
      setError('Nenhum arquivo foi selecionado');
      return;
    }

    const reader = new FileReader();

    reader.onload = (e) => {
      try {
        const binaryStr = e.target.result;
        const workbook = XLSX.read(binaryStr, { type: 'binary' });

        if (workbook.SheetNames.length === 0) {
          setError('O arquivo Excel não contém planilhas');
          return;
        }

        const sheetName = workbook.SheetNames[1];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, {
          defval: undefined,
          raw: true, // Opcional: mantém o formato original dos valores
        });

        if (jsonData.length === 0) {
          setError('A planilha selecionada está vazia');
          return;
        }

        let tempData = []
        
        jsonData.forEach((row, index) => {
            let item = {"data": `${row.__EMPTY_3}`, "codigo": `${row.__EMPTY_4}`,  "dia": `${row.__EMPTY_5}`, "semana": `${row.__EMPTY_6}`, "quinzena": `${row.__EMPTY_7}`, "mes": `${row.__EMPTY_8}`, "safra": `${row.__EMPTY_9}`}
            tempData.push(item)
        })

        // let polCana = tempData.filter {}

        setData(tempData);
        console.log(tempData)
        setError(null);
      } catch (error) {
        setError('Erro ao processar o arquivo Excel');
      }
    };

    reader.onerror = () => {
      setError('Erro ao ler o arquivo');
    };

    reader.readAsBinaryString(file);
  };

  async function updateBltDb(){

    let tempData = {}
    if (data[1].codigo === "%ACTOT"){
      for (const item of dadosPCTS){
        let valor = data.filter(row => row["codigo"] === item.codigo)[0]?.dia || null
        let newItem = {"data": data[1].data, "codigo": item.codigo, "descricao": item.descricao, "valor": valor === null || valor === 'undefined' ? null : parseFloat(valor)}
        tempData[item.codigo] = newItem
      }
      for (const item of dadosMoagem){
        let valor = data.filter(row => row["codigo"] === item.codigo)[0]?.dia || null
        let newItem = {"data": data[1].data, "codigo": item.codigo, "descricao": item.descricao, "valor": valor === null || valor === 'undefined' ? null : parseFloat(valor)}
        tempData[item.codigo] = newItem
      }
      for (const item of dadosFabAcucar){
        let valor = data.filter(row => row["codigo"] === item.codigo)[0]?.dia || null
        if (item.codigo === 'DIFPRAC'){
          let newItem = {"data": data[1].data, "codigo": item.codigo, "descricao": item.descricao, "valor": valor === null || valor === 'undefined' ? null : parseFloat(valor)/1000}
          tempData[item.codigo] = newItem
        }else{
          let newItem = {"data": data[1].data, "codigo": item.codigo, "descricao": item.descricao, "valor": valor === null || valor === 'undefined' ? null : parseFloat(valor)}
          tempData[item.codigo] = newItem
        }
      }
      for (const item of dadosDestilaria){
        let valor = data.filter(row => row["codigo"] === item.codigo)[0]?.dia || null
        if (item.codigo === 'DIFPRAL'){
          let newItem = {"data": data[1].data, "codigo": item.codigo, "descricao": item.descricao, "valor": valor === null || valor === 'undefined' ? null : parseFloat(valor)/1000}
          tempData[item.codigo] = newItem
        }else{
          let newItem = {"data": data[1].data, "codigo": item.codigo, "descricao": item.descricao, "valor": valor === null || valor === 'undefined' ? null : parseFloat(valor)}
          tempData[item.codigo] = newItem
        }
      }
      for (const item of dadosEnergia){
        let valor = data.filter(row => row["codigo"] === item.codigo)[0]?.dia || null
        let newItem = {"data": data[1].data, "codigo": item.codigo, "descricao": item.descricao, "valor": valor === null || valor === 'undefined' ? null : parseFloat(valor)}
        tempData[item.codigo] = newItem
      }
      for (const item of dadosOutros){
        let valor = data.filter(row => row["codigo"] === item.codigo)[0]?.dia || null
        let newItem = {"data": data[1].data, "codigo": item.codigo, "descricao": item.descricao, "valor": valor === null || valor === 'undefined' ? null : parseFloat(valor)}
        tempData[item.codigo] = newItem
      }
      for (const item of dadosEficART){
        let valor = data.filter(row => row["codigo"] === item.codigo)[0]?.dia || null
        let newItem = {"data": data[1].data, "codigo": item.codigo, "descricao": item.descricao, "valor": valor === null || valor === 'undefined' ? null : parseFloat(valor)}
        tempData[item.codigo] = newItem
      }
      let perdaBagaco = parseFloat(tempData['%ARTPEB']?.valor === null ? "0.0" : tempData['%ARTPEB']?.valor)
      let perdaTorta = parseFloat(tempData['%ARTPET']?.valor === null ? "0.0" : tempData['%ARTPET']?.valor)
      let perdaDest = parseFloat(tempData['%ARTPDSP']?.valor === null ? "0.0" : tempData['%ARTPDSP']?.valor)
      let perdaIndet = parseFloat(tempData['%ARTPD3']?.valor === null ? "0.0" : tempData['%ARTPD3']?.valor)
      let perdaLavCana = parseFloat(tempData['%ARTPLC']?.valor === null ? "0.0" : tempData['%ARTPLC']?.valor)
      let perdaMultij = parseFloat(tempData['%ARTPMJ']?.valor === null ? "0.0" : tempData['%ARTPMJ']?.valor)
      let perdaEfFab = parseFloat(tempData['%ARTEFT']?.valor === null ? "0.0" : tempData['%ARTEFT']?.valor)
      let perdaEfDest = parseFloat(tempData['%ARTPEFD']?.valor === null ? "0.0" : tempData['%ARTPEFD']?.valor)
      let perdaTot = perdaBagaco + perdaTorta + perdaDest + perdaIndet + perdaLavCana + perdaMultij + perdaEfFab + perdaEfDest
      let newItem = {"data": data[1].data, "codigo": "%ARTPETOT", "descricao": "Perdas Totais % ART", "valor": perdaTot}
      tempData["%ARTPETOT"] = newItem
    }else{
      for (const item of dadosGerAcucar){
        let valor = data.filter(row => row["codigo"] === item.codigo)[0]?.dia || null
        if (item.codigo === 'ACTOT'){
          let newItem = {"data": data[1].data, "codigo": item.codigo, "descricao": item.descricao, "valor": valor === null || valor === 'undefined' ? null : parseFloat(valor)/1000}
          tempData[item.codigo] = newItem
        }else{
          let newItem = {"data": data[1].data, "codigo": item.codigo, "descricao": item.descricao, "valor": valor === null || valor === 'undefined' ? null : parseFloat(valor)}
          tempData[item.codigo] = newItem
        }
      }
      for (const item of dadosGerEtanol){
        let valor = data.filter(row => row["codigo"] === item.codigo)[0]?.dia || null
        if (item.codigo === 'PENMOL' || item.codigo === 'ALCTOT'){
          let newItem = {"data": data[1].data, "codigo": item.codigo, "descricao": item.descricao, "valor": valor === null || valor === 'undefined' ? null : parseFloat(valor)/1000}
          tempData[item.codigo] = newItem
        }else{
          let newItem = {"data": data[1].data, "codigo": item.codigo, "descricao": item.descricao, "valor": valor === null || valor === 'undefined' ? null : parseFloat(valor)}
          tempData[item.codigo] = newItem
        }
        
        
      }
      let etHid = parseFloat(tempData['ALCHIDT']?.valor === null ? "0.0" : tempData['ALCHIDT']?.valor)
      let etHidJp = parseFloat(tempData['HJAPAO']?.valor === null ? "0.0" : tempData['HJAPAO']?.valor)
      let etHidCo = parseFloat(tempData['HCOREIA']?.valor === null ? "0.0" : tempData['HCOREIA']?.valor)
      let etHidNe = parseFloat(tempData['HIDNEUT']?.valor === null ? "0.0" : tempData['HIDNEUT']?.valor)
      let etHidExtNe = parseFloat(tempData['EXTNEUT']?.valor === null ? "0.0" : tempData['EXTNEUT']?.valor)
      let etHidTot = etHid + etHidJp + etHidCo
      let etHidNeTot = etHidNe + etHidExtNe
      let newItem = {"data": data[1].data, "codigo": "ACHIDTOT", "descricao": "Etanol Hid. Total", "valor": etHidTot/1000}
      tempData["ACHIDTOT"] = newItem
      let newItem2 = {"data": data[1].data, "codigo": "ACNEUTOT", "descricao": "Etanol Neutro Total", "valor": etHidNeTot/1000}
      tempData["ACNEUTOT"] = newItem2


      for (const item of dadosGerRend){
        let valor = data.filter(row => row["codigo"] === item.codigo)[0]?.dia || null
        let newItem = {"data": data[1].data, "codigo": item.codigo, "descricao": item.descricao, "valor": valor === null || valor === 'undefined' ? null : parseFloat(valor)}
        tempData[item.codigo] = newItem
      }
    }

    await fetch(''+global.endPoint+'/updateBltDb', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(tempData)
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Erro de requisição.');
      }else{
        console.log('updated')
      }
    })
    .catch(error => {
      // Lida com o erro da requisição
    })
  }


  return (
    <div style={{ padding: '2vh', textAlign: 'center', overflowY: 'scroll'}}>
      <div style={{marginBottom: '1vh', fontSize: '2vh', fontWeight: 'bold'}}>Importar Boletim</div>
      <input
        type="file"
        accept=".xls,.xlsx"
        onChange={handleFileUpload}
        style={{ margin: '5px' }}
      />
      {error && <div style={{ color: 'red' }}>{error}</div>}
      {data && 
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <div style={{textAlign: 'left', marginLeft: '2.5vh', fontWeight: 'bold', fontSize: '1.6vh'}}>
            Data: {data[1]?.data}
          </div>
          <div style={{textAlign: 'left', marginRight: '2.5vh', fontWeight: 'bold', fontSize: '1.6vh'}}>
            <button disabled={data ? false : true} className='green-button' style={{fontSize: '1.8vh'}} onClick={updateBltDb}>Salvar</button>
          </div>
        </div>}
     
        <div style={{marginLeft: '2vh', marginRight: '2vh', marginTop: '2vh', marginBottom: '0.5vh', fontWeight: 'bold'}}>
            <div className='fullLine'>
                <div className='fullLineRow' style={{width: '12%'}}>Código</div>
                <div className='fullLineRow' style={{width: '28%'}}>Descrição</div>
                <div className='fullLineRow' style={{width: '12%'}}>Dia</div>
                <div className='fullLineRow' style={{width: '12%'}}>Semana</div>
                <div className='fullLineRow' style={{width: '12%'}}>Quizena</div>
                <div className='fullLineRow' style={{width: '12%'}}>Mês</div>
                <div className='fullLineRow' style={{width: '12%'}}>Safra</div>
            </div>
      </div>
      {data && data[1].codigo === "%ACTOT" && <div style={{marginLeft: '2.5vh', marginRight: '2vh', marginTop: '1vh', marginBottom: '0.5vh', fontWeight: 'bold', fontSize: '1.5vh', textAlign: 'left'}}>DADOS PCTS</div>}
      {data && data[1].codigo === "%ACTOT" && dadosPCTS.map((item) => {
        let filteredData = data.filter(row => row["codigo"] === item.codigo)[0]
              return (
              <div style={{marginLeft: '2vh', marginRight: '2vh', backgroundColor: 'white', borderBottom: '1px solid #f2f2f7'}}>
          <div className='fullLine'>
              <div className='fullLineRowBol' style={{width: '12%'}}>{item.codigo}</div>
              <div className='fullLineRowBol' style={{width: '28%'}}>{item.descricao}</div>
              <div className='fullLineRowBol' style={{width: '12%'}}>{filteredData?.dia === 'undefined' ? null : filteredData?.dia}</div>
              <div className='fullLineRowBol' style={{width: '12%'}}>{filteredData?.semana === 'undefined' ? null : filteredData?.semana}</div>
              <div className='fullLineRowBol' style={{width: '12%'}}>{filteredData?.quinzena === 'undefined' ? null : filteredData?.quinzena}</div>
              <div className='fullLineRowBol' style={{width: '12%'}}>{filteredData?.mes === 'undefined' ? null : filteredData?.mes}</div>
              <div className='fullLineRowBol' style={{width: '12%'}}>{filteredData?.safra === 'undefined' ? null : filteredData?.safra}</div>
          </div>
      </div> 
      )})}

      {data && data[1].codigo === "%ACTOT" && <div style={{marginLeft: '2.5vh', marginRight: '2vh', marginTop: '1vh', marginBottom: '0.5vh', fontWeight: 'bold', fontSize: '1.5vh', textAlign: 'left'}}>MOENDAS</div>}
      {data && data[1].codigo === "%ACTOT" &&  dadosMoagem.map((item) => {
        let filteredData = data.filter(row => row["codigo"] === item.codigo)[0]
            return (
              <div style={{marginLeft: '2vh', marginRight: '2vh', backgroundColor: 'white', borderBottom: '1px solid #f2f2f7'}}>
          <div className='fullLine'>
              <div className='fullLineRowBol' style={{width: '12%'}}>{item.codigo}</div>
              <div className='fullLineRowBol' style={{width: '28%'}}>{item.descricao}</div>
              <div className='fullLineRowBol' style={{width: '12%'}}>{filteredData?.dia === 'undefined' ? null : filteredData?.dia}</div>
              <div className='fullLineRowBol' style={{width: '12%'}}>{filteredData?.semana === 'undefined' ? null : filteredData?.semana}</div>
              <div className='fullLineRowBol' style={{width: '12%'}}>{filteredData?.quinzena === 'undefined' ? null : filteredData?.quinzena}</div>
              <div className='fullLineRowBol' style={{width: '12%'}}>{filteredData?.mes === 'undefined' ? null : filteredData?.mes}</div>
              <div className='fullLineRowBol' style={{width: '12%'}}>{filteredData?.safra === 'undefined' ? null : filteredData?.safra}</div>
          </div>
      </div> 
      )})}

      {data && data[1].codigo === "%ACTOT" && <div style={{marginLeft: '2.5vh', marginRight: '2vh', marginTop: '1vh', marginBottom: '0.5vh', fontWeight: 'bold', fontSize: '1.5vh', textAlign: 'left'}}>FABRICAÇÃO AÇÚCAR</div>}
      {data && data[1].codigo === "%ACTOT" &&  dadosFabAcucar.map((item) => {
        let filteredData = data.filter(row => row["codigo"] === item.codigo)[0]
        let d = 1
        if (item.codigo === 'DIFPRAC'){
          d = 50
        }
            return (
              <div style={{marginLeft: '2vh', marginRight: '2vh', backgroundColor: 'white', borderBottom: '1px solid #f2f2f7'}}>
          <div className='fullLine'>
              <div className='fullLineRowBol' style={{width: '12%'}}>{item.codigo}</div>
              <div className='fullLineRowBol' style={{width: '28%'}}>{item.descricao}</div>
              <div className='fullLineRowBol' style={{width: '12%'}}>{filteredData?.dia === 'undefined' ? null : d !== 1 ? filteredData?.dia/d : filteredData?.dia}</div>
              <div className='fullLineRowBol' style={{width: '12%'}}>{filteredData?.semana === 'undefined' ? null : d !== 1 ? filteredData?.semana/d :filteredData?.semana}</div>
              <div className='fullLineRowBol' style={{width: '12%'}}>{filteredData?.quinzena === 'undefined' ? null : d !== 1 ? filteredData?.quinzena/d : filteredData?.quinzena}</div>
              <div className='fullLineRowBol' style={{width: '12%'}}>{filteredData?.mes === 'undefined' ? null : d !== 1 ? filteredData?.mes/d : filteredData?.mes}</div>
              <div className='fullLineRowBol' style={{width: '12%'}}>{filteredData?.safra === 'undefined' ? null : d !== 1 ? filteredData?.safra/d : filteredData?.safra}</div>
          </div>
      </div> 
      )})}

        {data && data[1].codigo === "%ACTOT" && <div style={{marginLeft: '2.5vh', marginRight: '2vh', marginTop: '1vh', marginBottom: '0.5vh', fontWeight: 'bold', fontSize: '1.5vh', textAlign: 'left'}}>FERMENTAÇÃO / DESTILARIA</div>}
        {data && data[1].codigo === "%ACTOT" && dadosDestilaria.map((item) => {
          let filteredData = data.filter(row => row["codigo"] === item.codigo)[0]
              return (
                <div style={{marginLeft: '2vh', marginRight: '2vh', backgroundColor: 'white', borderBottom: '1px solid #f2f2f7'}}>
            <div className='fullLine'>
                <div className='fullLineRowBol' style={{width: '12%'}}>{item.codigo}</div>
                <div className='fullLineRowBol' style={{width: '28%'}}>{item.descricao}</div>
                <div className='fullLineRowBol' style={{width: '12%'}}>{filteredData?.dia === 'undefined' ? null : filteredData?.dia}</div>
                <div className='fullLineRowBol' style={{width: '12%'}}>{filteredData?.semana === 'undefined' ? null : filteredData?.semana}</div>
                <div className='fullLineRowBol' style={{width: '12%'}}>{filteredData?.quinzena === 'undefined' ? null : filteredData?.quinzena}</div>
                <div className='fullLineRowBol' style={{width: '12%'}}>{filteredData?.mes === 'undefined' ? null : filteredData?.mes}</div>
                <div className='fullLineRowBol' style={{width: '12%'}}>{filteredData?.safra === 'undefined' ? null : filteredData?.safra}</div>
            </div>
        </div> 
        )})}

        {data && data[1].codigo === "%ACTOT" && <div style={{marginLeft: '2.5vh', marginRight: '2vh', marginTop: '1vh', marginBottom: '0.5vh', fontWeight: 'bold', fontSize: '1.5vh', textAlign: 'left'}}>ENERGIA</div>}
        {data && data[1].codigo === "%ACTOT" &&  dadosEnergia.map((item) => {
          let filteredData = data.filter(row => row["codigo"] === item.codigo)[0]
              return (
                <div style={{marginLeft: '2vh', marginRight: '2vh', backgroundColor: 'white', borderBottom: '1px solid #f2f2f7'}}>
            <div className='fullLine'>
                <div className='fullLineRowBol' style={{width: '12%'}}>{item.codigo}</div>
                <div className='fullLineRowBol' style={{width: '28%'}}>{item.descricao}</div>
                <div className='fullLineRowBol' style={{width: '12%'}}>{filteredData?.dia === 'undefined' ? null : filteredData?.dia}</div>
                <div className='fullLineRowBol' style={{width: '12%'}}>{filteredData?.semana === 'undefined' ? null : filteredData?.semana}</div>
                <div className='fullLineRowBol' style={{width: '12%'}}>{filteredData?.quinzena === 'undefined' ? null : filteredData?.quinzena}</div>
                <div className='fullLineRowBol' style={{width: '12%'}}>{filteredData?.mes === 'undefined' ? null : filteredData?.mes}</div>
                <div className='fullLineRowBol' style={{width: '12%'}}>{filteredData?.safra === 'undefined' ? null : filteredData?.safra}</div>
            </div>
        </div> 
        )})}

        {data && data[1].codigo === "%ACTOT" && <div style={{marginLeft: '2.5vh', marginRight: '2vh', marginTop: '1vh', marginBottom: '0.5vh', fontWeight: 'bold', fontSize: '1.5vh', textAlign: 'left'}}>EFICIÊNCIA / BALANÇO ART</div>}
        {data && data[1].codigo === "%ACTOT" &&  dadosEficART.map((item) => {
          let filteredData = data.filter(row => row["codigo"] === item.codigo)[0]
              return (
                <div style={{marginLeft: '2vh', marginRight: '2vh', backgroundColor: 'white', borderBottom: '1px solid #f2f2f7'}}>
            <div className='fullLine'>
                <div className='fullLineRowBol' style={{width: '12%'}}>{item.codigo}</div>
                <div className='fullLineRowBol' style={{width: '28%'}}>{item.descricao}</div>
                <div className='fullLineRowBol' style={{width: '12%'}}>{filteredData?.dia === 'undefined' ? null : filteredData?.dia}</div>
                <div className='fullLineRowBol' style={{width: '12%'}}>{filteredData?.semana === 'undefined' ? null : filteredData?.semana}</div>
                <div className='fullLineRowBol' style={{width: '12%'}}>{filteredData?.quinzena === 'undefined' ? null : filteredData?.quinzena}</div>
                <div className='fullLineRowBol' style={{width: '12%'}}>{filteredData?.mes === 'undefined' ? null : filteredData?.mes}</div>
                <div className='fullLineRowBol' style={{width: '12%'}}>{filteredData?.safra === 'undefined' ? null : filteredData?.safra}</div>
            </div>
        </div> 
        )})}

        {data && data[1].codigo === "%ACTOT" && <div style={{marginLeft: '2.5vh', marginRight: '2vh', marginTop: '1vh', marginBottom: '0.5vh', fontWeight: 'bold', fontSize: '1.5vh', textAlign: 'left'}}>OUTROS</div>}
        {data && data[1].codigo === "%ACTOT" &&  dadosOutros.map((item) => {
          let filteredData = data.filter(row => row["codigo"] === item.codigo)[0]
              return (
                <div style={{marginLeft: '2vh', marginRight: '2vh', backgroundColor: 'white', borderBottom: '1px solid #f2f2f7'}}>
            <div className='fullLine'>
                <div className='fullLineRowBol' style={{width: '12%'}}>{item.codigo}</div>
                <div className='fullLineRowBol' style={{width: '28%'}}>{item.descricao}</div>
                <div className='fullLineRowBol' style={{width: '12%'}}>{filteredData?.dia === 'undefined' ? null : filteredData?.dia}</div>
                <div className='fullLineRowBol' style={{width: '12%'}}>{filteredData?.semana === 'undefined' ? null : filteredData?.semana}</div>
                <div className='fullLineRowBol' style={{width: '12%'}}>{filteredData?.quinzena === 'undefined' ? null : filteredData?.quinzena}</div>
                <div className='fullLineRowBol' style={{width: '12%'}}>{filteredData?.mes === 'undefined' ? null : filteredData?.mes}</div>
                <div className='fullLineRowBol' style={{width: '12%'}}>{filteredData?.safra === 'undefined' ? null : filteredData?.safra}</div>
            </div>
        </div> 
        )})}

        {data && data[1].codigo === "%ARTENS" && <div style={{marginLeft: '2.5vh', marginRight: '2vh', marginTop: '1vh', marginBottom: '0.5vh', fontWeight: 'bold', fontSize: '1.5vh', textAlign: 'left'}}>PRODUÇÃO AÇÚCAR</div>}
        {data && data[1].codigo === "%ARTENS" &&  dadosGerAcucar.map((item) => {
          let filteredData = data.filter(row => row["codigo"] === item.codigo)[0]
              return (
                <div style={{marginLeft: '2vh', marginRight: '2vh', backgroundColor: 'white', borderBottom: '1px solid #f2f2f7'}}>
            <div className='fullLine'>
                <div className='fullLineRowBol' style={{width: '12%'}}>{item.codigo}</div>
                <div className='fullLineRowBol' style={{width: '28%'}}>{item.descricao}</div>
                <div className='fullLineRowBol' style={{width: '12%'}}>{filteredData?.dia === 'undefined' ? null : parseFloat(filteredData?.dia).toLocaleString('pt-BR')}</div>
                <div className='fullLineRowBol' style={{width: '12%'}}>{filteredData?.semana === 'undefined' ? null : parseFloat(filteredData?.semana).toLocaleString('pt-BR')}</div>
                <div className='fullLineRowBol' style={{width: '12%'}}>{filteredData?.quinzena === 'undefined' ? null : parseFloat(filteredData?.quinzena).toLocaleString('pt-BR')}</div>
                <div className='fullLineRowBol' style={{width: '12%'}}>{filteredData?.mes === 'undefined' ? null : parseFloat(filteredData?.mes).toLocaleString('pt-BR')}</div>
                <div className='fullLineRowBol' style={{width: '12%'}}>{filteredData?.safra === 'undefined' ? null : parseFloat(filteredData?.safra).toLocaleString('pt-BR')}</div>
            </div>
        </div> 
        )})}

        {data && data[1].codigo === "%ARTENS" && <div style={{marginLeft: '2.5vh', marginRight: '2vh', marginTop: '1vh', marginBottom: '0.5vh', fontWeight: 'bold', fontSize: '1.5vh', textAlign: 'left'}}>PRODUÇÃO ETANOL</div>}
        {data && data[1].codigo === "%ARTENS" &&  dadosGerEtanol.map((item) => {
          let filteredData = data.filter(row => row["codigo"] === item.codigo)[0]
              return (
                <div style={{marginLeft: '2vh', marginRight: '2vh', backgroundColor: 'white', borderBottom: '1px solid #f2f2f7'}}>
            <div className='fullLine'>
                <div className='fullLineRowBol' style={{width: '12%'}}>{item.codigo}</div>
                <div className='fullLineRowBol' style={{width: '28%'}}>{item.descricao}</div>
                <div className='fullLineRowBol' style={{width: '12%'}}>{filteredData?.dia === 'undefined' ? null : parseFloat(filteredData?.dia).toLocaleString('pt-BR')}</div>
                <div className='fullLineRowBol' style={{width: '12%'}}>{filteredData?.semana === 'undefined' ? null : parseFloat(filteredData?.semana).toLocaleString('pt-BR')}</div>
                <div className='fullLineRowBol' style={{width: '12%'}}>{filteredData?.quinzena === 'undefined' ? null : parseFloat(filteredData?.quinzena).toLocaleString('pt-BR')}</div>
                <div className='fullLineRowBol' style={{width: '12%'}}>{filteredData?.mes === 'undefined' ? null : parseFloat(filteredData?.mes).toLocaleString('pt-BR')}</div>
                <div className='fullLineRowBol' style={{width: '12%'}}>{filteredData?.safra === 'undefined' ? null : parseFloat(filteredData?.safra).toLocaleString('pt-BR')}</div>
            </div>
        </div> 
        )})}

        {data && data[1].codigo === "%ARTENS" && <div style={{marginLeft: '2.5vh', marginRight: '2vh', marginTop: '1vh', marginBottom: '0.5vh', fontWeight: 'bold', fontSize: '1.5vh', textAlign: 'left'}}>RENDIMENTOS</div>}
        {data && data[1].codigo === "%ARTENS" &&  dadosGerRend.map((item) => {
          let filteredData = data.filter(row => row["codigo"] === item.codigo)[0]
              return (
                <div style={{marginLeft: '2vh', marginRight: '2vh', backgroundColor: 'white', borderBottom: '1px solid #f2f2f7'}}>
            <div className='fullLine'>
                <div className='fullLineRowBol' style={{width: '12%'}}>{item.codigo}</div>
                <div className='fullLineRowBol' style={{width: '28%'}}>{item.descricao}</div>
                <div className='fullLineRowBol' style={{width: '12%'}}>{filteredData?.dia === 'undefined' ? null : parseFloat(filteredData?.dia).toLocaleString('pt-BR')}</div>
                <div className='fullLineRowBol' style={{width: '12%'}}>{filteredData?.semana === 'undefined' ? null : parseFloat(filteredData?.semana).toLocaleString('pt-BR')}</div>
                <div className='fullLineRowBol' style={{width: '12%'}}>{filteredData?.quinzena === 'undefined' ? null : parseFloat(filteredData?.quinzena).toLocaleString('pt-BR')}</div>
                <div className='fullLineRowBol' style={{width: '12%'}}>{filteredData?.mes === 'undefined' ? null : parseFloat(filteredData?.mes).toLocaleString('pt-BR')}</div>
                <div className='fullLineRowBol' style={{width: '12%'}}>{filteredData?.safra === 'undefined' ? null : parseFloat(filteredData?.safra).toLocaleString('pt-BR')}</div>
            </div>
        </div> 
        )})}
      
    </div>
  );
};

export default ExcelReader;
