import React, { useContext, useEffect } from 'react'
import '../../css/InputLine.css'
import InputLine from './InputLine'
import OutputLine from './OutputLine'
import ComboLine from './ComboLine'
import SwitchLine from './SwitchLine'
import GlobalStateContext from '../GlobalStateContext'
import CapLine from './CapLine'
import CapVapLine from './CapVapLine'
import InputLineMax from './InputLineMax'
import InputLineMin from './InputLineMin'
import IptLineCap from '../inputLines/iptLineCap'
import IptLineCapVap from '../inputLines/iptLineCapVap'
import IptLineMaxOp from '../inputLines/iptLineMaxOp'

function ListLine({ ipt, cod, desc, handleCountChange }) {
    const {data, isPrinting } = useContext(GlobalStateContext);
  
    const vh = window.innerHeight;
    const ideal = 727;
    let nVH = ideal / vh;

  
     if (data[cod]?.hide === true) {
      return <></>
    }else if (ipt === true && !isPrinting) {
      return <InputLine cod={cod} key={cod}/>;
    } else if (ipt === true && isPrinting) {
      return <OutputLine cod={cod} />;
    }else if (ipt === 'max') {
      return <InputLineMax cod={cod} desc={desc}/>
    }else if (ipt === 'min') {
      return <InputLineMin cod={cod} desc={desc}/>
    }else if (ipt === false) {
      return <OutputLine cod={cod} />
    }else if (ipt === "switch") {
      return <SwitchLine cod={cod} handleCountChange={handleCountChange} />
    }else if (ipt === "cap") {
      return <CapLine cod={cod} handleCountChange={handleCountChange} />
    }else if (ipt === "cap2") {
      return <IptLineCap cod={cod} />
    }else if (ipt === "maxOp") {
      return <IptLineMaxOp cod={cod} />
    }else if (ipt === "capVap") {
      return <CapVapLine cod={cod} handleCountChange={handleCountChange} />
    }else if (ipt === "capVap2") {
      return <IptLineCapVap cod={cod} />
    }else if (ipt === "combo") {
        return <ComboLine cod={cod} handleCountChange={handleCountChange} />
    }else {
        return <></>
    }
  }
  
  export default ListLine;
  