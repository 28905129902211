import React, {useContext, useEffect, useState } from 'react';
import PSContext from '../opoio/PSContext';
import { LuFileEdit, LuFile } from "react-icons/lu";
import GlobalStateContext from '../../components/GlobalStateContext';
import MenuBtDivider from '../../components/ui/menuBtDivider';
import psHooks from '../../Hooks/psHooks';
import { getStdPlanoSafra } from '../../BME/Unidades/Geral/getStdPlanoSafra';
import {usePsCalcs} from '../../BME/Unidades/Geral/usePsCalcs';

export default function BtEditPS() {
  const {site, user} = useContext(GlobalStateContext)
  const [stdPlanoVars, setStdPlanoVars] = useState(null)
  
  const {psIsSaved, setPsIsSaved, setIsEdit, isOpen, isEdit, isChart, psData, setPsData, setIsNew, setIsSaveAs} = useContext(PSContext)
  const { updatePS } = psHooks();
  const psCalcs = usePsCalcs(site)

  useEffect(() => {
      const std = getStdPlanoSafra(site) 
      setStdPlanoVars(std)
  }, [site]);

    const btClicked = async () => {
      setIsNew(false)
      setIsSaveAs(false)
      setIsEdit(!isEdit)
    }

    const btSaveClicked = async () => {
      await update()
      setIsEdit(!isEdit)
      setPsIsSaved(true)
    }

    async function update(){
      // console.log('PsData', psData)
      // let psData2 = await removeNonExistingFromPsData(stdPlanoVars, psData)
      // console.log('PsData2', psData2)
      let psData3 = await addToPsData(stdPlanoVars, psData, site)
      // console.log('PsData3', psData3)
      let tempData = await psCalcs.calcPS(psData3, site)
      // console.log('tempData', tempData)
      setPsData(tempData)
      updatePS(tempData, user.email)
      if (tempData[0].revisao === '13_Realizado'){
        psCalcs.calcRevsFromSafra(site, tempData[0].safra)
      }
    }

    if (!isOpen && !isEdit && !isChart){
      return (
        <div style={{display: 'flex', flexDirection: 'row'}}>
          <MenuBtDivider/>
            <button className='psButtom' disabled={!psData ? true : false} onClick={btClicked}>
            <div className='psMenuButtom' >
        <LuFileEdit  className='psMenuIcon'/>
        <div className='psMenuText'>EDITAR</div></div>
            </button>
        </div>
      )
    }else if (!isOpen && isEdit && !isChart){
      return (
        <div style={{display: 'flex', flexDirection: 'row'}}>
            <button className='psButtom' onClick={btSaveClicked}
            >
            <div className='psMenuButtom' >
        <LuFile  className='psMenuIcon' />
        <div className='psMenuText'>SALVAR</div>
        {!psIsSaved && <div className='psAlert'>!</div>}
        </div>
            </button>
    </div>
      )
    }
}


async function removeNonExistingFromPsData(stdPlanoVars, psData) {
  // Filtra psData e mantém apenas os itens que existem em stdPlanosVars
  return psData.filter(item => 
    stdPlanoVars.some(stdItem => stdItem.cod === item.codigo)
  );
}

async function addToPsData(stdPlanoVars, psData, site) {
  let psData2 = psData
  for (const item in stdPlanoVars){

    const existingItem = psData.filter(i => i.codigo === stdPlanoVars[item].cod);
    // console.log('Item to Add', item, stdPlanoVars[item].cod, existingItem, stdPlanoVars[item].show)
  
  if (existingItem.length === 0 && stdPlanoVars[item].show !== false) {
    // console.log('Existing Item', existingItem)
    // Novo objeto a ser adicionado
    const newItem = {
      abr: 0,
      acu: 0,
      ago: 0,
      dez: 0,
      fev: 0,
      jan: 0,
      jul: 0,
      jun: 0,
      mai: 0,
      mar: 0,
      nov: 0,
      out: 0,
      set: 0,
      ssr: `${site}-${psData[0].safra}-${psData[0].revisao}`, // Defina um valor relevante aqui
      input: stdPlanoVars[item].ipt,
      safra: psData[0].safra, // Defina o valor apropriado
      secao: stdPlanoVars[item].grp,
      codigo: stdPlanoVars[item].cod,
      siteId: site, // Defina o valor apropriado
      revisao: psData[0].revisao, // Defina o valor apropriado
      unidade: stdPlanoVars[item].unid,
      descricao: stdPlanoVars[item].desc
    };

    psData2.push(newItem);
  }

  }
  
  
  return psData2;
}
