import React, {useContext, useEffect, useState } from 'react';
import PSContext from '../opoio/PSContext';
import GlobalStateContext from '../../components/GlobalStateContext';
import { UseColors } from '../../components/ui/colors';
import ChartsCompSegControl from './ChartsCompSegControl';
import PsBarChart from '../charts/barChart';
import { getPsChartsData } from '../../BME/Unidades/Geral/getPsChartsData';

export default function ChartsCompRevs({page = '', h='36vh'}) {
  const {psData1, psData2, selectedCompCharts, psIsPrinting} = useContext(PSContext)
  const {site} = useContext(GlobalStateContext)
  const [chartsData, setChartsData] = useState(null);

  const COLORS = UseColors()

  useEffect(() => {
    const fetchData = async () => {
        const result = await getPsChartsData(site);
        setChartsData(result);
    };

    fetchData();
}, []);


  return (
    <>
    {!psIsPrinting && <ChartsCompSegControl/>}
     
    {psData1 && psData2 && chartsData &&
    Object.keys(chartsData).map((item, index) => {
        if ((page === '' && selectedCompCharts === index) || (page !== '' && item === page)) {
            return(
                <div className='psBody' style={psIsPrinting ? {maxHeight: '150vh'} : {maxHeight: '80vh'}}>
                    {chartsData[item].map((item, index) => (
                        <div style={{display: 'flex', direction:'row', justifyContent: 'space-between', marginLeft: '1vh', marginRight: '1vh', marginBottom: '0.5vh'}}>
                           {Object.entries(item).map(cod => {
                            if (cod[1] !== ''){
                                return (<PsBarChart cod={cod[1]} h={h}/>)
                                
                            }else{
                                return (<div style={{height: h, width: '100%', borderRadius: '2%',  margin: '1vh'}}></div>)
                            }      
        })}
                        </div>
                    ))
                    }
                </div>
            )
        }
    })}
    </>
  )
  
}