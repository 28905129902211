import React, { useState, useContext, useEffect, useRef } from 'react'
import GlobalStateContext from '../GlobalStateContext'
import SegmentedControl from "./ScreenSegs";
import { Link, useNavigate } from 'react-router-dom';



function ExtracaoSeg() {
  const { setAlert, data, user, setScreen, screen, area, site } = useContext(GlobalStateContext);
 
  const [selectedValue1, setSelectedValue1] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    // setScreen('fluxograma')
    // navigate('/'+area+'/fluxograma')
    setSelectedValue1(0)
    // if (area){
        // updateValue(0)
        // console.log('use effect')
    // }
    
  }, [area]);


  const updateValue = async (val) => {
    switch (val){
        case 1:
            setScreen('fluxos')
            navigate('/'+area+'/fluxos');
            break
        case 2: 
            setScreen('flxAcionamentos')
            navigate('/extracao/flxAcionamentos')
            break
        case 3: 
            setScreen('acionM1')
            navigate('/extracao/acionM1')
            break
        case 4: 
            setScreen('acionM2')
            navigate('/extracao/acionM2')
            break
        case 5: 
            setScreen('regen')
            navigate('/'+area+'/regeneradores')
            break
        case 6: 
            setScreen('aquec')
            navigate('/'+area+'/aquecedores')
            break
        case 7: 
            setScreen('equipamentos')
            navigate('/'+area+'/equipamentos')
            break
        case 8: 
            setScreen('premissas')
            navigate('/'+area+'/premissas')
            break
        case 9: 
            setScreen('turboger')
            navigate('/vapor/turboger')
            break
        case 10: 
            setScreen('boilers')
            navigate('/vapor/caldeiras')
            break
        case 11:
            setScreen('capacidades')
            navigate('/'+area+'/capacidades')
            break
        default:
            setScreen('fluxograma')
            navigate('/'+area+'/fluxograma')
    }
  }

 function getSeg(){
    // console.log(area)
    
    switch (area){
        case 'extracao': 

          if (site === 'usSantaElisa'){
            return getSegExtracaoAcion
          }if (site === 'usCevasa'){
            return getSegExtracaoDif
          }else{
            return getSegExtracao
          }
        
        case 'tratAcucar': 
          if (site === 'usSantaElisa'){
            return getSegTratFabricaSEL
          }else{
            return getSegTratFabrica
          }
        case 'tratEtanol': 
          if (site === 'usSantaElisa'){
            return getSegTratEtanolSEL
          }else if (site === 'usSonora'){
            return getSegTratEtanolSON
          }else{
            return getSegTratEtanol
          }
        case 'flotador': return getSegFlot
        case 'fabrica': return getSegFab
        case 'filtLodo': return getSegFilt
        case 'fermentacao': return getSegFerm
        case 'evaporacao': return getSegFerm
        case 'destilaria': return getSegDest
        case 'vapor': 
          if (site === 'usLins'){
            return getSegVap2
          }else{
              return getSegVap
            }
        default: return getSegStd
    }
 }

  const getSegExtracao = [
    {label: "FLUXOGRAMA",  value: 0, ref: useRef()},
    {label: "FLUXOS", value: 1, ref: useRef()},
    {label: "EQUIPAMENTOS", value: 3, ref: useRef()},
    {label: "CAPACIDADES", value: 11, ref: useRef()}
  ]

  const getSegExtracaoDif = [
    {label: "FLUXOGRAMA",  value: 0, ref: useRef()},
    {label: "FLUXOS", value: 1, ref: useRef()},
    {label: "EQUIPAMENTOS", value: 3, ref: useRef()},
    {label: "AQUECEDORES", value: 6, ref: useRef()},
    {label: "CAPACIDADES", value: 11, ref: useRef()}
  ]

  const getSegExtracaoAcion = [
    {label: "FLUXOGRAMA",  value: 0, ref: useRef()},
    {label: "FLUXOS", value: 1, ref: useRef()},
    {label: "FLX. ACION.", value: 2, ref: useRef()},
    {label: "ACION. FARREL", value: 3, ref: useRef()},
    {label: "ACION. ZANINI", value: 4, ref: useRef()},
    {label: "CAPACIDADES", value: 11, ref: useRef()}
  ]

  const getSegTratFabrica = [
    {label: "FLUXOGRAMA",  value: 0, ref: useRef()},
    {label: "FLUXOS", value: 1, ref: useRef()},
    {label: "REGENERADORES", value: 5, ref: useRef()},
    {label: "AQUECEDORES", value: 6, ref: useRef()},
    {label: "CAPACIDADES", value: 11, ref: useRef()}
  ]

  const getSegTratFabricaSEL = [
    {label: "FLUXOGRAMA",  value: 0, ref: useRef()},
    {label: "FLUXOS", value: 1, ref: useRef()},
    {label: "REGENERADORES", value: 5, ref: useRef()},
    {label: "AQUECEDORES", value: 6, ref: useRef()},
  ]

  const getSegTratEtanol = [
    {label: "FLUXOGRAMA",  value: 0, ref: useRef()},
    {label: "FLUXOS", value: 1, ref: useRef()},
    {label: "AQUECEDORES", value: 6, ref: useRef()},
    {label: "CAPACIDADES", value: 11, ref: useRef()}
  ]

  const getSegTratEtanolSON = [
    {label: "FLUXOGRAMA",  value: 0, ref: useRef()},
    {label: "FLUXOS", value: 1, ref: useRef()},
    {label: "EQUIPAMENTOS", value: 7, ref: useRef()},
    {label: "CAPACIDADES", value: 11, ref: useRef()}
  ]

  const getSegTratEtanolSEL = [
    {label: "FLUXOGRAMA",  value: 0, ref: useRef()},
    {label: "FLUXOS", value: 1, ref: useRef()},
    {label: "EQUIPAMENTOS", value: 7, ref: useRef()}
  ]

  const getSegStd = [
    {label: "FLUXOGRAMA",  value: 0, ref: useRef()},
    {label: "FLUXOS", value: 1, ref: useRef()},
    {label: "EQUIPAMENTOS", value: 7, ref: useRef()}
  ]

  const getSegFilt = [
    {label: "FLUXOGRAMA",  value: 0, ref: useRef()},
    {label: "EQUIPAMENTOS", value: 7, ref: useRef()}
  ]

  const getSegDest = [
    {label: "FLUXOGRAMA",  value: 0, ref: useRef()},
    {label: "PREMISSAS", value: 8, ref: useRef()},
    {label: "EQUIPAMENTOS", value: 7, ref: useRef()}
  ]

  const getSegFlot = [
    {label: "FLUXOGRAMA",  value: 0, ref: useRef()},
    {label: "PREMISSAS", value: 8, ref: useRef()},
    {label: "CAPACIDADES", value: 11, ref: useRef()}
  ]

  const getSegFab = [
    {label: "FLUXOGRAMA",  value: 0, ref: useRef()},
    {label: "PREMISSAS", value: 8, ref: useRef()},
    {label: "FLUXOS", value: 1, ref: useRef()},
    {label: "EQUIPAMENTOS", value: 7, ref: useRef()},
    {label: "CAPACIDADES", value: 11, ref: useRef()}
  ]

  const getSegFerm = [
    {label: "FLUXOGRAMA",  value: 0, ref: useRef()},
    {label: "FLUXOS", value: 1, ref: useRef()},
    {label: "EQUIPAMENTOS", value: 7, ref: useRef()},
    {label: "CAPACIDADES", value: 11, ref: useRef()}
  ]

  const getSegVap = [
    {label: "FLUXOGRAMA",  value: 0, ref: useRef()},
    {label: "PREMISSAS", value: 8, ref: useRef()},
    {label: "TGs", value: 9, ref: useRef()},
    {label: "CALDEIRAS", value: 10, ref: useRef()},
    {label: "CAPACIDADES", value: 11, ref: useRef()}
  ]

  const getSegVap2 = [
    {label: "FLUXOGRAMA",  value: 0, ref: useRef()},
    {label: "PREMISSAS", value: 8, ref: useRef()},
    {label: "EQUIPAMENTOS", value: 7, ref: useRef()},
    {label: "CAPACIDADES", value: 11, ref: useRef()}
  ]

  return (
    <SegmentedControl 
      descricao={''}
        name="group-1"
        callback={(val) => updateValue(val)}
        controlRef={useRef()}
        defaultIndex={selectedValue1}
        segments={getSeg()}
        screen={screen}
        area={area}
      />
  )
}

export default ExtracaoSeg

