import React, { useState, useContext, useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { stdValue } from '../../../../Resources/setNumber'
import { dados } from './stdBoletim';
import AnalisesContext from '../../../../ANALISES/AnalisesContext';
import { FaCheck } from "react-icons/fa";
import { FaCircleArrowUp, FaCircleArrowDown, FaCircleArrowLeft } from "react-icons/fa6";
import {UseColors} from '../../../../components/ui/colors'

const DayAnalyzerBltXPlano = () => {
    const [secoes, setSecoes] = useState(null);
    const {psAnData, bltData, anDate} = useContext(AnalisesContext)
    const [groupData, setGroupData] = useState(null)
    const COLORS = UseColors()

    // useEffect(() => {
    //   if (bltData === null && global.bltData !== null){
    //     setBltData(global.bltData)
    //     setSelectedDate(global.selectedDate)
    //   }else{
    //     global.bltData = bltData
    //     global.selectedDate = selectedDate
    //   }
    // }, [bltData]);

    useEffect(() => {
      const agruparPorSecao = () => {
        return dados.reduce((resultado, item) => {
          if (item.psCod && codsToCompare.includes(item.psCod)){
              // Se a seção ainda não existe no objeto, crie-a
          if (!resultado[item.secao]) {
            resultado[item.secao] = [];
          }
          // Adicione o item à sua respectiva seção
          resultado[item.secao].push(item);
        }
          return resultado;
         
          
        }, {});
      }
      // if (!groupData){
        let group = agruparPorSecao()
        setGroupData(group)
      // }
      console.log(bltData, psAnData)
    }, []);

    const codsToCompare = ['psFibraCana', 'psAtrDigestor', 'psAtrPcts', 'psBagacoCana', 'psUptimeGeral', 'psUpTimeVapor', 'psMoagemDiaria',
      'psTaxaMoagem', 'psMixAcucar', 'psArtRecuperado', 'psRTC', 'psEficienciaIndustrial', 'psPerdaLavavem', 'psPerdaBagaco', 'psPerdaTorta',
      'psPerdaMultijato', 'Perda Multijatos', 'psPerdaFermentacao', 'psPerdaResiduaria', 'Perda Residuária', 'psPerdaIndeterminada', 'psPerdaArtSaidaVacuo', 'psPerdaArtSaidaEvap', 'psTaxaEfProdAcucarScs',
      'psProdEfEtanolAnidro', 'psProdEfEtanolHidpTq','psProdEtanolTotal', 'psProdEfEtanol100', 'psConsVaporTc', 'psPtConsumidaTC', 'psProdBagacao', 'psConsBagacao', 'psVendaBagaco', 'psSobraBagaco'
    ]

    const inverter = ['psPerdaLavavem', 'psPerdaBagaco', 'psPerdaTorta',
      'psPerdaMultijato', 'Perda Multijatos', 'psPerdaFermentacao', 'psPerdaResiduaria', 'Perda Residuária', 'psPerdaIndeterminada', , 'psPerdaIndeterminada', 'psPerdaArtSaidaVacuo', 'psPerdaArtSaidaEvap',
    'psConsVaporTc', 'psPtConsumidaTC', 'psConsBagacao'] 

    
    function getDesvio(item){
      if (psAnData[item.psCod]){
        let bltValue = Math.round(bltData[item.name].dia * 100)/100
        let planValue = Math.round(psAnData[item.psCod][psAnData[item.psCod].revisao.slice(-3).toLowerCase()] * 100)/100
        let desvio = bltValue - planValue
        let d = bltValue*100/planValue
        return [`${stdValue(desvio)}`, `(${stdValue(d)}%)`]
      }else{
        return ''
      }
      
    }

    function getSign(item){
      if (psAnData[item.psCod]){
        let bltValue = Math.round(bltData[item.name].dia * 100)/100
        let planValue = Math.round(psAnData[item.psCod][psAnData[item.psCod].revisao.slice(-3).toLowerCase()] * 100)/100
        let desvio = bltValue - planValue
        let d = bltValue*100/planValue
        if (inverter.includes(item.psCod)){
          if (d < 100){
            return <FaCircleArrowUp color='green'/>
          }else if(d > 99.9 && d < 100.1){ 
            return <FaCircleArrowLeft color='rgb(14, 68, 132)'/>
          }else{
            return <FaCircleArrowDown color='red'/>
          }
        }
        if (d < 100){
          return <FaCircleArrowDown color='red'/>
        }else if(d > 99.9 && d < 100.1){ 
          return <FaCircleArrowLeft color='rgb(14, 68, 132)'/>
        }else{
          return <FaCircleArrowUp color='green'/>
        }

      }else{
        return ''
      }
      
    }


    return (
        <div style={{ paddingLeft: '2vh', paddingRight: '2vh', textAlign: 'center', overflowY: 'scroll'}}>
          <div style={{marginBottom: '1vh', fontSize: '2vh', fontWeight: 'bold', marginTop: '2vh'}}>REALIZADO X PLANEJADO</div>
            <div style={{fontSize: '1.8vh'}}>{anDate.replace(/\./g, '/')}</div>

          
          <div className='fullLine' style={{marginLeft: '2vh', marginRight: '0vh', paddingLeft: '1.7vh', paddingRight: '2.5vh', fontWeight: 'bold', marginTop: '2vh', fontSize: '1.6vh', textAlign: 'left'}}>
                    <div  style={{width: '25%'}}>DESCRIÇÃO</div>
                    <div  style={{width: '18%'}}>BOLETIM</div>
                    <div  style={{width: '18%'}}>PLANO</div>
                    <div  style={{width: '24%'}}>DESVIO</div>
                    
                    <div  style={{width: '10%', textAlign: 'right'}}>UNIDADE</div>
                  </div>
                  <div style={{overflow: 'auto', maxHeight: '72vh'}}>
          {bltData && psAnData && groupData &&
          Object.keys(groupData)
          .map(secao => {
            return (
              <>
              <div className='psSectionHead' style={{marginLeft: '2vh', marginRight: '0vh'}}>{secao} </div>
              {groupData[secao].map((item, index) => {
                return (
                  <>            
                      <div className={`psLine ${index%2 === 0 && "psLineImp"}`} style={{marginLeft: '2vh', marginRight: '0vh', paddingLeft: '1.7vh', paddingRight: '2.5vh'}}>
                        <div className='fullLineRow' style={{width: '25%'}}>{item.name}</div>
                        <div className='fullLineRow' style={{width: '18%'}}>{stdValue(bltData[item.name].dia)}</div>
                        <div className='fullLineRow' style={{width: '18%'}}>{psAnData[item.psCod] ? stdValue(psAnData[item.psCod][psAnData[item.psCod].revisao.slice(-3).toLowerCase()]) : ''}</div>
                        <div className='fullLineRow' style={{display: 'flex', flexDirection: 'row',  width: '24%'}}>
                          <div style={{width: '30%'}}>{getDesvio(item)[0]}</div>
                          <div style={{width: '40%'}}>{getDesvio(item)[1]}</div>
                          <div style={{width: '30%'}}>{getSign(item)}</div>
                        </div>
                        
                        <div className='fullLineRow' style={{width: '10%', textAlign: 'right'}}>{psAnData[item.psCod] ? psAnData[item.psCod].unidade : ''}</div>
                      </div>
                      </>
                    )
              })}
              </>
            )
            
          })
          
          }
          </div>
        <div style={{height: '5vh'}}></div>
        </div>
      );
}

export default DayAnalyzerBltXPlano;


