import React, { useState, useContext } from 'react';
import PmsLogo from '../assets/imgs/pmsLogo.png'
import { auth, firestore } from '../firebase/config';
import { signInWithEmailAndPassword } from 'firebase/auth';
import {doc, setDoc, getDoc } from "firebase/firestore";
import { GlobalStateContext } from '../components/GlobalStateContext'


function Login() {
    const [isHovered, setIsHovered] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const { setUser, user, setSite, site} = useContext(GlobalStateContext)
    const [userData, setUserData] = useState(null)
    
   
  
    const handleLogin = async (event) => {
      // newAction('login', site, user.email || '')
      event.preventDefault();
      try {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
        // Sucesso! Redirecionar ou atualizar o estado conforme necessário
        console.log('Login bem-sucedido! ', user.email || '');

        // Dados personalizados que você deseja salvar
      const customData = {
        lastLogin: new Date(),
        // Adicione outras informações que você deseja salvar
      };

      // Salvar informações personalizadas no Firestore
      await setDoc(doc(firestore, 'users', user.email), customData, { merge: true });

        // Buscar dados personalizados do usuário após login
      const userDoc = doc(firestore, 'users', user.email);
      const docSnap = await getDoc(userDoc);

      if (docSnap.exists()) {
        
        console.log('Login bem-sucedido e informações salvas!', docSnap.data());
        setUser(docSnap.data()); 

        
      } else {
        alert('Nenhum dado encontrado para o usuário');
      }
       // Armazenar dados do usuário no estado

      // console.log('User: '+userCredential.user.email)
      // setUser(userCredential.user);
      // console.log('Uid', docSnap.data().uid)

      } catch (error) {
        setError(error.message);
      }
    };
  
    return (
      <div style={styles.container}>
        <div style={styles.box}>
          <img src={PmsLogo} alt="Logo" style={styles.logo} />
          <div style={{padding: '3vh', fontSize: '2vh', fontWeight: 'bold'}}>LOGIN</div>
          {error && <p style={{ color: 'red' }}>{error}</p>}
          <form onSubmit={handleLogin}>
            <div style={styles.inputGroup}>
              <label htmlFor="email" style={styles.label}>Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                required
                style={styles.input}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div style={styles.inputGroup}>
              <label htmlFor="password" style={styles.label}>Senha:</label>
              <input
                type="password"
                id="password"
                name="password"
                required
                style={styles.input}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <button
              type="submit"
              style={isHovered ? { ...styles.button, ...styles.buttonHover } : styles.button}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              LOGAR
            </button>
          </form>
        </div>
      </div>
    );
  }
  
  export default Login;

  const styles = {
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      width: '100%',
      backgroundColor: '#f0f0f0',
    },
    box: {
      padding: '20px',
      background: '#fff',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
      borderRadius: '10px',
      textAlign: 'center',
      width: '300px',
    },
    logo: {
      width: '100px',
      height: '100px',
      marginBottom: '20px',
    },
    inputGroup: {
      marginBottom: '15px',
      textAlign: 'left',
    },
    label: {
      display: 'block',
      marginBottom: '5px',
      fontSize: '2vh'
    },
    input: {
      width: '100%',
      padding: '8px',
      boxSizing: 'border-box',
      border: '1px solid #ccc',
      borderRadius: '5px',
    },
    button: {
      width: '100%',
      padding: '10px',
      backgroundColor: '#007bff',
      border: 'none',
      borderRadius: '5px',
      color: 'white',
      fontSize: '1.6vh',
      cursor: 'pointer',
    },
    buttonHover: {
      backgroundColor: '#0056b3',
    }
  };