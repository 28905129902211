import logoSEL from '../usSantaElisa/logoSEL.png'
import logoUBT from '../usBatatais/logoUBT.png'
import logoSON from '../usSonora/logoSON.png'
import logoUMA from '../usMonteAlegre/logoUMA.png'
import logoCVS from '../usCevasa/logoCVS.png'
import logoUSL from '../usLins/logoUSL.png'
import logoTST1 from '../usTeste1/logoTST1.png'

export function getLogo(site){
    switch (site){
        case 'usSantaElisa': return null
        case 'usBatatais': return logoUBT
        case 'usSonora': return logoSON
        case 'usMonteAlegre': return logoUMA
        case 'usCevasa': return logoCVS
        case 'usLins': return logoUSL
        case 'usTeste1': return logoTST1
        default: return null
    }
}