const PsChartsDataSEL =  {
    'MATÉRIA PRIMA': [
        ['psAtrPcts', 'psAtrDigestor'],
        ['psFibraCana', 'psBagacoCana'],
        ['', ''],
    ],
    'MOAGEM': [
        ['psUptimeGeral', 'psDiasSafra'],
        ['psTaxaMoagem', 'psMoagemTotal'],
        ['', ''],
    ],
    'EFICIÊNCIAS': [
        ['psArtEntrado', 'psArtRecuperado'],
        ['psRitStab', 'psRitArt'],
        ['psEficienciaIndustrial', 'psRelProdAcAtrEnt'],
        ['psPerdaIndeterminada', '']
    ],
    'PERDAS': [
        ['psPerdaBagaco', 'psPerdaLavavem'],
        ['psPerdaTorta', 'psPerdaMultijato'],
        ['psPerdaFermentacao', 'psPerdaResiduaria'],
    ],
    'PRODUÇÕES': [
        ['psProdAcucarTotal', 'psProdAcucarTotalSC'],
        ['psProdEtanolTotalHid', 'psProdEtanolAnidro'],
        ['psProdEtanolNeutro', 'psEnergiaExportada'],
        
    ],
    'ESTOQUES': [
        ['psEstoqueInicialBag', 'psEstoqueFinalBag'],
        ['', ''],
        ['', ''],
    ],
    'PAGE1': [
        ['psAtrDigestor', 'psFibraCana'],
        ['psUptimeGeral', 'psMoagemTotal'],
        ['psTaxaMoagem', 'psRitStab'],
    ],
    'PAGE2': [
        ['psRitArt', 'psRelProdAcAtrEnt'],
        ['psMixAcucar', 'psProdAcucarTotalSC'],
        ['psProdEtanolTotal', ''],
    ]
}

export default PsChartsDataSEL
