import { getScreenDataUsSantaElisa } from "../usSantaElisa/usSantaElisaScreensData"
import { getScreenDataUsBatatais } from '../usBatatais/usBatataisScreensData'
import { getScreenDataUsSonora } from "../usSonora/usSonoraScreensData"
import { getScreenDataUsMonteAlegre } from '../usMonteAlegre/usMonteAlegreScreensData'  
import { getScreenDataUsCevasa } from "../usCevasa/usCevasaScreensData"
import { getScreenDataUsLins } from "../usLins/usLinsScreensData"
import { getScreenDataUsTeste1 } from "../usTeste1/usTeste1ScreensData"
    

export function getScreenData(area, screen, data, site){
  switch (site){
    case 'usSantaElisa': return getScreenDataUsSantaElisa(area, screen, data)
    case 'usBatatais': return getScreenDataUsBatatais(area, screen, data)
    case 'usSonora': return getScreenDataUsSonora(area, screen, data)
    case 'usMonteAlegre': return getScreenDataUsMonteAlegre(area, screen, data)
    case 'usCevasa': return getScreenDataUsCevasa(area, screen, data)
    case 'usLins': return getScreenDataUsLins(area, screen, data)
    case 'usTeste1': return getScreenDataUsTeste1(area, screen, data)
    default: 
      return getScreenDataUsSantaElisa(area, screen, data)
  }
}
