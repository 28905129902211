// Função para abrir o banco de dados
export function openDB() {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open("myDatabase", 1);
  
      request.onupgradeneeded = function (event) {
        const db = event.target.result;
        if (!db.objectStoreNames.contains("images")) {
          db.createObjectStore("images", { keyPath: "id" });
        }
      };
  
      request.onsuccess = function () {
        resolve(request.result);
      };
  
      request.onerror = function () {
        reject("Erro ao abrir o banco de dados");
      };
    });
  }
  
  // Função para salvar uma imagem no banco de dados
  export async function saveImageAsync(id, imageData) {
    const db = await openDB();
    return new Promise((resolve, reject) => {
      const transaction = db.transaction("images", "readwrite");
      const store = transaction.objectStore("images");
      store.put({ id, data: imageData });
  
      transaction.oncomplete = () => resolve("Imagem salva com sucesso!");
      transaction.onerror = () => reject("Erro ao salvar a imagem");
    });
  }
  
  // Função para buscar uma imagem do banco de dados
  export async function getImageAsync(id) {
    const db = await openDB();
    return new Promise((resolve, reject) => {
      const transaction = db.transaction("images", "readonly");
      const store = transaction.objectStore("images");
      const request = store.get(id);
  
      request.onsuccess = () => resolve(request.result ? request.result.data : null);
      request.onerror = () => reject("Erro ao buscar a imagem");
    });
  }

  // Função para listar todas as imagens armazenadas
export async function getAllImagesAsync() {
    const db = await openDB();
    return new Promise((resolve, reject) => {
      const transaction = db.transaction("images", "readonly");
      const store = transaction.objectStore("images");
      const request = store.getAll();
  
      request.onsuccess = () => resolve(request.result);
      request.onerror = () => reject("Erro ao listar as imagens");
    });
  }

  // Função para remover todas as imagens do banco de dados
export async function removeAllImages() {
  const db = await openDB();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction("images", "readwrite");
    const store = transaction.objectStore("images");
    const clearRequest = store.clear();

    clearRequest.onsuccess = () => resolve("Todas as imagens foram removidas com sucesso!");
    clearRequest.onerror = () => reject("Erro ao remover as imagens");
  });
}
  