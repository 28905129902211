import React from 'react';
import '../../css/ModalAlert.css';

const ModalUpdateValue = ({ show, children }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="modal-overlay2">
      <div className="modal2">
        <div className="modal-body">
          {children}
        </div>
      </div>
    </div>
  );
};

export default ModalUpdateValue;