import React, { useContext, useEffect, useState } from 'react'
import '../../css/InputLine.css'
import GlobalStateContext from '../GlobalStateContext'
import { stdValue } from '../../Resources/setNumber';

function OutputLine({cod}) {
  const { data, isPrinting} = useContext(GlobalStateContext);
  const [updated, setUpdate] = useState(false)

  useEffect(() => {
    setUpdate(!updated)
  }, [data]);

  const vh = window.innerHeight
  const ideal = 727
  let nVH = isPrinting? ideal/vh : 1

  const styles = {
    inputLineCont: {
      marginLeft: `${2*nVH}vh`},
    desc: {
        fontSize: `${1.9*nVH}vh`,
    },
    optCont: {
      padding: `${0.2*nVH}vh`,
      fontSize: `${1.9*nVH}vh`,
      padding: `${0.6*nVH}vh`,
      margin: `${0.4*nVH}vh`},
      unit:{
        fontSize: `${1.9*nVH}vh`,
        paddingRight: `${1*nVH}vh`,
        marginRight: `${1*nVH}vh`,
    }
  }

  return (
    <div className='inputLineCont' style={styles.inputLineCont}>
      {data && 
            <div className='line' >
                <div className='desc' style={styles.desc}>{data[cod]?.descricao}</div>
                <div className={`optCont ${data[cod]?.limiteMax !== null && data[cod]?.valor > data[cod]?.limiteMax ||
                  data[cod]?.limiteMin !== null && data[cod]?.valor < data[cod]?.limiteMin ? 'outOfLimit' : ''}`} style={styles.optCont}>{data[cod]?.valor !== null ? stdValue(data[cod]?.valor) : ''}</div>
                <div className='unit' style={styles.unit}>{data[cod]?.unidade}</div>
            </div>
      }
    </div>
  )
}

export default OutputLine

